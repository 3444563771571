// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/ZenDimensionType.ts"
);
import.meta.hot.lastModified = "1728941028158.6558";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Possible ZenDimension types.
 */
export enum ZenDimensionType {
  STRING = 'string',
  YESNO = 'yesno',
  TIER = 'tier',
  NUMBER = 'number',
  TIME = 'time',
}
