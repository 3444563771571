// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/ModelsService.ts"
);
import.meta.hot.lastModified = "1728941032674.6301";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ArimaForecast } from '../models/ArimaForecast'
import type { ArimaPredictiveAPI } from '../models/ArimaPredictiveAPI'

import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'

export class ModelsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Arima Schema
   * Get schema for ARIMA predictive model.
   *
   * Args:
   * records_data: A pandas dataframe serialized as records.
   * @param accountName
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public getArimaSchema(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/models/forecasts/arima',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Arima Forecast
   * Get a list of available forecasting models for an account.
   *
   * Args:
   * records_data: A pandas dataframe serialized as records.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns ArimaForecast Successful Response
   * @throws ApiError
   */
  public getArimaForecast(
    accountName: string,
    requestBody: ArimaPredictiveAPI,
    sessionId?: string | null,
  ): CancelablePromise<ArimaForecast> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/models/forecasts/arima',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Forecasting Model Apis
   * Get a list of available forecasting models for an account.
   * @param accountName
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public getForecastingModelApis(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/models/forecasts',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
