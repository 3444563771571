// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/AccountsService.ts"
);
import.meta.hot.lastModified = "1728941032314.632";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Account } from '../models/Account'
import type { AccountCreate } from '../models/AccountCreate'
import type { AccountSettings } from '../models/AccountSettings'
import type { AccountUpdate } from '../models/AccountUpdate'
import type { ClientSafeAccountSettings } from '../models/ClientSafeAccountSettings'
import type { ConversationBasic } from '../models/ConversationBasic'
import type { Domain } from '../models/Domain'
import type { Preregistration } from '../models/Preregistration'
import type { Tag } from '../models/Tag'
import type { TagAPICreate } from '../models/TagAPICreate'
import type { User } from '../models/User'
import type { UserAddToAccount } from '../models/UserAddToAccount'

import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'

export class AccountsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Read Accounts
   * Retrieve accounts.
   *
   * If name is provided, case-insensitive substring matching is performed.
   * @param skip
   * @param limit
   * @param name
   * @param sessionId
   * @returns Account Successful Response
   * @throws ApiError
   */
  public readAccounts(
    skip?: number,
    limit: number = 100,
    name: string = '',
    sessionId?: string | null,
  ): CancelablePromise<Array<Account>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/',
      cookies: {
        session_id: sessionId,
      },
      query: {
        skip: skip,
        limit: limit,
        name: name,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Account
   * Create new account.
   * @param requestBody
   * @param preloadDemoDataset
   * @param sessionId
   * @returns Account Successful Response
   * @throws ApiError
   */
  public createAccount(
    requestBody: AccountCreate,
    preloadDemoDataset: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<Account> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/',
      cookies: {
        session_id: sessionId,
      },
      query: {
        preload_demo_dataset: preloadDemoDataset,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Account By Name
   * Get a specific account by name.
   * @param accountName
   * @param sessionId
   * @returns Account Successful Response
   * @throws ApiError
   */
  public readAccountByName(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<Account> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Account
   * Update a account.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns Account Successful Response
   * @throws ApiError
   */
  public updateAccount(
    accountName: string,
    requestBody: AccountUpdate,
    sessionId?: string | null,
  ): CancelablePromise<Account> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Add Account Allowed Domain
   * Add an allowed email domain to an account.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns Domain Successful Response
   * @throws ApiError
   */
  public addAccountAllowedDomain(
    accountName: string,
    requestBody: string,
    sessionId?: string | null,
  ): CancelablePromise<Domain> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/domains',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Remove Account Allowed Domain
   * Remove an allowed email domain to an account.
   * @param domainId
   * @param accountName
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public removeAccountAllowedDomain(
    domainId: string,
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/domains/{domain_id}',
      path: {
        domain_id: domainId,
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Account Tags
   * Get all the tags for an account.
   * @param accountName
   * @param filterStr
   * @param sessionId
   * @returns Tag Successful Response
   * @throws ApiError
   */
  public readAccountTags(
    accountName: string,
    filterStr?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<Array<Tag>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/tags',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        filter_str: filterStr,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Account Tag
   * Create a tag for an account.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns Tag Successful Response
   * @throws ApiError
   */
  public createAccountTag(
    accountName: string,
    requestBody: TagAPICreate,
    sessionId?: string | null,
  ): CancelablePromise<Tag> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/tags',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Account Tag
   * Delete a tag for an account.
   * @param tagId
   * @param accountName
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public deleteAccountTag(
    tagId: string,
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/tags/{tag_id}',
      path: {
        tag_id: tagId,
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Account Settings
   * Get all client visible settings for an account.
   * @param accountName
   * @param sessionId
   * @returns ClientSafeAccountSettings Successful Response
   * @throws ApiError
   */
  public readAccountSettings(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<ClientSafeAccountSettings> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/settings',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Account Settings
   * Update settings for an account.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns ClientSafeAccountSettings Successful Response
   * @throws ApiError
   */
  public updateAccountSettings(
    accountName: string,
    requestBody: Record<string, any>,
    sessionId?: string | null,
  ): CancelablePromise<ClientSafeAccountSettings> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/settings',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Account Settings All
   * Get all settings for an account.
   * @param accountName
   * @param sessionId
   * @returns AccountSettings Successful Response
   * @throws ApiError
   */
  public readAccountSettingsAll(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<AccountSettings> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/all_settings',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Reset Account Settings
   * Reset settings for an account.
   *
   * Resets the specified settings to their default values.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns ClientSafeAccountSettings Successful Response
   * @throws ApiError
   */
  public resetAccountSettings(
    accountName: string,
    requestBody: Array<string>,
    sessionId?: string | null,
  ): CancelablePromise<ClientSafeAccountSettings> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/settings/reset',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Account Superuser Settings
   * Update superuser settings for an account.
   *
   * Superuser settings take precedence over regular account settings.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns ClientSafeAccountSettings Successful Response
   * @throws ApiError
   */
  public updateAccountSuperuserSettings(
    accountName: string,
    requestBody: Record<string, any>,
    sessionId?: string | null,
  ): CancelablePromise<ClientSafeAccountSettings> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/superuser-settings',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Reset Account Superuser Settings
   * Reset superuser settings for an account.
   *
   * Resets the specified settings to their default values. Note that
   * superuser settings take precedence over regular account settings.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns ClientSafeAccountSettings Successful Response
   * @throws ApiError
   */
  public resetAccountSuperuserSettings(
    accountName: string,
    requestBody: Array<string>,
    sessionId?: string | null,
  ): CancelablePromise<ClientSafeAccountSettings> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/superuser-settings/reset',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Account Users
   * Get all users in the account.
   * @param accountName
   * @param sessionId
   * @returns User Successful Response
   * @throws ApiError
   */
  public getAccountUsers(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<User>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/users',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Add Account Users
   * Add users to the account.
   *
   * Returns a list of the IDs which were invalid.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public addAccountUsers(
    accountName: string,
    requestBody: Array<string>,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/users',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Rm Account Users
   * Remove users to the account.
   *
   * Returns a list of the IDs which were invalid.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public rmAccountUsers(
    accountName: string,
    requestBody: Array<string>,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/users',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Account Preregistrations
   * Get all preregistrations in the account.
   * @param accountName
   * @param sessionId
   * @returns Preregistration Successful Response
   * @throws ApiError
   */
  public getAccountPreregistrations(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<Preregistration>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/preregistrations',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Preregistration
   * Delete a preregistration.
   * @param accountName
   * @param preregistrationId
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public deletePreregistration(
    accountName: string,
    preregistrationId: string,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/preregistrations/{preregistration_id}',
      path: {
        account_name: accountName,
        preregistration_id: preregistrationId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Add Account Users By Emails
   * Add users to the account by their email.
   *
   * Returns a list of the emails which were invalid.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public addAccountUsersByEmails(
    accountName: string,
    requestBody: Array<string>,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/users_by_emails',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Account Admins
   * Get account admins.
   * @param accountName
   * @param sessionId
   * @returns User Successful Response
   * @throws ApiError
   */
  public getAccountAdmins(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<User>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/admins',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Add Account Admins
   * Add admin users to the account.
   *
   * This will add users to the account if they are not already included.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public addAccountAdmins(
    accountName: string,
    requestBody: Array<string>,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/admins',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Rm Account Admins
   * Remove admin privileges from users of the account.
   *
   * Note that this does NOT remove the user themselves from the account.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public rmAccountAdmins(
    accountName: string,
    requestBody: Array<string>,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/admins',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Account Eval Maintainers
   * Get account eval maintainers.
   * @param accountName
   * @param sessionId
   * @returns User Successful Response
   * @throws ApiError
   */
  public getAccountEvalMaintainers(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<User>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/eval_maintainers',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Add Account Eval Maintainers
   * Add eval maintainers to the account.
   *
   * This will add users to the account if they are not already included.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public addAccountEvalMaintainers(
    accountName: string,
    requestBody: Array<string>,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/eval_maintainers',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Rm Account Eval Maintainers
   * Remove eval maintainer privileges from users of the account.
   *
   * Note that this does NOT remove the user themselves from the account.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public rmAccountEvalMaintainers(
    accountName: string,
    requestBody: Array<string>,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/eval_maintainers',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Account Engines
   * Get manifest engines of the account.
   * @param accountName
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public getAccountEngines(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/engines',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Bulk Create Conversations
   * Upload conversations to an account.
   *
   * Attempts to create with the same user, but will default to the admin
   * if the user doesn't belong to the account.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns ConversationBasic Successful Response
   * @throws ApiError
   */
  public bulkCreateConversations(
    accountName: string,
    requestBody: Array<ConversationBasic>,
    sessionId?: string | null,
  ): CancelablePromise<Array<ConversationBasic>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/upload_conversations',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Register User
   * Register a user to an account.
   *
   * If it is an SSO environment, the user will be created if it doesn't
   * already exist, then it will be added to the account.
   *
   * If it is not an SSO environment and the user already exists on a different account,
   * the user will be added to the new account, and they will be sent an account invite email
   *
   * If it is not an SSO environment and the user does NOT exist,
   * the user will be preregistered, and they will be sent a preregistration email
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public registerUser(
    accountName: string,
    requestBody: UserAddToAccount,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/register_user',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Pending Assistance Count
   * Get the count of all pending assistance requests for an account, grouped by ssds_id.
   *
   * Pending assistance requests, previously known as Bad responses, are cells
   * that users have requested a data admin inspect and verify.
   *
   * This endpoint is used in the application to show the admin how many
   * cells are pending assistance.
   * @param accountName
   * @param skip
   * @param limit
   * @param sessionId
   * @returns number Successful Response
   * @throws ApiError
   */
  public getPendingAssistanceCount(
    accountName: string,
    skip?: number | null,
    limit?: number | null,
    sessionId?: string | null,
  ): CancelablePromise<Record<string, number>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/pending_assistance_count',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        skip: skip,
        limit: limit,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
