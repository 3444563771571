// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/LoginService.ts"
);
import.meta.hot.lastModified = "1728941032626.6304";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppSession } from '../models/AppSession'
import type { Body_login_access_token_api_v1_login_access_token_post } from '../models/Body_login_access_token_api_v1_login_access_token_post'
import type { Body_login_session_api_v1_login_session_post } from '../models/Body_login_session_api_v1_login_session_post'
import type { Body_recover_password_api_v1_password_recovery__post } from '../models/Body_recover_password_api_v1_password_recovery__post'
import type { Body_reset_password_api_v1_reset_password__post } from '../models/Body_reset_password_api_v1_reset_password__post'
import type { Msg } from '../models/Msg'
import type { Token } from '../models/Token'

import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'

export class LoginService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Login Access Token
   * OAuth2-compatible token login, get an access token for future requests.
   *
   * Args:
   * m2m_token: The machine-to-machine token to store in the cache.
   * @param formData
   * @param m2MToken
   * @returns Token Successful Response
   * @throws ApiError
   */
  public loginAccessToken(
    formData: Body_login_access_token_api_v1_login_access_token_post,
    m2MToken?: string | null,
  ): CancelablePromise<Token> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/login/access-token',
      query: {
        m2m_token: m2MToken,
      },
      formData: formData,
      mediaType: 'application/x-www-form-urlencoded',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Temp Check Cookie
   * Return an HTTP OK.
   * @param sessionId
   * @returns AppSession Successful Response
   * @throws ApiError
   */
  public tempCheckCookie(
    sessionId?: string | null,
  ): CancelablePromise<AppSession> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/login/test-session',
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Recover Password
   * Send a password recovery email to a user.
   * @param requestBody
   * @returns Msg Successful Response
   * @throws ApiError
   */
  public recoverPassword(
    requestBody: Body_recover_password_api_v1_password_recovery__post,
  ): CancelablePromise<Msg> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/password-recovery/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Reset Password
   * Reset a user's password.
   * @param requestBody
   * @returns Msg Successful Response
   * @throws ApiError
   */
  public resetPassword(
    requestBody: Body_reset_password_api_v1_reset_password__post,
  ): CancelablePromise<Msg> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/reset-password/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Login Session
   * Login and get a session-based cookie.
   *
   * Args:
   * form_data: username and password
   * third_party: whether to set a third-party cookie for cross-origin iframes
   * @param formData
   * @param thirdParty
   * @returns any Successful Response
   * @throws ApiError
   */
  public loginSession(
    formData: Body_login_session_api_v1_login_session_post,
    thirdParty: boolean = false,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/login/session',
      query: {
        third_party: thirdParty,
      },
      formData: formData,
      mediaType: 'application/x-www-form-urlencoded',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Logout Session
   * Logout and unset session-based cookie value.
   *
   * Args:
   * third_party: whether to set a third party cookie for cross-origin iframes
   * @param thirdParty
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public logoutSession(
    thirdParty: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/logout/session',
      cookies: {
        session_id: sessionId,
      },
      query: {
        third_party: thirdParty,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Login Session Oauth
   * Login via OAuth2.0 authorization code flow with PKCE.
   * @returns any Successful Response
   * @throws ApiError
   */
  public loginSessionOauth(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/login/session/oauth',
    })
  }

  /**
   * Callback
   * Callback handler.
   * @returns any Successful Response
   * @throws ApiError
   */
  public callback(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/auth/oauth',
    })
  }

  /**
   * Login Session Firebase
   * Login via Firebase.
   * @param idToken
   * @param accountName
   * @returns any Successful Response
   * @throws ApiError
   */
  public loginSessionFirebase(
    idToken: string,
    accountName: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/login/session/firebase',
      query: {
        id_token: idToken,
        account_name: accountName,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
