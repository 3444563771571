// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/V1Service.ts"
);
import.meta.hot.lastModified = "1728941033554.6252";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Account } from '../models/Account'
import type { AccountCreate } from '../models/AccountCreate'
import type { AccountSettings } from '../models/AccountSettings'
import type { AccountUpdate } from '../models/AccountUpdate'
import type { AgentMessage } from '../models/AgentMessage'
import type { APIKeyAPICreate } from '../models/APIKeyAPICreate'
import type { APIKeyCreateResponse } from '../models/APIKeyCreateResponse'
import type { AppSession } from '../models/AppSession'
import type { BadResponseAPICreate } from '../models/BadResponseAPICreate'
import type { BBExample } from '../models/BBExample'
import type { BigQueryConnection } from '../models/BigQueryConnection'
import type { BigQueryConnectionAPICreate } from '../models/BigQueryConnectionAPICreate'
import type { BigQueryConnectionAPIUpdate } from '../models/BigQueryConnectionAPIUpdate'
import type { Body_create_dimension_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__dimensions__post } from '../models/Body_create_dimension_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__dimensions__post'
import type { Body_create_dms_via_remapping_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__create_dms_via_remapping_post } from '../models/Body_create_dms_via_remapping_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__create_dms_via_remapping_post'
import type { Body_create_measure_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__measures__post } from '../models/Body_create_measure_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__measures__post'
import type { Body_create_remapped_ssds_api_v1_accounts__account_name__star_schema_data_source_remap_ssds_post } from '../models/Body_create_remapped_ssds_api_v1_accounts__account_name__star_schema_data_source_remap_ssds_post'
import type { Body_create_ssds_from_twb_api_v1_accounts__account_name__star_schema_data_source_upload_twb__twb_id__create_post } from '../models/Body_create_ssds_from_twb_api_v1_accounts__account_name__star_schema_data_source_upload_twb__twb_id__create_post'
import type { Body_create_user_open_api_v1_users_open_post } from '../models/Body_create_user_open_api_v1_users_open_post'
import type { Body_enrich_ssds_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__enrich_ssds_post } from '../models/Body_enrich_ssds_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__enrich_ssds_post'
import type { Body_extract_sqls_from_csv_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__extract_sqls_from_csv_post } from '../models/Body_extract_sqls_from_csv_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__extract_sqls_from_csv_post'
import type { Body_import_from_twb_api_v1_accounts__account_name__star_schema_data_source_import_from_twb_post } from '../models/Body_import_from_twb_api_v1_accounts__account_name__star_schema_data_source_import_from_twb_post'
import type { Body_login_access_token_api_v1_login_access_token_post } from '../models/Body_login_access_token_api_v1_login_access_token_post'
import type { Body_login_session_api_v1_login_session_post } from '../models/Body_login_session_api_v1_login_session_post'
import type { Body_merge_dimensions_api_v1_accounts__account_name__star_schema_data_source_dimensions__dimension_id__merge_patch } from '../models/Body_merge_dimensions_api_v1_accounts__account_name__star_schema_data_source_dimensions__dimension_id__merge_patch'
import type { Body_merge_measures_api_v1_accounts__account_name__star_schema_data_source_measures__measure_id__merge_patch } from '../models/Body_merge_measures_api_v1_accounts__account_name__star_schema_data_source_measures__measure_id__merge_patch'
import type { Body_read_cell_data_api_v1_accounts__account_name__conversations__conversation_id__cells__cell_id__data_post } from '../models/Body_read_cell_data_api_v1_accounts__account_name__conversations__conversation_id__cells__cell_id__data_post'
import type { Body_read_connection_data_api_v1_accounts__account_name__connections__connection_id__data_get } from '../models/Body_read_connection_data_api_v1_accounts__account_name__connections__connection_id__data_get'
import type { Body_read_data_source_data_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__data_sources__data_source_id__data_post } from '../models/Body_read_data_source_data_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__data_sources__data_source_id__data_post'
import type { Body_read_star_schema_data_source_data_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__data_post } from '../models/Body_read_star_schema_data_source_data_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__data_post'
import type { Body_recover_password_api_v1_password_recovery__post } from '../models/Body_recover_password_api_v1_password_recovery__post'
import type { Body_reset_password_api_v1_reset_password__post } from '../models/Body_reset_password_api_v1_reset_password__post'
import type { Body_run_eval_on_reference_conversations_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__eval_reference_conversations_post } from '../models/Body_run_eval_on_reference_conversations_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__eval_reference_conversations_post'
import type { Body_run_regression_test_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__regression_test_post } from '../models/Body_run_regression_test_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__regression_test_post'
import type { Body_update_user_me_api_v1_users_me_put } from '../models/Body_update_user_me_api_v1_users_me_put'
import type { Body_upload_cube_csv_api_v1_accounts__account_name__star_schema_data_source_upload_cube_csv_post } from '../models/Body_upload_cube_csv_api_v1_accounts__account_name__star_schema_data_source_upload_cube_csv_post'
import type { Body_upload_dataset_api_v1_accounts__account_name__datasets_upload_post } from '../models/Body_upload_dataset_api_v1_accounts__account_name__datasets_upload_post'
import type { Body_upload_tableau_workbook_api_v1_accounts__account_name__star_schema_data_source_upload_twb_post } from '../models/Body_upload_tableau_workbook_api_v1_accounts__account_name__star_schema_data_source_upload_twb_post'
import type { Body_write_csv_to_connection_api_v1_accounts__account_name__connections_csv__post } from '../models/Body_write_csv_to_connection_api_v1_accounts__account_name__connections_csv__post'
import type { BrowserLog } from '../models/BrowserLog'
import type { BumblebeeCell } from '../models/BumblebeeCell'
import type { BumblebeeCellAPICreate } from '../models/BumblebeeCellAPICreate'
import type { BumblebeeCellAPIUpdate } from '../models/BumblebeeCellAPIUpdate'
import type { BumblebeeCellBasic } from '../models/BumblebeeCellBasic'
import type { BumblebeeCellFilterOptionWithGroup } from '../models/BumblebeeCellFilterOptionWithGroup'
import type { BumblebeeCellUpdate } from '../models/BumblebeeCellUpdate'
import type { BumblebeeDataset } from '../models/BumblebeeDataset'
import type { CellOrderBy } from '../models/CellOrderBy'
import type { CellRunEvent } from '../models/CellRunEvent'
import type { Chart } from '../models/Chart'
import type { ChartConfig } from '../models/ChartConfig'
import type { ChartConfigAPIUpdate } from '../models/ChartConfigAPIUpdate'
import type { ClientSafeAccountSettings } from '../models/ClientSafeAccountSettings'
import type { ColumnValue } from '../models/ColumnValue'
import type { Connection } from '../models/Connection'
import type { ConnectionPage } from '../models/ConnectionPage'
import type { ConversationAPICreate } from '../models/ConversationAPICreate'
import type { ConversationAPIUpdate } from '../models/ConversationAPIUpdate'
import type { ConversationBasic } from '../models/ConversationBasic'
import type { ConversationCellFilter } from '../models/ConversationCellFilter'
import type { ConversationCellTuple } from '../models/ConversationCellTuple'
import type { ConversationFull } from '../models/ConversationFull'
import type { ConversationsFilter } from '../models/ConversationsFilter'
import type { ConversationsSort } from '../models/ConversationsSort'
import type { DatabricksConnection } from '../models/DatabricksConnection'
import type { DatabricksConnectionCreate } from '../models/DatabricksConnectionCreate'
import type { DatabricksConnectionUpdate } from '../models/DatabricksConnectionUpdate'
import type { DataSourceProfileInfo } from '../models/DataSourceProfileInfo'
import type { DefaultSetting } from '../models/DefaultSetting'
import type { DefaultSettingUpdate } from '../models/DefaultSettingUpdate'
import type { DeleteRequest } from '../models/DeleteRequest'
import type { Dialect } from '../models/Dialect'
import type { DimensionDetail } from '../models/DimensionDetail'
import type { DimensionFilter } from '../models/DimensionFilter'
import type { DimensionMetricValidation } from '../models/DimensionMetricValidation'
import type { DimensionMetricValidationAPIUpdate } from '../models/DimensionMetricValidationAPIUpdate'
import type { DimensionUpdate } from '../models/DimensionUpdate'
import type { DimensionUpdateWithId } from '../models/DimensionUpdateWithId'
import type { DimensionView } from '../models/DimensionView'
import type { DimensionViewWithGroup } from '../models/DimensionViewWithGroup'
import type { DimensionViewWithoutDialect } from '../models/DimensionViewWithoutDialect'
import type { DisplayFieldsUpdateAPI } from '../models/DisplayFieldsUpdateAPI'
import type { DMFilter } from '../models/DMFilter'
import type { DMSensitiveAssignment } from '../models/DMSensitiveAssignment'
import type { DMSensitiveKnowledgeImpact } from '../models/DMSensitiveKnowledgeImpact'
import type { Domain } from '../models/Domain'
import type { EvalBumblebeeCellAPICreate } from '../models/EvalBumblebeeCellAPICreate'
import type { EvalResultBase } from '../models/EvalResultBase'
import type { Feedback } from '../models/Feedback'
import type { FeedbackFilter } from '../models/FeedbackFilter'
import type { Identifier } from '../models/Identifier'
import type { IdentifierSuggestionResponse } from '../models/IdentifierSuggestionResponse'
import type { Join } from '../models/Join'
import type { JoinGroup } from '../models/JoinGroup'
import type { JoinValidationResponse } from '../models/JoinValidationResponse'
import type { JWTToken } from '../models/JWTToken'
import type { KnowledgeOriginFilter } from '../models/KnowledgeOriginFilter'
import type { KnowledgeStateFilter } from '../models/KnowledgeStateFilter'
import type { MeasureDetail } from '../models/MeasureDetail'
import type { MeasureUpdate } from '../models/MeasureUpdate'
import type { MeasureUpdateWithId } from '../models/MeasureUpdateWithId'
import type { MetricFilter } from '../models/MetricFilter'
import type { MetricView } from '../models/MetricView'
import type { MetricViewWithoutDialect } from '../models/MetricViewWithoutDialect'
import type { MoonlightRunInputAPI } from '../models/MoonlightRunInputAPI'
import type { MoonlightRunParams } from '../models/MoonlightRunParams'
import type { Msg } from '../models/Msg'
import type { NamedFilterBase } from '../models/NamedFilterBase'
import type { NamedFilterCreate } from '../models/NamedFilterCreate'
import type { NamedFilterCreateAPI } from '../models/NamedFilterCreateAPI'
import type { NamedFilterOptionConditionValidOperatorsWithGroup } from '../models/NamedFilterOptionConditionValidOperatorsWithGroup'
import type { NamedFilterUpdateAPI } from '../models/NamedFilterUpdateAPI'
import type { NamedFilterUpdateAPIWithId } from '../models/NamedFilterUpdateAPIWithId'
import type { NodeDataPage } from '../models/NodeDataPage'
import type { PostgresConnection } from '../models/PostgresConnection'
import type { PostgresConnectionCreate } from '../models/PostgresConnectionCreate'
import type { PostgresConnectionUpdate } from '../models/PostgresConnectionUpdate'
import type { Preregistration } from '../models/Preregistration'
import type { PreregistrationCreate } from '../models/PreregistrationCreate'
import type { PromptLog } from '../models/PromptLog'
import type { PromptLogComponent } from '../models/PromptLogComponent'
import type { PublishStateFilterOption } from '../models/PublishStateFilterOption'
import type { RedshiftConnection } from '../models/RedshiftConnection'
import type { RedshiftConnectionCreate } from '../models/RedshiftConnectionCreate'
import type { RedshiftConnectionUpdate } from '../models/RedshiftConnectionUpdate'
import type { RetrievalParams } from '../models/RetrievalParams'
import type { RunBBCellResponse } from '../models/RunBBCellResponse'
import type { SemanticLayerView } from '../models/SemanticLayerView'
import type { SemanticQuery } from '../models/SemanticQuery'
import type { SemanticQueryCoalescingStrategy } from '../models/SemanticQueryCoalescingStrategy'
import type { SemanticQueryCreate } from '../models/SemanticQueryCreate'
import type { SnowflakeConnection } from '../models/SnowflakeConnection'
import type { SnowflakeConnectionAPICreate } from '../models/SnowflakeConnectionAPICreate'
import type { SnowflakeConnectionPage } from '../models/SnowflakeConnectionPage'
import type { SnowflakeConnectionUpdate } from '../models/SnowflakeConnectionUpdate'
import type { SnowflakeConnectionWithSecrets } from '../models/SnowflakeConnectionWithSecrets'
import type { SqlValidation } from '../models/SqlValidation'
import type { SqlValidationAPICreate } from '../models/SqlValidationAPICreate'
import type { SqlValidationAPIUpdate } from '../models/SqlValidationAPIUpdate'
import type { SqlWithValidation } from '../models/SqlWithValidation'
import type { SSDSJoin } from '../models/SSDSJoin'
import type { StarSchemaDataSource } from '../models/StarSchemaDataSource'
import type { StarSchemaDataSourceAPICreate } from '../models/StarSchemaDataSourceAPICreate'
import type { StarSchemaDataSourceBase } from '../models/StarSchemaDataSourceBase'
import type { StarSchemaDataSourceColdStart } from '../models/StarSchemaDataSourceColdStart'
import type { StarSchemaDataSourceEnrichment } from '../models/StarSchemaDataSourceEnrichment'
import type { StarSchemaDataSourceSort } from '../models/StarSchemaDataSourceSort'
import type { StarSchemaDataSourcesResponse } from '../models/StarSchemaDataSourcesResponse'
import type { StarSchemaDataSourceTableOrViewInfo } from '../models/StarSchemaDataSourceTableOrViewInfo'
import type { StateUpdate } from '../models/StateUpdate'
import type { StateUpdateWithId } from '../models/StateUpdateWithId'
import type { TableColumn } from '../models/TableColumn'
import type { TableFilter } from '../models/TableFilter'
import type { TableLocation } from '../models/TableLocation'
import type { Tag } from '../models/Tag'
import type { TagAPICreate } from '../models/TagAPICreate'
import type { Task } from '../models/Task'
import type { TaskName } from '../models/TaskName'
import type { TaskPage } from '../models/TaskPage'
import type { TaskStatus } from '../models/TaskStatus'
import type { TeradataConnection } from '../models/TeradataConnection'
import type { TeradataConnectionCreate } from '../models/TeradataConnectionCreate'
import type { TeradataConnectionUpdate } from '../models/TeradataConnectionUpdate'
import type { Token } from '../models/Token'
import type { UnionStatistic } from '../models/UnionStatistic'
import type { UpdateSemanticLayerViewResponse } from '../models/UpdateSemanticLayerViewResponse'
import type { UploadTableauResponse } from '../models/UploadTableauResponse'
import type { User } from '../models/User'
import type { UserAddToAccount } from '../models/UserAddToAccount'
import type { UserCreate } from '../models/UserCreate'
import type { UserUpdate } from '../models/UserUpdate'

import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'

export class V1Service {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Health Check
   * Return an HTTP OK.
   * @returns any Successful Response
   * @throws ApiError
   */
  public healthCheck(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/healthz',
    })
  }

  /**
   * Version
   * Return the version of the mlcore web server.
   * @returns any Successful Response
   * @throws ApiError
   */
  public version(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/version',
    })
  }

  /**
   * Login Access Token
   * OAuth2-compatible token login, get an access token for future requests.
   *
   * Args:
   * m2m_token: The machine-to-machine token to store in the cache.
   * @param formData
   * @param m2MToken
   * @returns Token Successful Response
   * @throws ApiError
   */
  public loginAccessToken(
    formData: Body_login_access_token_api_v1_login_access_token_post,
    m2MToken?: string | null,
  ): CancelablePromise<Token> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/login/access-token',
      query: {
        m2m_token: m2MToken,
      },
      formData: formData,
      mediaType: 'application/x-www-form-urlencoded',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Temp Check Cookie
   * Return an HTTP OK.
   * @param sessionId
   * @returns AppSession Successful Response
   * @throws ApiError
   */
  public tempCheckCookie(
    sessionId?: string | null,
  ): CancelablePromise<AppSession> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/login/test-session',
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Recover Password
   * Send a password recovery email to a user.
   * @param requestBody
   * @returns Msg Successful Response
   * @throws ApiError
   */
  public recoverPassword(
    requestBody: Body_recover_password_api_v1_password_recovery__post,
  ): CancelablePromise<Msg> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/password-recovery/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Reset Password
   * Reset a user's password.
   * @param requestBody
   * @returns Msg Successful Response
   * @throws ApiError
   */
  public resetPassword(
    requestBody: Body_reset_password_api_v1_reset_password__post,
  ): CancelablePromise<Msg> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/reset-password/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Login Session
   * Login and get a session-based cookie.
   *
   * Args:
   * form_data: username and password
   * third_party: whether to set a third-party cookie for cross-origin iframes
   * @param formData
   * @param thirdParty
   * @returns any Successful Response
   * @throws ApiError
   */
  public loginSession(
    formData: Body_login_session_api_v1_login_session_post,
    thirdParty: boolean = false,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/login/session',
      query: {
        third_party: thirdParty,
      },
      formData: formData,
      mediaType: 'application/x-www-form-urlencoded',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Logout Session
   * Logout and unset session-based cookie value.
   *
   * Args:
   * third_party: whether to set a third party cookie for cross-origin iframes
   * @param thirdParty
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public logoutSession(
    thirdParty: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/logout/session',
      cookies: {
        session_id: sessionId,
      },
      query: {
        third_party: thirdParty,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Login Session Oauth
   * Login via OAuth2.0 authorization code flow with PKCE.
   * @returns any Successful Response
   * @throws ApiError
   */
  public loginSessionOauth(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/login/session/oauth',
    })
  }

  /**
   * Callback
   * Callback handler.
   * @returns any Successful Response
   * @throws ApiError
   */
  public callback(): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/auth/oauth',
    })
  }

  /**
   * Login Session Firebase
   * Login via Firebase.
   * @param idToken
   * @param accountName
   * @returns any Successful Response
   * @throws ApiError
   */
  public loginSessionFirebase(
    idToken: string,
    accountName: string,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/login/session/firebase',
      query: {
        id_token: idToken,
        account_name: accountName,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Api Key
   * Create a new API key.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns APIKeyCreateResponse Successful Response
   * @throws ApiError
   */
  public createApiKey(
    accountName: string,
    requestBody: APIKeyAPICreate,
    sessionId?: string | null,
  ): CancelablePromise<APIKeyCreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/api_key',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Api Key
   * Delete the account's API Key.
   * @param accountName
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public deleteApiKey(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/api_key',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Accounts
   * Retrieve accounts.
   *
   * If name is provided, case-insensitive substring matching is performed.
   * @param skip
   * @param limit
   * @param name
   * @param sessionId
   * @returns Account Successful Response
   * @throws ApiError
   */
  public readAccounts(
    skip?: number,
    limit: number = 100,
    name: string = '',
    sessionId?: string | null,
  ): CancelablePromise<Array<Account>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/',
      cookies: {
        session_id: sessionId,
      },
      query: {
        skip: skip,
        limit: limit,
        name: name,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Account
   * Create new account.
   * @param requestBody
   * @param preloadDemoDataset
   * @param sessionId
   * @returns Account Successful Response
   * @throws ApiError
   */
  public createAccount(
    requestBody: AccountCreate,
    preloadDemoDataset: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<Account> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/',
      cookies: {
        session_id: sessionId,
      },
      query: {
        preload_demo_dataset: preloadDemoDataset,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Account By Name
   * Get a specific account by name.
   * @param accountName
   * @param sessionId
   * @returns Account Successful Response
   * @throws ApiError
   */
  public readAccountByName(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<Account> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Account
   * Update a account.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns Account Successful Response
   * @throws ApiError
   */
  public updateAccount(
    accountName: string,
    requestBody: AccountUpdate,
    sessionId?: string | null,
  ): CancelablePromise<Account> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Add Account Allowed Domain
   * Add an allowed email domain to an account.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns Domain Successful Response
   * @throws ApiError
   */
  public addAccountAllowedDomain(
    accountName: string,
    requestBody: string,
    sessionId?: string | null,
  ): CancelablePromise<Domain> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/domains',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Remove Account Allowed Domain
   * Remove an allowed email domain to an account.
   * @param domainId
   * @param accountName
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public removeAccountAllowedDomain(
    domainId: string,
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/domains/{domain_id}',
      path: {
        domain_id: domainId,
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Account Tags
   * Get all the tags for an account.
   * @param accountName
   * @param filterStr
   * @param sessionId
   * @returns Tag Successful Response
   * @throws ApiError
   */
  public readAccountTags(
    accountName: string,
    filterStr?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<Array<Tag>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/tags',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        filter_str: filterStr,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Account Tag
   * Create a tag for an account.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns Tag Successful Response
   * @throws ApiError
   */
  public createAccountTag(
    accountName: string,
    requestBody: TagAPICreate,
    sessionId?: string | null,
  ): CancelablePromise<Tag> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/tags',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Account Tag
   * Delete a tag for an account.
   * @param tagId
   * @param accountName
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public deleteAccountTag(
    tagId: string,
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/tags/{tag_id}',
      path: {
        tag_id: tagId,
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Account Settings
   * Get all client visible settings for an account.
   * @param accountName
   * @param sessionId
   * @returns ClientSafeAccountSettings Successful Response
   * @throws ApiError
   */
  public readAccountSettings(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<ClientSafeAccountSettings> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/settings',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Account Settings
   * Update settings for an account.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns ClientSafeAccountSettings Successful Response
   * @throws ApiError
   */
  public updateAccountSettings(
    accountName: string,
    requestBody: Record<string, any>,
    sessionId?: string | null,
  ): CancelablePromise<ClientSafeAccountSettings> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/settings',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Account Settings All
   * Get all settings for an account.
   * @param accountName
   * @param sessionId
   * @returns AccountSettings Successful Response
   * @throws ApiError
   */
  public readAccountSettingsAll(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<AccountSettings> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/all_settings',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Reset Account Settings
   * Reset settings for an account.
   *
   * Resets the specified settings to their default values.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns ClientSafeAccountSettings Successful Response
   * @throws ApiError
   */
  public resetAccountSettings(
    accountName: string,
    requestBody: Array<string>,
    sessionId?: string | null,
  ): CancelablePromise<ClientSafeAccountSettings> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/settings/reset',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Account Superuser Settings
   * Update superuser settings for an account.
   *
   * Superuser settings take precedence over regular account settings.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns ClientSafeAccountSettings Successful Response
   * @throws ApiError
   */
  public updateAccountSuperuserSettings(
    accountName: string,
    requestBody: Record<string, any>,
    sessionId?: string | null,
  ): CancelablePromise<ClientSafeAccountSettings> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/superuser-settings',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Reset Account Superuser Settings
   * Reset superuser settings for an account.
   *
   * Resets the specified settings to their default values. Note that
   * superuser settings take precedence over regular account settings.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns ClientSafeAccountSettings Successful Response
   * @throws ApiError
   */
  public resetAccountSuperuserSettings(
    accountName: string,
    requestBody: Array<string>,
    sessionId?: string | null,
  ): CancelablePromise<ClientSafeAccountSettings> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/superuser-settings/reset',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Account Users
   * Get all users in the account.
   * @param accountName
   * @param sessionId
   * @returns User Successful Response
   * @throws ApiError
   */
  public getAccountUsers(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<User>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/users',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Add Account Users
   * Add users to the account.
   *
   * Returns a list of the IDs which were invalid.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public addAccountUsers(
    accountName: string,
    requestBody: Array<string>,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/users',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Rm Account Users
   * Remove users to the account.
   *
   * Returns a list of the IDs which were invalid.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public rmAccountUsers(
    accountName: string,
    requestBody: Array<string>,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/users',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Account Preregistrations
   * Get all preregistrations in the account.
   * @param accountName
   * @param sessionId
   * @returns Preregistration Successful Response
   * @throws ApiError
   */
  public getAccountPreregistrations(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<Preregistration>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/preregistrations',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Preregistration
   * Delete a preregistration.
   * @param accountName
   * @param preregistrationId
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public deletePreregistration(
    accountName: string,
    preregistrationId: string,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/preregistrations/{preregistration_id}',
      path: {
        account_name: accountName,
        preregistration_id: preregistrationId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Add Account Users By Emails
   * Add users to the account by their email.
   *
   * Returns a list of the emails which were invalid.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public addAccountUsersByEmails(
    accountName: string,
    requestBody: Array<string>,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/users_by_emails',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Account Admins
   * Get account admins.
   * @param accountName
   * @param sessionId
   * @returns User Successful Response
   * @throws ApiError
   */
  public getAccountAdmins(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<User>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/admins',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Add Account Admins
   * Add admin users to the account.
   *
   * This will add users to the account if they are not already included.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public addAccountAdmins(
    accountName: string,
    requestBody: Array<string>,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/admins',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Rm Account Admins
   * Remove admin privileges from users of the account.
   *
   * Note that this does NOT remove the user themselves from the account.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public rmAccountAdmins(
    accountName: string,
    requestBody: Array<string>,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/admins',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Account Eval Maintainers
   * Get account eval maintainers.
   * @param accountName
   * @param sessionId
   * @returns User Successful Response
   * @throws ApiError
   */
  public getAccountEvalMaintainers(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<User>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/eval_maintainers',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Add Account Eval Maintainers
   * Add eval maintainers to the account.
   *
   * This will add users to the account if they are not already included.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public addAccountEvalMaintainers(
    accountName: string,
    requestBody: Array<string>,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/eval_maintainers',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Rm Account Eval Maintainers
   * Remove eval maintainer privileges from users of the account.
   *
   * Note that this does NOT remove the user themselves from the account.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public rmAccountEvalMaintainers(
    accountName: string,
    requestBody: Array<string>,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/eval_maintainers',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Account Engines
   * Get manifest engines of the account.
   * @param accountName
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public getAccountEngines(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/engines',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Bulk Create Conversations
   * Upload conversations to an account.
   *
   * Attempts to create with the same user, but will default to the admin
   * if the user doesn't belong to the account.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns ConversationBasic Successful Response
   * @throws ApiError
   */
  public bulkCreateConversations(
    accountName: string,
    requestBody: Array<ConversationBasic>,
    sessionId?: string | null,
  ): CancelablePromise<Array<ConversationBasic>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/upload_conversations',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Register User
   * Register a user to an account.
   *
   * If it is an SSO environment, the user will be created if it doesn't
   * already exist, then it will be added to the account.
   *
   * If it is not an SSO environment and the user already exists on a different account,
   * the user will be added to the new account, and they will be sent an account invite email
   *
   * If it is not an SSO environment and the user does NOT exist,
   * the user will be preregistered, and they will be sent a preregistration email
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public registerUser(
    accountName: string,
    requestBody: UserAddToAccount,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/register_user',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Pending Assistance Count
   * Get the count of all pending assistance requests for an account, grouped by ssds_id.
   *
   * Pending assistance requests, previously known as Bad responses, are cells
   * that users have requested a data admin inspect and verify.
   *
   * This endpoint is used in the application to show the admin how many
   * cells are pending assistance.
   * @param accountName
   * @param skip
   * @param limit
   * @param sessionId
   * @returns number Successful Response
   * @throws ApiError
   */
  public getPendingAssistanceCount(
    accountName: string,
    skip?: number | null,
    limit?: number | null,
    sessionId?: string | null,
  ): CancelablePromise<Record<string, number>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/pending_assistance_count',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        skip: skip,
        limit: limit,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Tasks By Ids
   * Get specific task(s) by id(s).
   * @param accountName
   * @param taskId A list of task ids to retrieve.
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public readTasksByIds(
    accountName: string,
    taskId?: Array<string>,
    sessionId?: string | null,
  ): CancelablePromise<Array<Task>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/tasks/ids',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        task_id: taskId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Task By Id
   * Get a specific task by id.
   * @param accountName
   * @param taskId
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public readTaskById(
    accountName: string,
    taskId: string,
    sessionId?: string | null,
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/tasks/{task_id}',
      path: {
        account_name: accountName,
        task_id: taskId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Tasks By Account
   * Read tasks by account.
   * @param accountName
   * @param skip
   * @param limit
   * @param name
   * @param status
   * @param sessionId
   * @returns TaskPage Successful Response
   * @throws ApiError
   */
  public readTasksByAccount(
    accountName: string,
    skip?: number,
    limit: number = 100,
    name?: TaskName | null,
    status?: TaskStatus | null,
    sessionId?: string | null,
  ): CancelablePromise<TaskPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/tasks',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        skip: skip,
        limit: limit,
        name: name,
        status: status,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Users
   * Retrieve users.
   * @param skip
   * @param limit
   * @param email
   * @param sessionId
   * @returns User Successful Response
   * @throws ApiError
   */
  public readUsers(
    skip?: number,
    limit: number = 100,
    email?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<Array<User>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/users/',
      cookies: {
        session_id: sessionId,
      },
      query: {
        skip: skip,
        limit: limit,
        email: email,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create User
   * Create new user.
   * @param requestBody
   * @param sessionId
   * @returns User Successful Response
   * @throws ApiError
   */
  public createUser(
    requestBody: UserCreate,
    sessionId?: string | null,
  ): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/users/',
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update User Me
   * Update own user.
   * @param sessionId
   * @param requestBody
   * @returns User Successful Response
   * @throws ApiError
   */
  public updateUserMe(
    sessionId?: string | null,
    requestBody?: Body_update_user_me_api_v1_users_me_put,
  ): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/users/me',
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read User Me
   * Get current user.
   * @param sessionId
   * @returns User Successful Response
   * @throws ApiError
   */
  public readUserMe(sessionId?: string | null): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/users/me',
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read User Jwt Token
   * Get current user's jwt token.
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public readUserJwtToken(
    sessionId?: string | null,
  ): CancelablePromise<JWTToken | null> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/users/jwt_token',
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Accounts By Current User
   * Get accounts by user.
   * @param sessionId
   * @returns Account Successful Response
   * @throws ApiError
   */
  public readAccountsByCurrentUser(
    sessionId?: string | null,
  ): CancelablePromise<Array<Account>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/users/accounts',
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Check Username Exists
   * Check if username exists.
   * @param username
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public checkUsernameExists(username: string): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/users/username_exists',
      query: {
        username: username,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create User Open
   * Create new user without the need to be logged in.
   * @param requestBody
   * @returns User Successful Response
   * @throws ApiError
   */
  public createUserOpen(
    requestBody: Body_create_user_open_api_v1_users_open_post,
  ): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/users/open',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read User By Id
   * Get a specific user by id.
   * @param userId
   * @param userEmail
   * @param sessionId
   * @returns User Successful Response
   * @throws ApiError
   */
  public readUserById(
    userId: string | null,
    userEmail?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/users/{user_id}',
      path: {
        user_id: userId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        user_email: userEmail,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update User
   * Update a user.
   * @param userId
   * @param requestBody
   * @param userEmail
   * @param sessionId
   * @returns User Successful Response
   * @throws ApiError
   */
  public updateUser(
    userId: string | null,
    requestBody: UserUpdate,
    userEmail?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/users/{user_id}',
      path: {
        user_id: userId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        user_email: userEmail,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Preregister User
   * Create a preregistration.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns Preregistration Successful Response
   * @throws ApiError
   */
  public preregisterUser(
    accountName: string,
    requestBody: PreregistrationCreate,
    sessionId?: string | null,
  ): CancelablePromise<Preregistration> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/users/preregistration',
      cookies: {
        session_id: sessionId,
      },
      query: {
        account_name: accountName,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Resend Email
   * Re-send a preregistration welcome email.
   * @param preregistrationId
   * @param accountName
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public resendEmail(
    preregistrationId: string,
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/users/preregistration/{preregistration_id}/resend-invite',
      path: {
        preregistration_id: preregistrationId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        account_name: accountName,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Compelete Preregistration
   * Register a user.
   * @param preregistrationId
   * @param requestBody
   * @returns User Successful Response
   * @throws ApiError
   */
  public compeletePreregistration(
    preregistrationId: string,
    requestBody: UserCreate,
  ): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/users/preregistration/{preregistration_id}',
      path: {
        preregistration_id: preregistrationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Test Celery Response
   * Test Celery worker.
   * @param accountName
   * @param requestBody
   * @returns Task Successful Response
   * @throws ApiError
   */
  public testCeleryResponse(
    accountName: string,
    requestBody: Msg,
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/utils/test-celery/',
      query: {
        account_name: accountName,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Semantic Query
   * Get a semantic query.
   * @param accountName
   * @param semanticQueryId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public getSemanticQuery(
    accountName: string,
    semanticQueryId: string,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/semantic_query/{semantic_query_id}',
      path: {
        account_name: accountName,
        semantic_query_id: semanticQueryId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Clone Conversation
   * Clone a conversation with the new user as the creator.
   * @param accountName
   * @param conversationId
   * @param isEvalConversation
   * @param sessionId
   * @returns ConversationBasic Successful Response
   * @throws ApiError
   */
  public cloneConversation(
    accountName: string,
    conversationId: string,
    isEvalConversation: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<ConversationBasic> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/clone',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        is_eval_conversation: isEvalConversation,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Conversation
   * Get a full conversation.
   * @param accountName
   * @param conversationId
   * @param sessionId
   * @returns ConversationFull Successful Response
   * @throws ApiError
   */
  public getConversation(
    accountName: string,
    conversationId: string,
    sessionId?: string | null,
  ): CancelablePromise<ConversationFull> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Conversation
   * Delete a conversation.
   * @param accountName
   * @param conversationId
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public deleteConversation(
    accountName: string,
    conversationId: string,
    sessionId?: string | null,
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Conversation
   * Update a conversation.
   * @param accountName
   * @param conversationId
   * @param requestBody
   * @param sessionId
   * @returns ConversationBasic Successful Response
   * @throws ApiError
   */
  public updateConversation(
    accountName: string,
    conversationId: string,
    requestBody: ConversationAPIUpdate,
    sessionId?: string | null,
  ): CancelablePromise<ConversationBasic> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Conversation Basic
   * Get a basic conversation.
   * @param accountName
   * @param conversationId
   * @param sessionId
   * @returns ConversationBasic Successful Response
   * @throws ApiError
   */
  public getConversationBasic(
    accountName: string,
    conversationId: string,
    sessionId?: string | null,
  ): CancelablePromise<ConversationBasic> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/basic',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Conversation
   * Create new bumblebee conversation.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns ConversationFull Successful Response
   * @throws ApiError
   */
  public createConversation(
    accountName: string,
    requestBody: ConversationAPICreate,
    sessionId?: string | null,
  ): CancelablePromise<ConversationFull> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Conversations
   * Get all conversations for the bumblebee dashboard.
   *
   * If show_moonlight is True, show only moonlight conversations
   * If show_moonlight is False, show only non-moonlight conversations
   * If show_moonlight is None, show everything.
   * @param accountName
   * @param sortAscending
   * @param sortBy
   * @param filterBy
   * @param limit
   * @param skip
   * @param showMoonlight
   * @param showEval
   * @param sessionId
   * @returns ConversationBasic Successful Response
   * @throws ApiError
   */
  public getConversations(
    accountName: string,
    sortAscending: boolean = true,
    sortBy?: ConversationsSort | null,
    filterBy?: ConversationsFilter | null,
    limit?: number | null,
    skip?: number,
    showMoonlight?: boolean | null,
    showEval: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<Array<ConversationBasic>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        sort_ascending: sortAscending,
        sort_by: sortBy,
        filter_by: filterBy,
        limit: limit,
        skip: skip,
        show_moonlight: showMoonlight,
        show_eval: showEval,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Pin Conversation
   * Pin a bumblebee conversation to the account home page.
   * @param accountName
   * @param conversationId
   * @param requestBody
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public pinConversation(
    accountName: string,
    conversationId: string,
    requestBody: ConversationAPIUpdate,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/pins',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Unpin Conversation
   * Unpin a bumblebee conversation to the account home page.
   * @param accountName
   * @param conversationId
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public unpinConversation(
    accountName: string,
    conversationId: string,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/pins',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Star Conversation
   * Star a bumblebee conversation.
   * @param accountName
   * @param conversationId
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public starConversation(
    accountName: string,
    conversationId: string,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/stars',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Unstar Conversation
   * Unstar a bumblebee conversation.
   * @param accountName
   * @param conversationId
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public unstarConversation(
    accountName: string,
    conversationId: string,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/stars',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Run Bumblebee Cell
   * Run bumblebee for an already created cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @param requestBody
   * @returns RunBBCellResponse Successful Response
   * @throws ApiError
   */
  public runBumblebeeCell(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: string | null,
    requestBody?: MoonlightRunParams,
  ): CancelablePromise<RunBBCellResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cell/{cell_id}/run_bumblebee_cell',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Run Moonlight
   * Run Moonlight.
   * @param accountName
   * @param conversationId
   * @param requestBody
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public runMoonlight(
    accountName: string,
    conversationId: string,
    requestBody: MoonlightRunInputAPI,
    sessionId?: string | null,
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/run_moonlight',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Run Moonlight Cell
   * Run moonlight for an already created cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param requestBody
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public runMoonlightCell(
    accountName: string,
    conversationId: string,
    cellId: string,
    requestBody: MoonlightRunInputAPI,
    sessionId?: string | null,
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cell/{cell_id}/run_moonlight_cell',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Messages
   * Get agent messages for a cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param runId
   * @param sessionId
   * @returns AgentMessage Successful Response
   * @throws ApiError
   */
  public getMessages(
    accountName: string,
    conversationId: string,
    cellId: string,
    runId?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<Array<AgentMessage>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cell/{cell_id}/messages',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        run_id: runId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Eval Cell
   * Create a new Evaluation Bumblebee Cell in an Evaluation Conversation.
   *
   * Args:
   * eval_bb_cell_in: Eval cell creation API. Only required if cell_id is None.
   * source_cell_id: source id to create eval cell from.
   * conversation_id: Optional, to append cell to an existing eval conversation.
   * @param accountName
   * @param sourceCellId
   * @param conversationId
   * @param sessionId
   * @param requestBody
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public createEvalCell(
    accountName: string,
    sourceCellId?: string | null,
    conversationId?: string | null,
    sessionId?: string | null,
    requestBody?: EvalBumblebeeCellAPICreate | null,
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/eval_cell',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        source_cell_id: sourceCellId,
        conversation_id: conversationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Cells
   * Read Bumblebee Cells for a conversation.
   * @param accountName
   * @param conversationId
   * @param limit
   * @param skip
   * @param withFeedback
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public readCells(
    accountName: string,
    conversationId: string,
    limit: number = 100,
    skip?: number,
    withFeedback: boolean = true,
    sessionId?: string | null,
  ): CancelablePromise<Array<BumblebeeCell>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
        skip: skip,
        with_feedback: withFeedback,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Cell
   * Create a new Bumblebee Cell.
   * @param accountName
   * @param conversationId
   * @param requestBody
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public createCell(
    accountName: string,
    conversationId: string,
    requestBody: BumblebeeCellAPICreate,
    sessionId?: string | null,
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Cells Basic
   * Read Bumblebee Cells for a conversation.
   * @param accountName
   * @param conversationId
   * @param limit
   * @param skip
   * @param sessionId
   * @returns BumblebeeCellBasic Successful Response
   * @throws ApiError
   */
  public readCellsBasic(
    accountName: string,
    conversationId: string,
    limit: number = 100,
    skip?: number,
    sessionId?: string | null,
  ): CancelablePromise<Array<BumblebeeCellBasic>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/basic',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
        skip: skip,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Cell
   * Get Bumblebee Cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public readCell(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: string | null,
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Cell
   * Update a Bumblebee Cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param requestBody
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public updateCell(
    accountName: string,
    conversationId: string,
    cellId: string,
    requestBody: BumblebeeCellAPIUpdate,
    sessionId?: string | null,
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Cell
   * Delete a Bumblebee Cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteCell(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * @deprecated
   * Get Cell Metric Options
   * Get cell metric options.
   *
   * DEPRECATED. Use get_ssds_metrics instead.
   *
   * This represents the available metrics for the cell that could be manually added.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param limit
   * @param skip
   * @param sessionId
   * @returns MetricView Successful Response
   * @throws ApiError
   */
  public getCellMetricOptions(
    accountName: string,
    conversationId: string,
    cellId: string,
    limit: number = 100,
    skip?: number,
    sessionId?: string | null,
  ): CancelablePromise<Array<MetricView>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/metric_options',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
        skip: skip,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Cell Filter Options
   * Get cell filter options.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns BumblebeeCellFilterOptionWithGroup Successful Response
   * @throws ApiError
   */
  public getCellFilterOptions(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<BumblebeeCellFilterOptionWithGroup>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/filter_options',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Cell Admin Only
   * Update a Bumblebee Cell's question only.
   *
   * This is an admin endpoint. One use case is question-only update of sensitive cells.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param requestBody
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public updateCellAdminOnly(
    accountName: string,
    conversationId: string,
    cellId: string,
    requestBody: BumblebeeCellUpdate,
    sessionId?: string | null,
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/admin_only',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Refresh Bumblebee Cell
   * Refresh the bumblebee cell's data.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public refreshBumblebeeCell(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: string | null,
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/refresh',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Cell Semanticlayerview
   * Update the semantic layer view of a cell.
   *
   * The `name` field of each Dimension or Metric in the `order` parameter should be prefixed
   * with '-' if the sort order is descending.
   *
   * Args:
   * skip_cell_refresh: instead of issuing a cell refresh, delete the underlying cell table and don't issue a new SQL query.
   * remove_feedback: remove any existing cell feedback when we update the cell. This defaults to false, but should be set to true for user edits.
   * Raises:
   * NSRuntimeError if the semantic layer view is invalid.
   * NSUnauthorizedError if the cell is verified and the user is not an admin.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param requestBody
   * @param skipCellRefresh
   * @param removeFeedback
   * @param sessionId
   * @returns UpdateSemanticLayerViewResponse Successful Response
   * @throws ApiError
   */
  public updateCellSemanticlayerview(
    accountName: string,
    conversationId: string,
    cellId: string,
    requestBody: SemanticLayerView,
    skipCellRefresh: boolean = false,
    removeFeedback: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<UpdateSemanticLayerViewResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/semanticlayerview',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        skip_cell_refresh: skipCellRefresh,
        remove_feedback: removeFeedback,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Add Tag
   * Add a tag to a bumblebee cell. Creates it if it does not exist.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param requestBody
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public addTag(
    accountName: string,
    conversationId: string,
    cellId: string,
    requestBody: TagAPICreate,
    sessionId?: string | null,
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/tags',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Remove Tag
   * Remove a tag from a bumblebee cell.
   * @param tagId
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public removeTag(
    tagId: string,
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: string | null,
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/tags/{tag_id}',
      path: {
        tag_id: tagId,
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Add Bad Response
   * Add bad repsonse to a bumblebee cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param requestBody
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public addBadResponse(
    accountName: string,
    conversationId: string,
    cellId: string,
    requestBody: BadResponseAPICreate,
    sessionId?: string | null,
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/bad_response',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Remove Bad Response
   * Remove bad responses from a bumblebee cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public removeBadResponse(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: string | null,
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/bad_response',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Verify Cell
   * Add positive feedback to a bumblebee cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param allowConflictingFeedback
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public verifyCell(
    accountName: string,
    conversationId: string,
    cellId: string,
    allowConflictingFeedback: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/verified',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        allow_conflicting_feedback: allowConflictingFeedback,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Unverify Cell
   * Add feedback to a bumblebee cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public unverifyCell(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: string | null,
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/verified',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Mark Cell Sensitive
   * Add positive feedback to a bumblebee cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public markCellSensitive(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: string | null,
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/is_user_sensitive',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Unmark Cell Sensitive
   * Unmark cell sensitive.
   *
   * If the cell uses DMs that are sensitive, it cannot be unmarked.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public unmarkCellSensitive(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: string | null,
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/is_user_sensitive',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Verified Conflicts
   * Get list of cells with conflicting feedback.
   *
   * If there are any conflicting cells, the user should be notified before verifying the cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns BumblebeeCellBasic Successful Response
   * @throws ApiError
   */
  public getVerifiedConflicts(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<BumblebeeCellBasic>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/verified_conflicts',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Is Suggested
   * Mark cell as Suggestion
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param isSuggested
   * @param sessionId
   * @returns BumblebeeCell Successful Response
   * @throws ApiError
   */
  public updateIsSuggested(
    accountName: string,
    conversationId: string,
    cellId: string,
    isSuggested: boolean,
    sessionId?: string | null,
  ): CancelablePromise<BumblebeeCell> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/suggested',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        is_suggested: isSuggested,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Cell Filter
   * Read the specified filter.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param filterId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public readCellFilter(
    accountName: string,
    conversationId: string,
    cellId: string,
    filterId: string,
    sessionId?: string | null,
  ): CancelablePromise<DimensionFilter | MetricFilter> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/filters/{filter_id}',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
        filter_id: filterId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Cell Filters
   * Get the filters for a cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public readCellFilters(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<DimensionFilter | MetricFilter>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/filters',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Profile Cell Data
   * Profile the bumblebee cell table columns.
   *
   * Raises:
   * NSCannotReadNodeDataError: If the bumblebee cell table does not exist.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param limit
   * @param sessionId
   * @param requestBody
   * @returns UnionStatistic Successful Response
   * @throws ApiError
   */
  public profileCellData(
    accountName: string,
    conversationId: string,
    cellId: string,
    limit: number = 100000,
    sessionId?: string | null,
    requestBody?: Array<TableFilter> | null,
  ): CancelablePromise<Record<string, UnionStatistic>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/profile',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Cell Data
   * Get Bumblebee Cell Data.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param limit
   * @param skip
   * @param sessionId
   * @param requestBody
   * @returns NodeDataPage Successful Response
   * @throws ApiError
   */
  public readCellData(
    accountName: string,
    conversationId: string,
    cellId: string,
    limit: number = 100,
    skip?: number,
    sessionId?: string | null,
    requestBody?: Body_read_cell_data_api_v1_accounts__account_name__conversations__conversation_id__cells__cell_id__data_post,
  ): CancelablePromise<NodeDataPage> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/data',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
        skip: skip,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Cell Data As Csv
   * Read Cell data as a CSV.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public readCellDataAsCsv(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/data/csv',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Cell Chart Config
   * Update Bumblebee Cell Chart Config.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param chartConfigId
   * @param requestBody
   * @param sessionId
   * @returns ChartConfig Successful Response
   * @throws ApiError
   */
  public updateCellChartConfig(
    accountName: string,
    conversationId: string,
    cellId: string,
    chartConfigId: string,
    requestBody: ChartConfigAPIUpdate,
    sessionId?: string | null,
  ): CancelablePromise<ChartConfig> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/chart/{chart_config_id}',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
        chart_config_id: chartConfigId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Cell Chart
   * Get Bumblebee Cell Chart Data.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param chartConfigId
   * @param sessionId
   * @returns Chart Successful Response
   * @throws ApiError
   */
  public readCellChart(
    accountName: string,
    conversationId: string,
    cellId: string,
    chartConfigId: string,
    sessionId?: string | null,
  ): CancelablePromise<Chart> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/chart/{chart_config_id}',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
        chart_config_id: chartConfigId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Cell Chart Config
   * Create a Bumblebee Cell Chart Config.
   *
   * Creating will infer the best chart configuration based on the semantic query.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns ChartConfig Successful Response
   * @throws ApiError
   */
  public createCellChartConfig(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: string | null,
  ): CancelablePromise<ChartConfig> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/chart',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Cell Tasks
   * Get all tasks for a cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param limit
   * @param skip
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public getCellTasks(
    accountName: string,
    conversationId: string,
    cellId: string,
    limit: number = 100,
    skip?: number,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/tasks',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
        skip: skip,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Bb Cell Sql
   * Get SQL from a bumblebee cell.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public getBbCellSql(
    accountName: string,
    conversationId: string,
    cellId: string,
    sessionId?: string | null,
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/sql',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Validate Cell Filter
   * Check that the filter is valid.
   *
   * Raises:
   * NSInvalidFilterSQLError if the filter leads to an error in the data warehouse.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param requestBody
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public validateCellFilter(
    accountName: string,
    conversationId: string,
    cellId: string,
    requestBody: DimensionFilter | MetricFilter,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/validate-filter',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Search Similar Verified Cells
   * Search for similar verified cells.
   *
   * NOTE: These are not the same cells used during retrieval when the cell was created.
   * This uses the same function to retrieve similar cells, but because this endpoint is
   * used each time the cell is loaded, it is possible to retrieve cells that are newly verified
   * and similar to the current cell (which the cell did not use when it was run.)
   *
   * Ideally we can make the BBRetrievedMetadata.retrieved_examples stateful and persisted
   * in the cell objects which would allow us to retrieve the same examples each time the cell is loaded.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param limit
   * @param sessionId
   * @returns BumblebeeCellBasic Successful Response
   * @throws ApiError
   */
  public searchSimilarVerifiedCells(
    accountName: string,
    conversationId: string,
    cellId: string,
    limit: number = 5,
    sessionId?: string | null,
  ): CancelablePromise<Array<BumblebeeCellBasic>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/search_similar',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Cell Promptlog
   * Admin endpoint to the promptlog history of a bumblebee cell.
   *
   * Component can be query to filter to that component.
   * Component of ALL will return unified promptlogs for an entire run
   * (this is not backwards compatible)
   * Component of None will return any component ordered by time and not unified.
   *
   * Returns:
   * This returns a JSON list of PromptLogs for a cell in reverse chronological order, by
   * default only the most recent. The component filters to return logs for everything or query.
   * @param accountName
   * @param conversationId
   * @param cellId
   * @param component
   * @param limit
   * @param sessionId
   * @returns PromptLog Successful Response
   * @throws ApiError
   */
  public getCellPromptlog(
    accountName: string,
    conversationId: string,
    cellId: string,
    component?: PromptLogComponent | null,
    limit: number = 1,
    sessionId?: string | null,
  ): CancelablePromise<Array<PromptLog>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/conversations/{conversation_id}/cells/{cell_id}/promptlog',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
        cell_id: cellId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        component: component,
        limit: limit,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Cell Run Events By Cell
   * Read all cell run events by cell id.
   * @param cellId
   * @param accountName
   * @param sessionId
   * @returns CellRunEvent Successful Response
   * @throws ApiError
   */
  public readCellRunEventsByCell(
    cellId: string,
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<CellRunEvent>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/cell_run_events/cell/{cell_id}',
      path: {
        cell_id: cellId,
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Cell Run Event By Run Id
   * Read cell run event by run id.
   * @param runId
   * @param accountName
   * @param sessionId
   * @returns CellRunEvent Successful Response
   * @throws ApiError
   */
  public readCellRunEventByRunId(
    runId: string,
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<CellRunEvent> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/cell_run_events/run/{run_id}',
      path: {
        run_id: runId,
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Connections
   * Read all connections, independent of type.
   * @param accountName
   * @param skip
   * @param limit
   * @param sessionId
   * @returns ConnectionPage Successful Response
   * @throws ApiError
   */
  public readConnections(
    accountName: string,
    skip?: number,
    limit: number = 100,
    sessionId?: string | null,
  ): CancelablePromise<ConnectionPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        skip: skip,
        limit: limit,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Connection Data
   * Read data from a data warehouse table without creating a node.
   * @param accountName
   * @param connectionId
   * @param database
   * @param schema
   * @param table
   * @param skip
   * @param limit
   * @param sessionId
   * @param requestBody
   * @returns NodeDataPage Successful Response
   * @throws ApiError
   */
  public readConnectionData(
    accountName: string,
    connectionId: string,
    database: string,
    schema: string,
    table: string,
    skip?: number,
    limit: number = 100,
    sessionId?: string | null,
    requestBody?: Body_read_connection_data_api_v1_accounts__account_name__connections__connection_id__data_get,
  ): CancelablePromise<NodeDataPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/{connection_id}/data',
      path: {
        account_name: accountName,
        connection_id: connectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        skip: skip,
        limit: limit,
        database: database,
        schema: schema,
        table: table,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Connection
   * Read Connection, independent of type.
   * @param accountName
   * @param connectionId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public readConnection(
    accountName: string,
    connectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<
    | BigQueryConnection
    | PostgresConnection
    | RedshiftConnection
    | SnowflakeConnection
    | DatabricksConnection
    | TeradataConnection
    | Connection
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/{connection_id}',
      path: {
        account_name: accountName,
        connection_id: connectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Write Csv To Connection
   * Upload CSV to the warehouse creating a table.
   *
   * If no connection_id is provided, a demo connection will be used/created.
   *
   * We use File and Form data together since we cannot declare Body fields we want to
   * accept as JSON to do the HTTP protocol specification.
   *
   * Ref: https://fastapi.tiangolo.com/tutorial/request-forms-and-files/
   * @param accountName
   * @param formData
   * @param sessionId
   * @returns TableLocation Successful Response
   * @throws ApiError
   */
  public writeCsvToConnection(
    accountName: string,
    formData: Body_write_csv_to_connection_api_v1_accounts__account_name__connections_csv__post,
    sessionId?: string | null,
  ): CancelablePromise<TableLocation> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/csv/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * List Tables Metadata
   * List tables metadata.
   *
   * Tables: list of table names in the format database.schema.table (or project.dataset.table for BigQuery)
   * @param accountName
   * @param connectionId
   * @param requestBody
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public listTablesMetadata(
    accountName: string,
    connectionId: string,
    requestBody: Array<string>,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/{connection_id}/tables/metadata',
      path: {
        account_name: accountName,
        connection_id: connectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Bigquery Connection
   * Create a new BigQuery connection.
   * @param accountName
   * @param requestBody
   * @param validate
   * @param sessionId
   * @returns BigQueryConnection Successful Response
   * @throws ApiError
   */
  public createBigqueryConnection(
    accountName: string,
    requestBody: BigQueryConnectionAPICreate,
    validate: boolean = true,
    sessionId?: string | null,
  ): CancelablePromise<BigQueryConnection> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/bigquery',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        validate: validate,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Validate Bigquery Connection
   * Validate a BigQuery connection.
   * @param accountName
   * @param connectionId
   * @param sessionId
   * @returns BigQueryConnection Successful Response
   * @throws ApiError
   */
  public validateBigqueryConnection(
    accountName: string,
    connectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<BigQueryConnection> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/bigquery/{connection_id}/validate',
      path: {
        account_name: accountName,
        connection_id: connectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Bigquery Connection
   * Read a BigQuery connection.
   * @param accountName
   * @param connectionId
   * @param sessionId
   * @returns BigQueryConnection Successful Response
   * @throws ApiError
   */
  public readBigqueryConnection(
    accountName: string,
    connectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<BigQueryConnection> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/bigquery/{connection_id}',
      path: {
        account_name: accountName,
        connection_id: connectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Bigquery Connection
   * Update a BigQuery connection.
   *
   * Note that all fields for a valid BigQuery connection are required.
   * @param accountName
   * @param connectionId
   * @param requestBody
   * @param validate
   * @param sessionId
   * @returns BigQueryConnection Successful Response
   * @throws ApiError
   */
  public updateBigqueryConnection(
    accountName: string,
    connectionId: string,
    requestBody: BigQueryConnectionAPIUpdate,
    validate: boolean = true,
    sessionId?: string | null,
  ): CancelablePromise<BigQueryConnection> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/connections/bigquery/{connection_id}',
      path: {
        account_name: accountName,
        connection_id: connectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        validate: validate,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Bigquery Connection
   * Delete a BigQuery connection.
   * @param accountName
   * @param connectionId
   * @param sessionId
   * @returns BigQueryConnection Successful Response
   * @throws ApiError
   */
  public deleteBigqueryConnection(
    accountName: string,
    connectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<BigQueryConnection> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/connections/bigquery/{connection_id}',
      path: {
        account_name: accountName,
        connection_id: connectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * List Bigquery Datasets
   * List BigQuery datasets.
   * @param accountName
   * @param connectionId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public listBigqueryDatasets(
    accountName: string,
    connectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/bigquery/{connection_id}/datasets',
      path: {
        account_name: accountName,
        connection_id: connectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * List Bigquery Tables
   * List BigQuery tables.
   * @param accountName
   * @param connectionId
   * @param dataset
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public listBigqueryTables(
    accountName: string,
    connectionId: string,
    dataset?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<Record<string, Record<string, Array<string>>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/bigquery/{connection_id}/tables',
      path: {
        account_name: accountName,
        connection_id: connectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        dataset: dataset,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * List Bigquery Columns
   * List BigQuery columns.
   * @param accountName
   * @param connectionId
   * @param dataset
   * @param table
   * @param sessionId
   * @returns TableColumn Successful Response
   * @throws ApiError
   */
  public listBigqueryColumns(
    accountName: string,
    connectionId: string,
    dataset: string,
    table: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<TableColumn>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/bigquery/{connection_id}/columns',
      path: {
        account_name: accountName,
        connection_id: connectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        dataset: dataset,
        table: table,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Databricks Connection
   * Create new Databricks connection.
   * @param accountName
   * @param requestBody
   * @param validate
   * @param sessionId
   * @returns DatabricksConnection Successful Response
   * @throws ApiError
   */
  public createDatabricksConnection(
    accountName: string,
    requestBody: DatabricksConnectionCreate,
    validate: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<DatabricksConnection> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/databricks/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        validate: validate,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Validate Databricks Connection
   * Validate Databricks connection.
   * @param accountName
   * @param databricksConnectionId
   * @returns DatabricksConnection Successful Response
   * @throws ApiError
   */
  public validateDatabricksConnection(
    accountName: string,
    databricksConnectionId: string,
  ): CancelablePromise<DatabricksConnection> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/databricks/{databricks_connection_id}/validate',
      path: {
        account_name: accountName,
        databricks_connection_id: databricksConnectionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Databricks Connection
   * Read Databricks connection.
   * @param accountName
   * @param databricksConnectionId
   * @param sessionId
   * @returns DatabricksConnection Successful Response
   * @throws ApiError
   */
  public readDatabricksConnection(
    accountName: string,
    databricksConnectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<DatabricksConnection> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/databricks/{databricks_connection_id}',
      path: {
        account_name: accountName,
        databricks_connection_id: databricksConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Databricks Connection
   * Update Databricks connection.
   * @param accountName
   * @param databricksConnectionId
   * @param requestBody
   * @param sessionId
   * @returns DatabricksConnection Successful Response
   * @throws ApiError
   */
  public updateDatabricksConnection(
    accountName: string,
    databricksConnectionId: string,
    requestBody: DatabricksConnectionUpdate,
    sessionId?: string | null,
  ): CancelablePromise<DatabricksConnection> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/connections/databricks/{databricks_connection_id}',
      path: {
        account_name: accountName,
        databricks_connection_id: databricksConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Databricks Connection
   * Delete Databricks connection.
   * @param accountName
   * @param databricksConnectionId
   * @param sessionId
   * @returns DatabricksConnection Successful Response
   * @throws ApiError
   */
  public deleteDatabricksConnection(
    accountName: string,
    databricksConnectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<DatabricksConnection> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/connections/databricks/{databricks_connection_id}',
      path: {
        account_name: accountName,
        databricks_connection_id: databricksConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Databricks Connection Databases
   * Read Databricks databases.
   * @param accountName
   * @param databricksConnectionId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readDatabricksConnectionDatabases(
    accountName: string,
    databricksConnectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/databricks/{databricks_connection_id}/databases',
      path: {
        account_name: accountName,
        databricks_connection_id: databricksConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Databricks Connection Schemas
   * Read Databricks schemas.
   * @param accountName
   * @param databricksConnectionId
   * @param database
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readDatabricksConnectionSchemas(
    accountName: string,
    databricksConnectionId: string,
    database: string,
    sessionId?: string | null,
  ): CancelablePromise<Record<string, Array<string>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/databricks/{databricks_connection_id}/schemas',
      path: {
        account_name: accountName,
        databricks_connection_id: databricksConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        database: database,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Databricks Connection Tables
   * Read Databricks tables.
   * @param accountName
   * @param databricksConnectionId
   * @param database
   * @param schema
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readDatabricksConnectionTables(
    accountName: string,
    databricksConnectionId: string,
    database: string,
    schema?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<Record<string, Record<string, Array<string>>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/databricks/{databricks_connection_id}/tables',
      path: {
        account_name: accountName,
        databricks_connection_id: databricksConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        database: database,
        schema: schema,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Databricks Connection Columns
   * Read Databricks table columns.
   * @param accountName
   * @param databricksConnectionId
   * @param schema
   * @param table
   * @param sessionId
   * @returns TableColumn Successful Response
   * @throws ApiError
   */
  public readDatabricksConnectionColumns(
    accountName: string,
    databricksConnectionId: string,
    schema: string,
    table: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<TableColumn>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/databricks/{databricks_connection_id}/columns',
      path: {
        account_name: accountName,
        databricks_connection_id: databricksConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        schema: schema,
        table: table,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Redshift Connection
   * Create new Redshift connection.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns RedshiftConnection Successful Response
   * @throws ApiError
   */
  public createRedshiftConnection(
    accountName: string,
    requestBody: RedshiftConnectionCreate,
    sessionId?: string | null,
  ): CancelablePromise<RedshiftConnection> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/redshift/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Redshift Connection
   * Read a Redshift connection.
   * @param accountName
   * @param connectionId
   * @param sessionId
   * @returns RedshiftConnection Successful Response
   * @throws ApiError
   */
  public readRedshiftConnection(
    accountName: string,
    connectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<RedshiftConnection> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/redshift/{connection_id}',
      path: {
        account_name: accountName,
        connection_id: connectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Redshift Connection
   * Update Resfhit connection.
   *
   * Note that all fields for a valid Redshift connection are required.
   * @param accountName
   * @param connectionId
   * @param requestBody
   * @param sessionId
   * @returns RedshiftConnection Successful Response
   * @throws ApiError
   */
  public updateRedshiftConnection(
    accountName: string,
    connectionId: string,
    requestBody: RedshiftConnectionUpdate,
    sessionId?: string | null,
  ): CancelablePromise<RedshiftConnection> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/connections/redshift/{connection_id}',
      path: {
        account_name: accountName,
        connection_id: connectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Redshift Connection
   * Delete a Redshift connection.
   * @param accountName
   * @param connectionId
   * @param sessionId
   * @returns RedshiftConnection Successful Response
   * @throws ApiError
   */
  public deleteRedshiftConnection(
    accountName: string,
    connectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<RedshiftConnection> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/connections/redshift/{connection_id}',
      path: {
        account_name: accountName,
        connection_id: connectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * List Redshift Schemas
   * List Redshift schemas.
   * @param accountName
   * @param connectionId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public listRedshiftSchemas(
    accountName: string,
    connectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<Record<string, Array<string>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/redshift/{connection_id}/schemas',
      path: {
        account_name: accountName,
        connection_id: connectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * List Redshift Tables
   * Read Redshift tables.
   * @param accountName
   * @param connectionId
   * @param database
   * @param schema
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public listRedshiftTables(
    accountName: string,
    connectionId: string,
    database?: string | null,
    schema?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<Record<string, Record<string, Array<string>>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/redshift/{connection_id}/tables',
      path: {
        account_name: accountName,
        connection_id: connectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        database: database,
        schema: schema,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * List Redshift Columns
   * List Redshift columns.
   * @param accountName
   * @param connectionId
   * @param schema
   * @param table
   * @param database
   * @param sessionId
   * @returns TableColumn Successful Response
   * @throws ApiError
   */
  public listRedshiftColumns(
    accountName: string,
    connectionId: string,
    schema: string,
    table: string,
    database?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<Array<TableColumn>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/redshift/{connection_id}/columns',
      path: {
        account_name: accountName,
        connection_id: connectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        database: database,
        schema: schema,
        table: table,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Snowflake Connection
   * Create new Snowflake connection.
   * @param accountName
   * @param requestBody
   * @param validate
   * @param sessionId
   * @returns SnowflakeConnection Successful Response
   * @throws ApiError
   */
  public createSnowflakeConnection(
    accountName: string,
    requestBody: SnowflakeConnectionAPICreate,
    validate: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<SnowflakeConnection> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        validate: validate,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * @deprecated
   * Read Snowflake Connections
   * Read Snowflake connections.
   *
   * This endpoint is deprecated. Use the /connections endpoint to get all connections instead.
   * This endpoint will be removed in a coming version.
   * @param accountName
   * @param skip
   * @param limit
   * @param sessionId
   * @returns SnowflakeConnectionPage Successful Response
   * @throws ApiError
   */
  public readSnowflakeConnections(
    accountName: string,
    skip?: number,
    limit: number = 100,
    sessionId?: string | null,
  ): CancelablePromise<SnowflakeConnectionPage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        skip: skip,
        limit: limit,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Validate Snowflake Connection
   * Validate Snowflake connection.
   * @param accountName
   * @param snowflakeConnectionId
   * @param sessionId
   * @returns SnowflakeConnection Successful Response
   * @throws ApiError
   */
  public validateSnowflakeConnection(
    accountName: string,
    snowflakeConnectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<SnowflakeConnection> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}/validate',
      path: {
        account_name: accountName,
        snowflake_connection_id: snowflakeConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Snowflake Connection
   * Read Snowflake connection.
   * @param accountName
   * @param snowflakeConnectionId
   * @param sessionId
   * @returns SnowflakeConnection Successful Response
   * @throws ApiError
   */
  public readSnowflakeConnection(
    accountName: string,
    snowflakeConnectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<SnowflakeConnection> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}',
      path: {
        account_name: accountName,
        snowflake_connection_id: snowflakeConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Snowflake Connection
   * Update Snowflake connection.
   *
   * Note that all fields for a valid Snowflake connection are required.
   * @param accountName
   * @param snowflakeConnectionId
   * @param requestBody
   * @param sessionId
   * @returns SnowflakeConnection Successful Response
   * @throws ApiError
   */
  public updateSnowflakeConnection(
    accountName: string,
    snowflakeConnectionId: string,
    requestBody: SnowflakeConnectionUpdate,
    sessionId?: string | null,
  ): CancelablePromise<SnowflakeConnection> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}',
      path: {
        account_name: accountName,
        snowflake_connection_id: snowflakeConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Snowflake Connection
   * Delete Snowflake connection.
   * @param accountName
   * @param snowflakeConnectionId
   * @param sessionId
   * @returns SnowflakeConnection Successful Response
   * @throws ApiError
   */
  public deleteSnowflakeConnection(
    accountName: string,
    snowflakeConnectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<SnowflakeConnection> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}',
      path: {
        account_name: accountName,
        snowflake_connection_id: snowflakeConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Full Snowflake Connection
   * Read Snowflake connection with secrets.
   * @param accountName
   * @param snowflakeConnectionId
   * @param sessionId
   * @returns SnowflakeConnectionWithSecrets Successful Response
   * @throws ApiError
   */
  public readFullSnowflakeConnection(
    accountName: string,
    snowflakeConnectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<SnowflakeConnectionWithSecrets> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}/full_connection_info',
      path: {
        account_name: accountName,
        snowflake_connection_id: snowflakeConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Snowflake Connection Warehouses
   * Read Snowflake warehouses.
   * @param accountName
   * @param snowflakeConnectionId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readSnowflakeConnectionWarehouses(
    accountName: string,
    snowflakeConnectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}/warehouses',
      path: {
        account_name: accountName,
        snowflake_connection_id: snowflakeConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Snowflake Connection Roles
   * Read Snowflake roles.
   * @param accountName
   * @param snowflakeConnectionId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readSnowflakeConnectionRoles(
    accountName: string,
    snowflakeConnectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}/roles',
      path: {
        account_name: accountName,
        snowflake_connection_id: snowflakeConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Snowflake Connection Region
   * Read Snowflake regions.
   * @param accountName
   * @param snowflakeConnectionId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readSnowflakeConnectionRegion(
    accountName: string,
    snowflakeConnectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}/region',
      path: {
        account_name: accountName,
        snowflake_connection_id: snowflakeConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Snowflake Connection Databases
   * Read Snowflake databases.
   * @param accountName
   * @param snowflakeConnectionId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readSnowflakeConnectionDatabases(
    accountName: string,
    snowflakeConnectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}/databases',
      path: {
        account_name: accountName,
        snowflake_connection_id: snowflakeConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Snowflake Connection Schemas
   * Read Snowflake schemas.
   * @param accountName
   * @param snowflakeConnectionId
   * @param database
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readSnowflakeConnectionSchemas(
    accountName: string,
    snowflakeConnectionId: string,
    database?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<Record<string, Array<string>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}/schemas',
      path: {
        account_name: accountName,
        snowflake_connection_id: snowflakeConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        database: database,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Snowflake Connection Tables
   * Read Snowflake tables.
   * @param accountName
   * @param snowflakeConnectionId
   * @param database
   * @param schema
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readSnowflakeConnectionTables(
    accountName: string,
    snowflakeConnectionId: string,
    database: string,
    schema?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<Record<string, Record<string, Array<string>>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}/tables',
      path: {
        account_name: accountName,
        snowflake_connection_id: snowflakeConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        database: database,
        schema: schema,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Snowflake Connection Columns
   * Read Snowflake table columns.
   * @param accountName
   * @param snowflakeConnectionId
   * @param database
   * @param schema
   * @param table
   * @param sessionId
   * @returns TableColumn Successful Response
   * @throws ApiError
   */
  public readSnowflakeConnectionColumns(
    accountName: string,
    snowflakeConnectionId: string,
    database: string,
    schema: string,
    table: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<TableColumn>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}/columns',
      path: {
        account_name: accountName,
        snowflake_connection_id: snowflakeConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        database: database,
        schema: schema,
        table: table,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Test Snowflake Connection
   * Test Snowflake connection.
   *
   * Throws an HTTP error if the connection is broken.
   * @param accountName
   * @param snowflakeConnectionId
   * @param sessionId
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public testSnowflakeConnection(
    accountName: string,
    snowflakeConnectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/{snowflake_connection_id}/test',
      path: {
        account_name: accountName,
        snowflake_connection_id: snowflakeConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Refresh Snowflake Connection
   * Create external functions for all snowflake connections.
   * @param accountName
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public refreshSnowflakeConnection(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<Record<string, any>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/snowflake/refresh',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Teradata Connection
   * Create new teradata connection.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns TeradataConnection Successful Response
   * @throws ApiError
   */
  public createTeradataConnection(
    accountName: string,
    requestBody: TeradataConnectionCreate,
    sessionId?: string | null,
  ): CancelablePromise<TeradataConnection> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/teradata/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Teradata Connection
   * Read teradata connection.
   * @param accountName
   * @param teradataConnectionId
   * @param sessionId
   * @returns TeradataConnection Successful Response
   * @throws ApiError
   */
  public readTeradataConnection(
    accountName: string,
    teradataConnectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<TeradataConnection> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/teradata/{teradata_connection_id}',
      path: {
        account_name: accountName,
        teradata_connection_id: teradataConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Teradata Connection
   * Update teradata connection.
   * @param accountName
   * @param teradataConnectionId
   * @param requestBody
   * @param sessionId
   * @returns TeradataConnection Successful Response
   * @throws ApiError
   */
  public updateTeradataConnection(
    accountName: string,
    teradataConnectionId: string,
    requestBody: TeradataConnectionUpdate,
    sessionId?: string | null,
  ): CancelablePromise<TeradataConnection> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/connections/teradata/{teradata_connection_id}',
      path: {
        account_name: accountName,
        teradata_connection_id: teradataConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Teradata Connection
   * Delete teradata connection.
   * @param accountName
   * @param teradataConnectionId
   * @param sessionId
   * @returns TeradataConnection Successful Response
   * @throws ApiError
   */
  public deleteTeradataConnection(
    accountName: string,
    teradataConnectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<TeradataConnection> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/connections/teradata/{teradata_connection_id}',
      path: {
        account_name: accountName,
        teradata_connection_id: teradataConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Teradata Connection Databases
   * Read Teradata databases.
   *
   * Note: Because Teradata has only a single level of qualification for tables,
   * we return a hard-coded list here for consistency with the other connectors.
   * @param accountName
   * @param teradataConnectionId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readTeradataConnectionDatabases(
    accountName: string,
    teradataConnectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/teradata/{teradata_connection_id}/databases',
      path: {
        account_name: accountName,
        teradata_connection_id: teradataConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Teradata Connection Schemas
   * Read teradata schemas.
   * @param accountName
   * @param teradataConnectionId
   * @param database
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readTeradataConnectionSchemas(
    accountName: string,
    teradataConnectionId: string,
    database: string,
    sessionId?: string | null,
  ): CancelablePromise<Record<string, Array<string>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/teradata/{teradata_connection_id}/schemas',
      path: {
        account_name: accountName,
        teradata_connection_id: teradataConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        database: database,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Teradata Connection Tables
   * Read teradata tables.
   * @param accountName
   * @param teradataConnectionId
   * @param database
   * @param schema
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readTeradataConnectionTables(
    accountName: string,
    teradataConnectionId: string,
    database: string,
    schema?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<Record<string, Record<string, Array<string>>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/teradata/{teradata_connection_id}/tables',
      path: {
        account_name: accountName,
        teradata_connection_id: teradataConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        database: database,
        schema: schema,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Postgres Connection
   * Create new Postgres connection.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns PostgresConnection Successful Response
   * @throws ApiError
   */
  public createPostgresConnection(
    accountName: string,
    requestBody: PostgresConnectionCreate,
    sessionId?: string | null,
  ): CancelablePromise<PostgresConnection> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/postgres/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Postgres Connection
   * Read a Postgres connection.
   * @param accountName
   * @param postgresConnectionId
   * @param sessionId
   * @returns PostgresConnection Successful Response
   * @throws ApiError
   */
  public readPostgresConnection(
    accountName: string,
    postgresConnectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<PostgresConnection> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/{postgres_connection_id}',
      path: {
        account_name: accountName,
        postgres_connection_id: postgresConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Postgres Connection
   * Update Postgres connection.
   * @param accountName
   * @param postgresConnectionId
   * @param requestBody
   * @param sessionId
   * @returns PostgresConnection Successful Response
   * @throws ApiError
   */
  public updatePostgresConnection(
    accountName: string,
    postgresConnectionId: string,
    requestBody: PostgresConnectionUpdate,
    sessionId?: string | null,
  ): CancelablePromise<PostgresConnection> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/connections/{postgres_connection_id}',
      path: {
        account_name: accountName,
        postgres_connection_id: postgresConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Postgres Connection
   * Delete Postgres connection.
   * @param accountName
   * @param postgresConnectionId
   * @param sessionId
   * @returns PostgresConnection Successful Response
   * @throws ApiError
   */
  public deletePostgresConnection(
    accountName: string,
    postgresConnectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<PostgresConnection> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/connections/{postgres_connection_id}',
      path: {
        account_name: accountName,
        postgres_connection_id: postgresConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Postgres Connection Schemas
   * Read Postgres schemas.
   * @param accountName
   * @param postgresConnectionId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readPostgresConnectionSchemas(
    accountName: string,
    postgresConnectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<Record<string, Array<string>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/{postgres_connection_id}/schemas',
      path: {
        account_name: accountName,
        postgres_connection_id: postgresConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Postgres Connection Tables
   * Read Postgres tables.
   *
   * This endpoint returns a dictionary of tables for each schema in the database.
   *
   * Args:
   * db: SQLAlchemy session.
   * current_user: The current user.
   * account: The current account.
   * connection: The Postgres connection.
   * schema: Optional schema to filter tables by.
   * @param accountName
   * @param postgresConnectionId
   * @param schema
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readPostgresConnectionTables(
    accountName: string,
    postgresConnectionId: string,
    schema?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<Record<string, Record<string, Array<string>>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/{postgres_connection_id}/tables',
      path: {
        account_name: accountName,
        postgres_connection_id: postgresConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        schema: schema,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Postgres Connection Columns
   * Read Postgres columns.
   * @param accountName
   * @param postgresConnectionId
   * @param schema
   * @param table
   * @param sessionId
   * @returns TableColumn Successful Response
   * @throws ApiError
   */
  public readPostgresConnectionColumns(
    accountName: string,
    postgresConnectionId: string,
    schema: string,
    table: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<TableColumn>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/{postgres_connection_id}/columns',
      path: {
        account_name: accountName,
        postgres_connection_id: postgresConnectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        schema: schema,
        table: table,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Default Settings
   * Admin endpoint to get a default setting.
   * @param settingName
   * @param sessionId
   * @returns DefaultSetting Successful Response
   * @throws ApiError
   */
  public getDefaultSettings(
    settingName: string,
    sessionId?: string | null,
  ): CancelablePromise<DefaultSetting> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/admin/default-settings/{setting_name}',
      path: {
        setting_name: settingName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Default Setting
   * Admin endpoint to update a default setting.
   * @param settingName
   * @param requestBody
   * @param sessionId
   * @returns DefaultSetting Successful Response
   * @throws ApiError
   */
  public updateDefaultSetting(
    settingName: string,
    requestBody: DefaultSettingUpdate,
    sessionId?: string | null,
  ): CancelablePromise<DefaultSetting> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/admin/default-settings/{setting_name}',
      path: {
        setting_name: settingName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Star Schema Data Sources
   * Delete all SSDSs in an account.
   * @param accountName
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public deleteStarSchemaDataSources(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/admin/star_schema_data_source/{account_name}',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Question Modelcalls
   * Admin endpoint to get model call history for a question.
   * Admin endpoints require a superuser token. It's intended that an NS admin could log in, then
   * pass in any bumblebee cell id.
   * Returns:
   * This returns a JSON list of model calls in reverse chronological order, by
   * default only the most recent.
   * @param question
   * @param component
   * @param limit
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public getQuestionModelcalls(
    question: string,
    component?: PromptLogComponent | null,
    limit: number = 5,
    sessionId?: string | null,
  ): CancelablePromise<Array<any>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/admin/promptlog/modelcalls',
      cookies: {
        session_id: sessionId,
      },
      query: {
        question: question,
        component: component,
        limit: limit,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Backing Services
   * Admin endpoint to get all backing services.
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public getBackingServices(sessionId?: string | null): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/admin/backing-services',
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Search Zen Dimension Value
   * Admin endpoint to search for a Zen Dimension Value.
   * @param value
   * @param limit
   * @param explainAnalyze
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public searchZenDimensionValue(
    value: string,
    limit: number = 10,
    explainAnalyze: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/admin/search/zen_dimension_value',
      cookies: {
        session_id: sessionId,
      },
      query: {
        value: value,
        limit: limit,
        explain_analyze: explainAnalyze,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Ssds Connection
   * Get Connection for Star Schema Data Source.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns Connection Successful Response
   * @throws ApiError
   */
  public getSsdsConnection(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: string | null,
  ): CancelablePromise<Connection> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/connection',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Ssds Connection
   * Get Connection for Star Schema Data Source.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param newConnectionId
   * @param sessionId
   * @returns Connection Successful Response
   * @throws ApiError
   */
  public updateSsdsConnection(
    accountName: string,
    starSchemaDataSourceId: string,
    newConnectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<Connection> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/update_connection',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        new_connection_id: newConnectionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Upload Cube Csv
   * Import DMs from a cube csv file.
   *
   * We expect the csv to have the following columns:
   * Category,Name,Calculation,Filters,Type,Description,Schema,Sql
   *
   * An example row looks like the following:
   * InvoiceWaitingApproval,pendingInvoicesValue, `AMOUNT / INDEXCOUNT`,,  sum  ,
   * Value of Pending Invoices,
   * "{""measures"":[""InvoiceWaitingApproval.pendingInvoicesValue""]}",
   * "SELECT      sum(AMOUNT / INDEXCOUNT) FROM x",
   *
   * We currently only use Name, Category, Description, Schema, Sql.
   * @param accountName
   * @param ssdsId
   * @param formData
   * @param defaultDatabase
   * @param defaultSchema
   * @param updateExistingDimensionsMeasures
   * @param updateExistingState
   * @param sessionId
   * @returns StarSchemaDataSource Successful Response
   * @throws ApiError
   */
  public uploadCubeCsv(
    accountName: string,
    ssdsId: string,
    formData: Body_upload_cube_csv_api_v1_accounts__account_name__star_schema_data_source_upload_cube_csv_post,
    defaultDatabase?: string,
    defaultSchema?: string,
    updateExistingDimensionsMeasures: boolean = true,
    updateExistingState: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<StarSchemaDataSource> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/upload_cube_csv',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        ssds_id: ssdsId,
        default_database: defaultDatabase,
        default_schema: defaultSchema,
        update_existing_dimensions_measures: updateExistingDimensionsMeasures,
        update_existing_state: updateExistingState,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Extract Sqls From Csv
   * Extract SQLs from uploaded csv file for users to review.
   *
   * We expect the csv at least to have the following column:
   * sql
   *
   * We currently only use sql column.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param formData
   * @param sessionId
   * @returns SqlWithValidation Successful Response
   * @throws ApiError
   */
  public extractSqlsFromCsv(
    accountName: string,
    starSchemaDataSourceId: string,
    formData: Body_extract_sqls_from_csv_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__extract_sqls_from_csv_post,
    sessionId?: string | null,
  ): CancelablePromise<Array<SqlWithValidation>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/extract_sqls_from_csv',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Validate Sql
   * Validate the SQL.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns SqlWithValidation Successful Response
   * @throws ApiError
   */
  public validateSql(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: SqlWithValidation,
    sessionId?: string | null,
  ): CancelablePromise<SqlWithValidation> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/validate_sql',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Learn Dms From Sqls
   * Learn DMs from list of SQLs.
   *
   * This endpoint will first remove all temporarily extracted DMs from SQLs from previous run in the SSDS.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public learnDmsFromSqls(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: Array<SqlValidationAPICreate>,
    sessionId?: string | null,
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/learn_dms_from_sqls',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Sql Validation
   * Recreates the SQL validation, effectively updating it.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sqlValidationId
   * @param requestBody
   * @param sessionId
   * @returns SqlValidation Successful Response
   * @throws ApiError
   */
  public updateSqlValidation(
    accountName: string,
    starSchemaDataSourceId: string,
    sqlValidationId: string,
    requestBody: SqlValidationAPIUpdate,
    sessionId?: string | null,
  ): CancelablePromise<SqlValidation> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/sql_validation/{sql_validation_id}',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
        sql_validation_id: sqlValidationId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Remove Sql Validation
   * Delete one SqlValidation.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sqlValidationId
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public removeSqlValidation(
    accountName: string,
    starSchemaDataSourceId: string,
    sqlValidationId: string,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/sql_validation/{sql_validation_id}',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
        sql_validation_id: sqlValidationId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Dimension Metric Validation
   * Updates the DimensionMetricValidation.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param dmValidationId
   * @param requestBody
   * @param sessionId
   * @returns DimensionMetricValidation Successful Response
   * @throws ApiError
   */
  public updateDimensionMetricValidation(
    accountName: string,
    starSchemaDataSourceId: string,
    dmValidationId: string,
    requestBody: DimensionMetricValidationAPIUpdate,
    sessionId?: string | null,
  ): CancelablePromise<DimensionMetricValidation> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/dimension_metric_validation/{dm_validation_id}',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
        dm_validation_id: dmValidationId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Sql Validations
   * Get the learn DMs from list of SQLs for an SSDS.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns SqlValidation Successful Response
   * @throws ApiError
   */
  public readSqlValidations(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<SqlValidation>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/sql_validation',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Remove Sql Validations For Ssds
   * Delete the learned DMs an SSDS. This effectively resets the drafted learned DMs.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public removeSqlValidationsForSsds(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/sql_validation',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Inject New Dimensions And Metrics
   * Inject new dimensions and metrics from the current SqlValidation objects in the SSDS.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param updateExistingDimensionsMeasures
   * @param updateExistingState
   * @param sessionId
   * @param requestBody
   * @returns Task Successful Response
   * @throws ApiError
   */
  public injectNewDimensionsAndMetrics(
    accountName: string,
    starSchemaDataSourceId: string,
    updateExistingDimensionsMeasures: boolean = true,
    updateExistingState: boolean = true,
    sessionId?: string | null,
    requestBody?: StarSchemaDataSourceEnrichment,
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/inject_new_dimensions_and_metrics',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        update_existing_dimensions_measures: updateExistingDimensionsMeasures,
        update_existing_state: updateExistingState,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Remapped Ssds
   * Upload SSDS created with remapping from SQL CSV.
   * @param accountName
   * @param defaultDatabase
   * @param defaultSchema
   * @param dialect
   * @param requestBody
   * @param slimSsds
   * @param sessionId
   * @returns StarSchemaDataSourceBase Successful Response
   * @throws ApiError
   */
  public createRemappedSsds(
    accountName: string,
    defaultDatabase: string,
    defaultSchema: string,
    dialect: Dialect,
    requestBody: Body_create_remapped_ssds_api_v1_accounts__account_name__star_schema_data_source_remap_ssds_post,
    slimSsds: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<StarSchemaDataSourceBase> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/remap_ssds',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        default_database: defaultDatabase,
        default_schema: defaultSchema,
        dialect: dialect,
        slim_ssds: slimSsds,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Generate Candidate Questions
   * Generate candidate questions for Star Schema Data Source.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param purgeExisting
   * @param coldStart
   * @param sessionId
   * @param requestBody
   * @returns Task Successful Response
   * @throws ApiError
   */
  public generateCandidateQuestions(
    accountName: string,
    starSchemaDataSourceId: string,
    purgeExisting: boolean = false,
    coldStart: boolean = false,
    sessionId?: string | null,
    requestBody?: Array<string> | null,
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/generate_candidate_questions',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        purge_existing: purgeExisting,
        cold_start: coldStart,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Candidate Demonstration Cells Count
   * Count candidate demonstration cells for Star Schema Data Source.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @param requestBody
   * @returns number Successful Response
   * @throws ApiError
   */
  public getCandidateDemonstrationCellsCount(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: string | null,
    requestBody?: FeedbackFilter | null,
  ): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/candidate_demonstration_cells_count',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Candidate Demonstration Cells
   * Get candidate demonstration cells for Star Schema Data Source.
   *
   * NOTE: The returned cells do not contain retrieved dimensions or metrics.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param skip
   * @param limit
   * @param orderBy
   * @param descending
   * @param sessionId
   * @param requestBody
   * @returns BumblebeeCellBasic Successful Response
   * @throws ApiError
   */
  public getCandidateDemonstrationCells(
    accountName: string,
    starSchemaDataSourceId: string,
    skip?: number,
    limit?: number | null,
    orderBy?: CellOrderBy,
    descending: boolean = false,
    sessionId?: string | null,
    requestBody?: FeedbackFilter | null,
  ): CancelablePromise<Array<BumblebeeCellBasic>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/candidate_demonstration_cells',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        skip: skip,
        limit: limit,
        order_by: orderBy,
        descending: descending,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Account Ssds Feedback
   * Get feedback for the given account and ssds.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns Feedback Successful Response
   * @throws ApiError
   */
  public getAccountSsdsFeedback(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<Feedback>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/feedback',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Search Verified Questions
   * Get similar ai-reference questions for an SSDS.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param question
   * @param limit
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public searchVerifiedQuestions(
    accountName: string,
    starSchemaDataSourceId: string,
    question: string,
    limit: number = 10,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/feedback',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        question: question,
        limit: limit,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Similar Ssds Feedback
   * Get conflicting feedback for the given account and ssds.
   *
   * Conflicting feedback are entries that have the same or similar questions
   * which map to different semantic queries.
   *
   * If cosine_similarity_threshold is provided, then we use cosine_similarity of the
   * embeddings to group feedback, not just textual similarity.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param maxCosineDistance
   * @param sessionId
   * @returns BumblebeeCellBasic Successful Response
   * @throws ApiError
   */
  public getSimilarSsdsFeedback(
    accountName: string,
    starSchemaDataSourceId: string,
    maxCosineDistance: number = 0.01,
    sessionId?: string | null,
  ): CancelablePromise<Array<Array<BumblebeeCellBasic>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/similar_feedback',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        max_cosine_distance: maxCosineDistance,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Dimension Expr Type
   * Get the expr_type of a dimension, then check if already exists.
   *
   * Return true if the sql/type/data_source_id combo already exists, false otherwise.
   * @param accountName
   * @param sql
   * @param dataSourceId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public getDimensionExprType(
    accountName: string,
    sql: string,
    dataSourceId: string,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/expr_type',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        sql: sql,
        data_source_id: dataSourceId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Validate Dimension Creation
   * Validate a dimension.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param updateIndex
   * @param updateDimensionGroups
   * @param updateExprTypes
   * @param updateDisplayTypes
   * @param sessionId
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public validateDimensionCreation(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: DimensionViewWithoutDialect,
    updateIndex: boolean = true,
    updateDimensionGroups: boolean = true,
    updateExprTypes: boolean = true,
    updateDisplayTypes: boolean = true,
    sessionId?: string | null,
  ): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/validate_dimension_creation',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        update_index: updateIndex,
        update_dimension_groups: updateDimensionGroups,
        update_expr_types: updateExprTypes,
        update_display_types: updateDisplayTypes,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Dimension
   * Create a dimension.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public createDimension(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: Body_create_dimension_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__dimensions__post,
    sessionId?: string | null,
  ): CancelablePromise<DimensionDetail | null> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/dimensions/',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Dimension
   * Read a dimension.
   * @param dimensionId
   * @param accountName
   * @param sessionId
   * @returns DimensionDetail Successful Response
   * @throws ApiError
   */
  public readDimension(
    dimensionId: string,
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<DimensionDetail> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}',
      path: {
        dimension_id: dimensionId,
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Dimension
   * Update a dimension.
   * @param accountName
   * @param dimensionId
   * @param requestBody
   * @param sessionId
   * @returns DimensionDetail Successful Response
   * @throws ApiError
   */
  public updateDimension(
    accountName: string,
    dimensionId: string,
    requestBody: DimensionUpdate,
    sessionId?: string | null,
  ): CancelablePromise<DimensionDetail> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}',
      path: {
        account_name: accountName,
        dimension_id: dimensionId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Dimension
   * Remove dimension from database.
   *
   * Note: This endpoint is for simple deletion of a dimension. It will not check for dimension usage and
   * handle the full flow for dimension deletion (invalidating examples and derived dms that use this dimension).
   * Use with caution, delete only when you are sure the dimension is not needed.
   * @param accountName
   * @param dimensionId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteDimension(
    accountName: string,
    dimensionId: string,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}',
      path: {
        account_name: accountName,
        dimension_id: dimensionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Dimension Is Sensitive
   * Update a dimension for is_sensitive.
   * @param accountName
   * @param dimensionId
   * @param isSensitive
   * @param sessionId
   * @returns DimensionDetail Successful Response
   * @throws ApiError
   */
  public updateDimensionIsSensitive(
    accountName: string,
    dimensionId: string,
    isSensitive: boolean,
    sessionId?: string | null,
  ): CancelablePromise<DimensionDetail> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}/is_sensitive',
      path: {
        account_name: accountName,
        dimension_id: dimensionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        is_sensitive: isSensitive,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Dimension Is Sensitive Impact
   * Compute the impact of updating a dimension for is_sensitive.
   * @param accountName
   * @param dimensionId
   * @param isSensitive
   * @param sessionId
   * @returns DMSensitiveKnowledgeImpact Successful Response
   * @throws ApiError
   */
  public updateDimensionIsSensitiveImpact(
    accountName: string,
    dimensionId: string,
    isSensitive: boolean,
    sessionId?: string | null,
  ): CancelablePromise<DMSensitiveKnowledgeImpact> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}/is_sensitive_impact',
      path: {
        account_name: accountName,
        dimension_id: dimensionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        is_sensitive: isSensitive,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Dimension Name
   * Update a dimension's name.
   * @param accountName
   * @param dimensionId
   * @param name
   * @param sessionId
   * @returns DimensionDetail Successful Response
   * @throws ApiError
   */
  public updateDimensionName(
    accountName: string,
    dimensionId: string,
    name: string,
    sessionId?: string | null,
  ): CancelablePromise<DimensionDetail> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}/name',
      path: {
        account_name: accountName,
        dimension_id: dimensionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        name: name,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Dimensions
   * Update a dimension.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns DimensionDetail Successful Response
   * @throws ApiError
   */
  public updateDimensions(
    accountName: string,
    requestBody: Array<DimensionUpdateWithId>,
    sessionId?: string | null,
  ): CancelablePromise<Array<DimensionDetail>> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Dimensions
   * Remove dimensions from database.
   *
   * Note: This endpoint is for simple deletion of a dimension. It will not check for dimension usage and
   * handle the full flow for dimension deletion (invalidating examples and derived dms that use this dimension).
   * Use with caution, delete only when you are sure the dimension is not needed.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteDimensions(
    accountName: string,
    requestBody: Array<DeleteRequest>,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Dimension State
   * Update a dimension's state.
   * @param accountName
   * @param dimensionId
   * @param requestBody
   * @param validateTransition
   * @param sessionId
   * @returns DimensionDetail Successful Response
   * @throws ApiError
   */
  public updateDimensionState(
    accountName: string,
    dimensionId: string,
    requestBody: StateUpdate,
    validateTransition: boolean = true,
    sessionId?: string | null,
  ): CancelablePromise<DimensionDetail> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}/state',
      path: {
        account_name: accountName,
        dimension_id: dimensionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        validate_transition: validateTransition,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Dimensions State
   * Bulk update multiple dimension's state.
   * @param accountName
   * @param requestBody
   * @param validateTransition
   * @param sessionId
   * @returns DimensionDetail Successful Response
   * @throws ApiError
   */
  public updateDimensionsState(
    accountName: string,
    requestBody: Array<StateUpdateWithId>,
    validateTransition: boolean = true,
    sessionId?: string | null,
  ): CancelablePromise<Array<DimensionDetail>> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimension_states',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        validate_transition: validateTransition,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Set Dimension Display Type
   * Set a dimension's display_type
   * @param dimensionId
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns DimensionDetail Successful Response
   * @throws ApiError
   */
  public setDimensionDisplayType(
    dimensionId: string,
    accountName: string,
    requestBody: DisplayFieldsUpdateAPI,
    sessionId?: string | null,
  ): CancelablePromise<DimensionDetail> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}/display',
      path: {
        dimension_id: dimensionId,
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Ssds Dimensions Count
   * Get count of Dimensions for Star Schema Data Source.
   *
   * If DataSourceIds are specified, then only dimensions that reference
   * the specified Data Sources are counted.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param search
   * @param state
   * @param origin
   * @param dataSourceIds
   * @param sessionId
   * @returns number Successful Response
   * @throws ApiError
   */
  public getSsdsDimensionsCount(
    accountName: string,
    starSchemaDataSourceId: string,
    search: string = '',
    state?: KnowledgeStateFilter | null,
    origin?: KnowledgeOriginFilter | null,
    dataSourceIds?: Array<string> | null,
    sessionId?: string | null,
  ): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/dimensions_count',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        search: search,
        state: state,
        origin: origin,
        data_source_ids: dataSourceIds,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Ssds Dimensions
   * Get Dimensions for Star Schema Data Source.
   *
   * If DataSourceIds are specified, then only dimensions that reference
   * the specified Data Sources are returned.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param limit
   * @param skip
   * @param search
   * @param state
   * @param origin
   * @param dataSourceIds
   * @param sessionId
   * @returns DimensionView Successful Response
   * @throws ApiError
   */
  public getSsdsDimensions(
    accountName: string,
    starSchemaDataSourceId: string,
    limit?: number | null,
    skip?: number,
    search: string = '',
    state?: KnowledgeStateFilter | null,
    origin?: KnowledgeOriginFilter | null,
    dataSourceIds?: Array<string> | null,
    sessionId?: string | null,
  ): CancelablePromise<Array<DimensionView>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/dimensions',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
        skip: skip,
        search: search,
        state: state,
        origin: origin,
        data_source_ids: dataSourceIds,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Ssds Grouped Dimensions Count
   * Get count of Dimensions for Star Schema Data Source.
   *
   * If DataSourceIds are specified, then only dimensions that reference
   * the specified Data Sources are counted.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param search
   * @param state
   * @param origin
   * @param dataSourceIds
   * @param sessionId
   * @returns number Successful Response
   * @throws ApiError
   */
  public getSsdsGroupedDimensionsCount(
    accountName: string,
    starSchemaDataSourceId: string,
    search: string = '',
    state?: KnowledgeStateFilter | null,
    origin?: KnowledgeOriginFilter | null,
    dataSourceIds?: Array<string> | null,
    sessionId?: string | null,
  ): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/grouped_dimensions_count',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        search: search,
        state: state,
        origin: origin,
        data_source_ids: dataSourceIds,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Ssds Grouped Dimensions
   * Get Dimensions for Star Schema Data Source.
   *
   * If DataSourceIds are specified, then only dimensions that reference
   * the specified Data Sources are returned.
   *
   * Each DimensionViewWithGroup will have a dimension which is the displayed dim used in the UI.
   * If cell_id is specified, then the displayed dim is chosen to be relevant
   * for that specific cell, otherwise the first dimension in the group is chosen.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param limit
   * @param skip
   * @param search
   * @param state
   * @param origin
   * @param dataSourceIds
   * @param cellId
   * @param sessionId
   * @returns DimensionViewWithGroup Successful Response
   * @throws ApiError
   */
  public getSsdsGroupedDimensions(
    accountName: string,
    starSchemaDataSourceId: string,
    limit?: number | null,
    skip?: number,
    search: string = '',
    state?: KnowledgeStateFilter | null,
    origin?: KnowledgeOriginFilter | null,
    dataSourceIds?: Array<string> | null,
    cellId?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<Array<DimensionViewWithGroup>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/grouped_dimensions',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
        skip: skip,
        search: search,
        state: state,
        origin: origin,
        data_source_ids: dataSourceIds,
        cell_id: cellId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Merge Dimensions
   * Merge multiple dimensions into one dimension.
   *
   * This endpoint lets users merge multiple (similar) dimensions (ids_to_merge) into one dimension
   * (dimension) to maintain SSDS better. Users can also update the dimension using dimension_update.
   * @param accountName
   * @param dimensionId
   * @param requestBody
   * @param sessionId
   * @returns DimensionDetail Successful Response
   * @throws ApiError
   */
  public mergeDimensions(
    accountName: string,
    dimensionId: string,
    requestBody: Body_merge_dimensions_api_v1_accounts__account_name__star_schema_data_source_dimensions__dimension_id__merge_patch,
    sessionId?: string | null,
  ): CancelablePromise<DimensionDetail> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}/merge',
      path: {
        account_name: accountName,
        dimension_id: dimensionId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Relevant Conversations Cells By Dimension
   * Get conversations and cells that use the given dimension.
   * @param accountName
   * @param dimensionId
   * @param limit
   * @param skip
   * @param sessionId
   * @returns ConversationCellTuple Successful Response
   * @throws ApiError
   */
  public getRelevantConversationsCellsByDimension(
    accountName: string,
    dimensionId: string,
    limit: number = 200,
    skip?: number,
    sessionId?: string | null,
  ): CancelablePromise<Array<ConversationCellTuple>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}/relevant_conversations_cells',
      path: {
        account_name: accountName,
        dimension_id: dimensionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
        skip: skip,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Dimension Sample Values
   * Get dimension sample values.
   * @param accountName
   * @param dimensionId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public getDimensionSampleValues(
    accountName: string,
    dimensionId: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<any>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/dimensions/{dimension_id}/sample_values',
      path: {
        account_name: accountName,
        dimension_id: dimensionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Measure Expr Type
   * Get the expr_type of a measure, then check if already exists.
   *
   * Returns:
   * A 409 if the sql/type/data_source_id combo already exists
   * The expr_type of the expr if a measure with the given sql doesn't exist
   * @param accountName
   * @param sql
   * @param dataSourceId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public getMeasureExprType(
    accountName: string,
    sql: string,
    dataSourceId: string,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/measures/expr_type',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        sql: sql,
        data_source_id: dataSourceId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Measure
   * Remove measure from database.
   *
   * Note: This endpoint is for simple deletion of a metric. It will not check for metric usage and
   * handle the full flow for metric deletion (invalidating examples and derived dms that use this metric).
   * Use with caution, delete only when you are sure the metric is not needed.
   * @param accountName
   * @param measureId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteMeasure(
    accountName: string,
    measureId: string,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/measures/{measure_id}',
      path: {
        account_name: accountName,
        measure_id: measureId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Measure
   * Read a measure.
   * @param measureId
   * @param accountName
   * @param sessionId
   * @returns MeasureDetail Successful Response
   * @throws ApiError
   */
  public readMeasure(
    measureId: string,
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<MeasureDetail> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/measures/{measure_id}',
      path: {
        measure_id: measureId,
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Measure
   * Update a measure.
   * @param accountName
   * @param measureId
   * @param requestBody
   * @param sessionId
   * @returns MeasureDetail Successful Response
   * @throws ApiError
   */
  public updateMeasure(
    accountName: string,
    measureId: string,
    requestBody: MeasureUpdate,
    sessionId?: string | null,
  ): CancelablePromise<MeasureDetail> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/measures/{measure_id}',
      path: {
        account_name: accountName,
        measure_id: measureId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Measures
   * Remove measures from database.
   *
   * Note: This endpoint is for simple deletion of a metric. It will not check for metric usage and
   * handle the full flow for metric deletion (invalidating examples and derived dms that use this metric).
   * Use with caution, delete only when you are sure the metric is not needed.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteMeasures(
    accountName: string,
    requestBody: Array<DeleteRequest>,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/measures',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Measures
   * Bulk update multiple measures at once.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns MeasureDetail Successful Response
   * @throws ApiError
   */
  public updateMeasures(
    accountName: string,
    requestBody: Array<MeasureUpdateWithId>,
    sessionId?: string | null,
  ): CancelablePromise<Array<MeasureDetail>> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/measures',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Validate Measure Creation
   * Validate a measure.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public validateMeasureCreation(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: MetricViewWithoutDialect,
    sessionId?: string | null,
  ): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/validate_measure_creation',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Measure
   * Create a measure.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public createMeasure(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: Body_create_measure_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__measures__post,
    sessionId?: string | null,
  ): CancelablePromise<MeasureDetail | null> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/measures/',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Measure Is Sensitive Impact
   * Compute the impact of updating a measure for is_sensitive.
   * @param accountName
   * @param measureId
   * @param isSensitive
   * @param sessionId
   * @returns DMSensitiveKnowledgeImpact Successful Response
   * @throws ApiError
   */
  public updateMeasureIsSensitiveImpact(
    accountName: string,
    measureId: string,
    isSensitive: boolean,
    sessionId?: string | null,
  ): CancelablePromise<DMSensitiveKnowledgeImpact> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/measures/{measure_id}/is_sensitive_impact',
      path: {
        account_name: accountName,
        measure_id: measureId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        is_sensitive: isSensitive,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Measure Is Sensitive
   * Update a measure for is_sensitive.
   * @param accountName
   * @param measureId
   * @param isSensitive
   * @param sessionId
   * @returns MeasureDetail Successful Response
   * @throws ApiError
   */
  public updateMeasureIsSensitive(
    accountName: string,
    measureId: string,
    isSensitive: boolean,
    sessionId?: string | null,
  ): CancelablePromise<MeasureDetail> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/measures/{measure_id}/is_sensitive',
      path: {
        account_name: accountName,
        measure_id: measureId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        is_sensitive: isSensitive,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Measure Name
   * Update a measure's name.
   * @param accountName
   * @param measureId
   * @param name
   * @param sessionId
   * @returns MeasureDetail Successful Response
   * @throws ApiError
   */
  public updateMeasureName(
    accountName: string,
    measureId: string,
    name: string,
    sessionId?: string | null,
  ): CancelablePromise<MeasureDetail> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/measures/{measure_id}/name',
      path: {
        account_name: accountName,
        measure_id: measureId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        name: name,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Measure State
   * Update a measure's state.
   * @param accountName
   * @param measureId
   * @param requestBody
   * @param validateTransition
   * @param sessionId
   * @returns MeasureDetail Successful Response
   * @throws ApiError
   */
  public updateMeasureState(
    accountName: string,
    measureId: string,
    requestBody: StateUpdate,
    validateTransition: boolean = true,
    sessionId?: string | null,
  ): CancelablePromise<MeasureDetail> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/measures/{measure_id}/state',
      path: {
        account_name: accountName,
        measure_id: measureId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        validate_transition: validateTransition,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Measures State
   * Bulk update multiple measure's state.
   * @param accountName
   * @param requestBody
   * @param validateTransition
   * @param sessionId
   * @returns MeasureDetail Successful Response
   * @throws ApiError
   */
  public updateMeasuresState(
    accountName: string,
    requestBody: Array<StateUpdateWithId>,
    validateTransition: boolean = true,
    sessionId?: string | null,
  ): CancelablePromise<Array<MeasureDetail>> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/measure_states',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        validate_transition: validateTransition,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Set Measure Display Type
   * Set a measure's display_type.
   * @param measureId
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns MeasureDetail Successful Response
   * @throws ApiError
   */
  public setMeasureDisplayType(
    measureId: string,
    accountName: string,
    requestBody: DisplayFieldsUpdateAPI,
    sessionId?: string | null,
  ): CancelablePromise<MeasureDetail> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/measures/{measure_id}/display',
      path: {
        measure_id: measureId,
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Ssds Metrics Count
   * Get the count of Metrics for Star Schema Data Source.
   *
   * This endpoint combines the metrics from the SSDS with all the measures from each Data Source.
   * If DataSourceIds are specified, then only metrics that reference
   * the specified Data Sources are counted.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param search
   * @param state
   * @param origin
   * @param dataSourceIds
   * @param sessionId
   * @returns number Successful Response
   * @throws ApiError
   */
  public getSsdsMetricsCount(
    accountName: string,
    starSchemaDataSourceId: string,
    search: string = '',
    state?: KnowledgeStateFilter | null,
    origin?: KnowledgeOriginFilter | null,
    dataSourceIds?: Array<string> | null,
    sessionId?: string | null,
  ): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/metrics_count',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        search: search,
        state: state,
        origin: origin,
        data_source_ids: dataSourceIds,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Ssds Metrics
   * Get Metrics for Star Schema Data Source.
   *
   * This endpoint combines the metrics from the SSDS with all the measures from each Data Source.
   * If DataSourceIds are specified, then only metrics that reference
   * the specified Data Sources are returned.
   *
   * If cell_id is specified, then the metrics will be filtered out if they are
   * state NEW and not present in the cell's conversation.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param limit
   * @param skip
   * @param search
   * @param state
   * @param origin
   * @param dataSourceIds
   * @param cellId
   * @param sessionId
   * @returns MetricView Successful Response
   * @throws ApiError
   */
  public getSsdsMetrics(
    accountName: string,
    starSchemaDataSourceId: string,
    limit?: number | null,
    skip?: number,
    search: string = '',
    state?: KnowledgeStateFilter | null,
    origin?: KnowledgeOriginFilter | null,
    dataSourceIds?: Array<string> | null,
    cellId?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<Array<MetricView>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/metrics',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
        skip: skip,
        search: search,
        state: state,
        origin: origin,
        data_source_ids: dataSourceIds,
        cell_id: cellId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Merge Measures
   * Merge multiple measures into one measure.
   *
   * This endpoint lets users merge multiple (similar) measures (ids_to_merge) into one measure
   * (measure) to maintain SSDS better. Users can also update the measure using measure_update.
   * @param accountName
   * @param measureId
   * @param requestBody
   * @param sessionId
   * @returns MeasureDetail Successful Response
   * @throws ApiError
   */
  public mergeMeasures(
    accountName: string,
    measureId: string,
    requestBody: Body_merge_measures_api_v1_accounts__account_name__star_schema_data_source_measures__measure_id__merge_patch,
    sessionId?: string | null,
  ): CancelablePromise<MeasureDetail> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/measures/{measure_id}/merge',
      path: {
        account_name: accountName,
        measure_id: measureId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Relevant Conversations Cells By Measure
   * Get conversations and cells that use the given measure.
   * @param accountName
   * @param measureId
   * @param limit
   * @param skip
   * @param sessionId
   * @returns ConversationCellTuple Successful Response
   * @throws ApiError
   */
  public getRelevantConversationsCellsByMeasure(
    accountName: string,
    measureId: string,
    limit: number = 200,
    skip?: number,
    sessionId?: string | null,
  ): CancelablePromise<Array<ConversationCellTuple>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/measures/{measure_id}/relevant_conversations_cells',
      path: {
        account_name: accountName,
        measure_id: measureId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
        skip: skip,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Account Ssds Named Filters
   * Get Named Filters from the given account and ssds.
   *
   * If DataSourceIds are specified, then only metrics that reference
   * the specified Data Sources are returned.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param limit
   * @param skip
   * @param state
   * @param dataSourceIds
   * @param sessionId
   * @returns NamedFilterBase Successful Response
   * @throws ApiError
   */
  public getAccountSsdsNamedFilters(
    accountName: string,
    starSchemaDataSourceId: string,
    limit?: number | null,
    skip?: number,
    state?: KnowledgeStateFilter | null,
    dataSourceIds?: Array<string> | null,
    sessionId?: string | null,
  ): CancelablePromise<Array<NamedFilterBase>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filters',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
        skip: skip,
        state: state,
        data_source_ids: dataSourceIds,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Named Filter
   * Create a Named Filter.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns NamedFilterBase Successful Response
   * @throws ApiError
   */
  public createNamedFilter(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: NamedFilterCreateAPI,
    sessionId?: string | null,
  ): CancelablePromise<NamedFilterBase> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filters',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Named Filters
   * Bulk update multiple named filters in a single API call.
   * @param starSchemaDataSourceId
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns NamedFilterBase Successful Response
   * @throws ApiError
   */
  public updateNamedFilters(
    starSchemaDataSourceId: string,
    accountName: string,
    requestBody: Array<NamedFilterUpdateAPIWithId>,
    sessionId?: string | null,
  ): CancelablePromise<Array<NamedFilterBase>> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filters',
      path: {
        star_schema_data_source_id: starSchemaDataSourceId,
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Named Filters
   * Delete the specified named filter from the ssds.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteNamedFilters(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: Array<DeleteRequest>,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filters',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Account Ssds Named Filters Count
   * Get Named Filters from the given account and ssds.
   *
   * If DataSourceIds are specified, then only metrics that reference
   * the specified Data Sources are returned.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param state
   * @param dataSourceIds
   * @param sessionId
   * @returns number Successful Response
   * @throws ApiError
   */
  public getAccountSsdsNamedFiltersCount(
    accountName: string,
    starSchemaDataSourceId: string,
    state?: KnowledgeStateFilter | null,
    dataSourceIds?: Array<string> | null,
    sessionId?: string | null,
  ): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filters_count',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        state: state,
        data_source_ids: dataSourceIds,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Named Filter State
   * Update a filter's state.
   * @param accountName
   * @param namedFilterId
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns NamedFilterBase Successful Response
   * @throws ApiError
   */
  public updateNamedFilterState(
    accountName: string,
    namedFilterId: string,
    starSchemaDataSourceId: string,
    requestBody: StateUpdate,
    sessionId?: string | null,
  ): CancelablePromise<NamedFilterBase> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filters/{named_filter_id}/state',
      path: {
        account_name: accountName,
        named_filter_id: namedFilterId,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Named Filters State
   * Bulk update multiple filter's state.
   * @param starSchemaDataSourceId
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns NamedFilterBase Successful Response
   * @throws ApiError
   */
  public updateNamedFiltersState(
    starSchemaDataSourceId: string,
    accountName: string,
    requestBody: Array<StateUpdateWithId>,
    sessionId?: string | null,
  ): CancelablePromise<Array<NamedFilterBase>> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filter_states',
      path: {
        star_schema_data_source_id: starSchemaDataSourceId,
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Named Filter
   * Update a Named Filter.
   * @param accountName
   * @param namedFilterId
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns NamedFilterBase Successful Response
   * @throws ApiError
   */
  public updateNamedFilter(
    accountName: string,
    namedFilterId: string,
    starSchemaDataSourceId: string,
    requestBody: NamedFilterUpdateAPI,
    sessionId?: string | null,
  ): CancelablePromise<NamedFilterBase> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filters/{named_filter_id}',
      path: {
        account_name: accountName,
        named_filter_id: namedFilterId,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Named Filter
   * Delete the specified named filter from the ssds.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param namedFilterId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteNamedFilter(
    accountName: string,
    starSchemaDataSourceId: string,
    namedFilterId: string,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filters/{named_filter_id}',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
        named_filter_id: namedFilterId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Named Filter Valid Operators
   * Get named filter options for SSDS Dimensions.
   *
   * This is analogous to BB Cell's filter options.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns NamedFilterOptionConditionValidOperatorsWithGroup Successful Response
   * @throws ApiError
   */
  public getNamedFilterValidOperators(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: string | null,
  ): CancelablePromise<
    Array<NamedFilterOptionConditionValidOperatorsWithGroup>
  > {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/filter_options',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Identifiers
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param updateIndex
   * @param updateDimensionGroups
   * @param updateExprTypes
   * @param updateDisplayTypes
   * @param sessionId
   * @returns StarSchemaDataSource Successful Response
   * @throws ApiError
   */
  public createIdentifiers(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: Array<Identifier>,
    updateIndex: boolean = true,
    updateDimensionGroups: boolean = true,
    updateExprTypes: boolean = true,
    updateDisplayTypes: boolean = true,
    sessionId?: string | null,
  ): CancelablePromise<StarSchemaDataSource> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/identifiers',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        update_index: updateIndex,
        update_dimension_groups: updateDimensionGroups,
        update_expr_types: updateExprTypes,
        update_display_types: updateDisplayTypes,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Ssds Identifiers
   * Get Identifiers for Star Schema Data Source.
   *
   * If DataSourceIds are specified, then only dimensions that reference
   * the specified Data Sources are returned.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param dataSourceIds
   * @param sessionId
   * @returns Identifier Successful Response
   * @throws ApiError
   */
  public getSsdsIdentifiers(
    accountName: string,
    starSchemaDataSourceId: string,
    dataSourceIds?: Array<string> | null,
    sessionId?: string | null,
  ): CancelablePromise<Array<Identifier>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/identifiers',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        data_source_ids: dataSourceIds,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Ssds Join Sqls
   * Get the joins implied by the identifiers on an ssds.
   *
   * Zenlytics always does <foreign key> left join <primary key>.
   *
   * This function makes a lot of assumptions e.g.
   * there must always exist exactly one foreign key for each identifier name
   *
   * This assumption should always be true in the datasets we control. This may break
   * later for datasets we don't control.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns SSDSJoin Successful Response
   * @throws ApiError
   */
  public getSsdsJoinSqls(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<SSDSJoin>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/join_sqls',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Ssds Suggest Identifiers
   * Suggest Identifiers for Star Schema Data Source.
   *
   * This only works for primary/foreign key relationships. The model won't suggest anything
   * fancier like a join with a condition or any an inner join.
   *
   * Args:
   * - overwrite_cache: whether to force the model to recompute, rather than using a cached result
   * - temperature: the randomness of the model. 0.0 is deterministic, 1.0 is random
   * ref: https://platform.openai.com/docs/api-reference/audio
   * - max_tokens: the maximum number of tokens to generate. This is model dependent.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param maxTokens
   * @param temperature
   * @param overwriteCache
   * @param sessionId
   * @returns IdentifierSuggestionResponse Successful Response
   * @throws ApiError
   */
  public ssdsSuggestIdentifiers(
    accountName: string,
    starSchemaDataSourceId: string,
    maxTokens: number = 4000,
    temperature?: number,
    overwriteCache: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<IdentifierSuggestionResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/identifiers/suggestions',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        max_tokens: maxTokens,
        temperature: temperature,
        overwrite_cache: overwriteCache,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Ssds Joins
   * Get Joins for Star Schema Data Source.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns Join Successful Response
   * @throws ApiError
   */
  public getSsdsJoins(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<Join>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/joins',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Joins
   * Create Joins for Star Schema Data Source.
   *
   * we will return the ambiguous join groups,
   * i.e. a list of join groups that have more than one bridge tables.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param overwrite
   * @param sessionId
   * @returns JoinGroup Successful Response
   * @throws ApiError
   */
  public createJoins(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: Array<Join>,
    overwrite: boolean = true,
    sessionId?: string | null,
  ): CancelablePromise<Array<JoinGroup>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/joins',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        overwrite: overwrite,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Composite Key Joins
   * Create Joins for Star Schema Data Source.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns JoinGroup Successful Response
   * @throws ApiError
   */
  public createCompositeKeyJoins(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: JoinGroup,
    sessionId?: string | null,
  ): CancelablePromise<JoinGroup> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/composite_key',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Validate Join
   * Validate Joins for Star Schema Data Source.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param numRowsOfLeftTable
   * @param numRowsOfRightTable
   * @param sessionId
   * @returns JoinValidationResponse Successful Response
   * @throws ApiError
   */
  public validateJoin(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: Join,
    numRowsOfLeftTable?: number | null,
    numRowsOfRightTable?: number | null,
    sessionId?: string | null,
  ): CancelablePromise<JoinValidationResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/joins/validate',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        num_rows_of_left_table: numRowsOfLeftTable,
        num_rows_of_right_table: numRowsOfRightTable,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Retrieve
   * Retrieve columns, dimensions, measures and top values.
   *
   * Endpoint used for evaluation purposes only.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param question
   * @param sessionId
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public retrieve(
    accountName: string,
    starSchemaDataSourceId: string,
    question: string,
    sessionId?: string | null,
    requestBody?: RetrievalParams,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/retrieve',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        question: question,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Retrieve Ner
   * Retrieve filter values from a question.
   * @param question
   * @param accountName
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public retrieveNer(
    question: string,
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/retrieve/ner',
      cookies: {
        session_id: sessionId,
      },
      query: {
        question: question,
        account_name: accountName,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Retrieve Text Search
   * Retrieve sample values using text search.
   * @param starSchemaDataSourceId
   * @param question
   * @param accountName
   * @param numValuesToRetrieve
   * @param useDmGroup
   * @param explainAnalyze
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public retrieveTextSearch(
    starSchemaDataSourceId: string,
    question: string,
    accountName: string,
    numValuesToRetrieve: number = 5,
    useDmGroup: boolean = false,
    explainAnalyze: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/star_schema_data_source/{star_schema_data_source_id}/retrieve/text',
      path: {
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        question: question,
        num_values_to_retrieve: numValuesToRetrieve,
        use_dm_group: useDmGroup,
        explain_analyze: explainAnalyze,
        account_name: accountName,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Retrieve Embedding Search
   * Retrieve sample values using embedding search.
   * @param starSchemaDataSourceId
   * @param question
   * @param accountName
   * @param numValuesToRetrieve
   * @param useDmGroup
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public retrieveEmbeddingSearch(
    starSchemaDataSourceId: string,
    question: string,
    accountName: string,
    numValuesToRetrieve: number = 5,
    useDmGroup: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/star_schema_data_source/{star_schema_data_source_id}/retrieve/embedding',
      path: {
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        question: question,
        num_values_to_retrieve: numValuesToRetrieve,
        use_dm_group: useDmGroup,
        account_name: accountName,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Star Schema Data Sources
   * Read Star Schema Data Sources.
   * @param accountName
   * @param sortAscending
   * @param sortBy
   * @param limit
   * @param offset
   * @param search
   * @param sessionId
   * @returns StarSchemaDataSourcesResponse Successful Response
   * @throws ApiError
   */
  public readStarSchemaDataSources(
    accountName: string,
    sortAscending: boolean = false,
    sortBy?: StarSchemaDataSourceSort | null,
    limit?: number | null,
    offset?: number | null,
    search?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<StarSchemaDataSourcesResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        sort_ascending: sortAscending,
        sort_by: sortBy,
        limit: limit,
        offset: offset,
        search: search,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Star Schema Data Source
   * Create Star Schema Data Source.
   * @param accountName
   * @param requestBody
   * @param updateIndex
   * @param updateProfiles
   * @param updateDimensionGroups
   * @param updateExprTypes
   * @param updateDisplayTypes
   * @param skipValidationCheck
   * @param sessionId
   * @returns StarSchemaDataSource Successful Response
   * @throws ApiError
   */
  public createStarSchemaDataSource(
    accountName: string,
    requestBody: StarSchemaDataSourceAPICreate,
    updateIndex: boolean = true,
    updateProfiles: boolean = true,
    updateDimensionGroups: boolean = true,
    updateExprTypes: boolean = true,
    updateDisplayTypes: boolean = true,
    skipValidationCheck: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<StarSchemaDataSource> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        update_index: updateIndex,
        update_profiles: updateProfiles,
        update_dimension_groups: updateDimensionGroups,
        update_expr_types: updateExprTypes,
        update_display_types: updateDisplayTypes,
        skip_validation_check: skipValidationCheck,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Star Schema Data Source
   * Read Star Schema Data Source.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param publishStateFilter
   * @param sessionId
   * @returns StarSchemaDataSource Successful Response
   * @throws ApiError
   */
  public readStarSchemaDataSource(
    accountName: string,
    starSchemaDataSourceId: string,
    publishStateFilter?: PublishStateFilterOption,
    sessionId?: string | null,
  ): CancelablePromise<StarSchemaDataSource> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        publish_state_filter: publishStateFilter,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Star Schema Data Source
   * Delete Star Schema Data Source.
   *
   * This will delete all associated conversations that end up empty after cells are removed.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public deleteStarSchemaDataSource(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Star Schema Data Source Name And Description
   * Update Star Schema Data Source name and description.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param name
   * @param description
   * @param isDemo
   * @param sessionId
   * @returns StarSchemaDataSource Successful Response
   * @throws ApiError
   */
  public updateStarSchemaDataSourceNameAndDescription(
    accountName: string,
    starSchemaDataSourceId: string,
    name?: string,
    description?: string,
    isDemo?: boolean,
    sessionId?: string | null,
  ): CancelablePromise<StarSchemaDataSource> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/name_and_description',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        name: name,
        description: description,
        is_demo: isDemo,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Suggested Prompts
   * Get a dataset's suggested prompts from the SSDS examples.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param numberSuggestions
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public getSuggestedPrompts(
    accountName: string,
    starSchemaDataSourceId: string,
    numberSuggestions: number = 5,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/suggestions',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        number_suggestions: numberSuggestions,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Star Schema Data Source Common Values
   * Get the common values for an SSDS's columns.
   *
   * Returns:
   * A dictionary mapping the column name to ColumnValue's. Only
   * the value attribute on each ColumnValue is meaningful. The
   * count in each ColumnValue is hard-coded to -1.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns ColumnValue Successful Response
   * @throws ApiError
   */
  public getStarSchemaDataSourceCommonValues(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: string | null,
  ): CancelablePromise<Record<string, Array<ColumnValue>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/common_values',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Star Schema Data Source Sql
   * Get the SQL SELECT statement that is used to return the SSDS data.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public readStarSchemaDataSourceSql(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: string | null,
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/sql',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Star Schema Data Source Data
   * Get the joined data for everything in an SSDS.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param limit
   * @param skip
   * @param sessionId
   * @param requestBody
   * @returns NodeDataPage Successful Response
   * @throws ApiError
   */
  public readStarSchemaDataSourceData(
    accountName: string,
    starSchemaDataSourceId: string,
    limit: number = 100,
    skip?: number,
    sessionId?: string | null,
    requestBody?: Body_read_star_schema_data_source_data_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__data_post,
  ): CancelablePromise<NodeDataPage> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/data',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
        skip: skip,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Profile Data Source
   * Get the data for a specific data source within an SSDS.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param dataSourceId
   * @param sessionId
   * @returns DataSourceProfileInfo Successful Response
   * @throws ApiError
   */
  public profileDataSource(
    accountName: string,
    starSchemaDataSourceId: string,
    dataSourceId: string,
    sessionId?: string | null,
  ): CancelablePromise<DataSourceProfileInfo> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/data_sources/{data_source_id}/profile',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
        data_source_id: dataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Data Source Data
   * Get the data for a specific data source within an SSDS.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param dataSourceId
   * @param limit
   * @param skip
   * @param sessionId
   * @param requestBody
   * @returns NodeDataPage Successful Response
   * @throws ApiError
   */
  public readDataSourceData(
    accountName: string,
    starSchemaDataSourceId: string,
    dataSourceId: string,
    limit: number = 100,
    skip?: number,
    sessionId?: string | null,
    requestBody?: Body_read_data_source_data_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__data_sources__data_source_id__data_post,
  ): CancelablePromise<NodeDataPage> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/data_sources/{data_source_id}/data',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
        data_source_id: dataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
        skip: skip,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Star Schema Data Source Conversations
   * Read Star Schema Data Source Conversations.
   *
   * If show_moonlight is True, show only moonlight conversations
   * If show_moonlight is False, show only non-moonlight conversations
   * If show_moonlight is None, show everything.
   *
   * If show_training is True, show only is_training conversations
   * If show_training is False, show only non-is_training conversations
   * If show_training is None, show everything.
   *
   * If show_eval is True, show only eval conversations
   * If show_eval is False, show only non-eval conversations
   * If show_eval is None, show everything.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param limit
   * @param skip
   * @param showHidden
   * @param showEval
   * @param showMoonlight
   * @param showTraining
   * @param searchQuery
   * @param creatorIds
   * @param orderBy
   * @param descending
   * @param sessionId
   * @returns ConversationBasic Successful Response
   * @throws ApiError
   */
  public getStarSchemaDataSourceConversations(
    accountName: string,
    starSchemaDataSourceId: string,
    limit: number = 100,
    skip?: number,
    showHidden: boolean = false,
    showEval?: boolean | null,
    showMoonlight?: boolean | null,
    showTraining?: boolean | null,
    searchQuery?: string | null,
    creatorIds?: Array<string> | null,
    orderBy?: ConversationsSort,
    descending: boolean = true,
    sessionId?: string | null,
  ): CancelablePromise<Array<ConversationBasic>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/conversations',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
        skip: skip,
        show_hidden: showHidden,
        show_eval: showEval,
        show_moonlight: showMoonlight,
        show_training: showTraining,
        search_query: searchQuery,
        creator_ids: creatorIds,
        order_by: orderBy,
        descending: descending,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Star Schema Data Source Conversations Count
   * Read Star Schema Data Source Conversations.
   *
   * If show_moonlight is True, show only moonlight conversations
   * If show_moonlight is False, show only non-moonlight conversations
   * If show_moonlight is None, show everything.
   *
   * If show_training is True, show only is_training conversations
   * If show_training is False, show only non-is_training conversations
   * If show_training is None, show everything.
   *
   * If show_eval is True, show only eval conversations
   * If show_eval is False, show only non-eval conversations
   * If show_eval is None, show everything.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param showHidden
   * @param showEval
   * @param showMoonlight
   * @param showTraining
   * @param searchQuery
   * @param creatorIds
   * @param orderBy
   * @param descending
   * @param sessionId
   * @returns number Successful Response
   * @throws ApiError
   */
  public getStarSchemaDataSourceConversationsCount(
    accountName: string,
    starSchemaDataSourceId: string,
    showHidden: boolean = false,
    showEval?: boolean | null,
    showMoonlight?: boolean | null,
    showTraining?: boolean | null,
    searchQuery?: string | null,
    creatorIds?: Array<string> | null,
    orderBy?: ConversationsSort,
    descending: boolean = true,
    sessionId?: string | null,
  ): CancelablePromise<number> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/conversations_count',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        show_hidden: showHidden,
        show_eval: showEval,
        show_moonlight: showMoonlight,
        show_training: showTraining,
        search_query: searchQuery,
        creator_ids: creatorIds,
        order_by: orderBy,
        descending: descending,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Recompute Expr Types
   * Recompute the expr_type for all dimensions and metrics.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public recomputeExprTypes(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/compute-expr-types',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Account Ssds Semantic Queries
   * Get semantic queries for the given account and ssds.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns SemanticQuery Successful Response
   * @throws ApiError
   */
  public getAccountSsdsSemanticQueries(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<SemanticQuery>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/semantic_queries',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Upload Dimension Groups
   * Upload dimension groups from dict mapping dm names to group ids.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public uploadDimensionGroups(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: Record<string, string>,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/upload_dimension_groups',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Remove Dm Groups
   * Create dimension groups for Star Schema Data Source.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public removeDmGroups(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/remove_dimension_groups',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Dms Via Remapping
   * Upload dms to an existing ssds.
   *
   * Attempt to remap sql expressions to be over the base tables.
   *
   * Args:
   * slim_ssds: Whether to match to slimmed datasources when merging
   * the SSDS.
   * @param accountName
   * @param ssdsId
   * @param formData
   * @param defaultDatabase
   * @param defaultSchema
   * @param updateExistingDimensionsMeasures
   * @param updateExistingState
   * @param slimSsds
   * @param sessionId
   * @returns StarSchemaDataSource Successful Response
   * @throws ApiError
   */
  public createDmsViaRemapping(
    accountName: string,
    ssdsId: string,
    formData: Body_create_dms_via_remapping_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__create_dms_via_remapping_post,
    defaultDatabase?: string,
    defaultSchema?: string,
    updateExistingDimensionsMeasures: boolean = true,
    updateExistingState: boolean = false,
    slimSsds: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<StarSchemaDataSource> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/create_dms_via_remapping',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        ssds_id: ssdsId,
        default_database: defaultDatabase,
        default_schema: defaultSchema,
        update_existing_dimensions_measures: updateExistingDimensionsMeasures,
        update_existing_state: updateExistingState,
        slim_ssds: slimSsds,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Enrich Ssds
   * Upload dms to an existing ssds.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @param formData
   * @returns Task Successful Response
   * @throws ApiError
   */
  public enrichSsds(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: string | null,
    formData?: Body_enrich_ssds_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__enrich_ssds_post,
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/enrich_ssds',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      formData: formData,
      mediaType: 'application/x-www-form-urlencoded',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Upload Examples
   * Upload examples to an existing ssds.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns StarSchemaDataSource Successful Response
   * @throws ApiError
   */
  public uploadExamples(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: Array<BBExample>,
    sessionId?: string | null,
  ): CancelablePromise<StarSchemaDataSource> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/upload_examples',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Dms Denormalized Expressions
   * Helper endpoint to compute denormalized dm expressions in terms of base tables.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public getDmsDenormalizedExpressions(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: string | null,
  ): CancelablePromise<Record<string, string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/denormalized_dm_expressions',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Validate Star Schema Data Source
   * Validate Star Schema Data Source.
   *
   * Check all dimensions, metrics, and filters and mark invalid ones as Deprecated.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns StarSchemaDataSource Successful Response
   * @throws ApiError
   */
  public validateStarSchemaDataSource(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: string | null,
  ): CancelablePromise<StarSchemaDataSource> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/validate',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Star Schema Data Source
   * Update Star Schema Data Source.
   *
   * This endpoint allows users to update the star schema data schema with a new definition.
   * It requires the input of StarSchemaDataSourceColdStart, which is the same as a cold start.
   * The endpoint will scan the tables and views and add new data sources (StarSchemaDataSourceTableOrViewInfo without data_source_id),
   * delete data sources that exist in the existing SSDS but not in the tables_and_views,
   * and update the existing data sources (StarSchemaDataSourceTableOrViewInfo with data_source_id)
   * if either the table or SQL changes.
   *
   * Moreover, the endpoint introduces a new argument called `force_update` that allows users to
   * update existing data sources to reflect the underlying database schema changes, even if the
   * table or SQL remains the same. By default, this argument is set to false, and it will only
   * update the dimensions and metrics related to modified data sources.
   *
   * Example:
   *
   * Given the original SSDS definition:
   * ```
   * tables_and_views = [
   * StarSchemaDataSourceTableOrViewInfo(
   * name="MAKANA_WAITTIME_1",
   * db="CI_FOR_PYTEST",
   * db_schema="PYTEST",
   * table="MAKANA_WAITTIME_1",
   * ),
   * StarSchemaDataSourceTableOrViewInfo(
   * name="MAKANA_WAITTIME_2_100",
   * sql="SELECT * FROM CI_FOR_PYTEST.PYTEST.MAKANA_WAITTIME_2 LIMIT 100",
   * ),
   * ]
   * body = {
   * "tables_and_views": cold_start_tables,
   * "ssds_name": "MAKANA_WAITTIMES (pytest)",
   * }
   * r = client.post(
   * f"{settings.API_V1_STR}/accounts/{account.name}/star_schema_data_source/cold_start_from_tables",
   * cookies=user_cookie,
   * params={"connection_id": connection_db.id},  # type: ignore
   * json=jsonable_encoder(body),
   * )
   * ```
   *
   * We need to perform the following updates to the SSDS:
   * 1. Update the first data source with sql `SELECT *, 1 AS CNT FROM CI_FOR_PYTEST.PYTEST.MAKANA_WAITTIME_1 LIMIT 100;`
   * 2. Delete the second data source
   * 3. Create a new data source with table `CI_FOR_PYTEST.PYTEST.MAKANA_WAITTIME_3`
   *
   * ```
   * updated_tables = [
   * # Update the first data source since the data_source_id is filled in
   * StarSchemaDataSourceTableOrViewInfo(
   * data_source_id=ssds["data_sources"][0]["id"],
   * name="MAKANA_WAITTIME_1",
   * sql="SELECT *, 1 AS CNT FROM CI_FOR_PYTEST.PYTEST.MAKANA_WAITTIME_1 LIMIT 100;",
   * ),
   * # Delete the second data source since the original second data source is not in the new list
   * # Create a new data source with table `CI_FOR_PYTEST.PYTEST.MAKANA_WAITTIME_3`
   * StarSchemaDataSourceTableOrViewInfo(
   * name="MAKANA_WAITTIME_3",
   * db="CI_FOR_PYTEST",
   * db_schema="PYTEST",
   * table="MAKANA_WAITTIME_3",
   * ),
   * ]
   * body = {
   * "tables_and_views": updated_tables,
   * "ssds_name": "updated ssds",
   * "generate_templated_examples": False,
   * "update_identifiers": False,
   * "ssds_enrich_params": {"enable": True},
   * }
   * r = client.post(
   * f"{settings.API_V1_STR}/accounts/{account.name}/star_schema_data_source/{ssds['id']}/update",
   * cookies=user_cookie,
   * params={"force_update": False},
   * json=jsonable_encoder(body),
   * )
   * ```
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param forceUpdate
   * @param sessionId
   * @returns StarSchemaDataSource Successful Response
   * @throws ApiError
   */
  public updateStarSchemaDataSource(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: StarSchemaDataSourceColdStart | null,
    forceUpdate: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<StarSchemaDataSource> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/update',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        force_update: forceUpdate,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Add Data Sources
   * Add new data sources to the Star Schema Data Source.
   *
   * This endpoint allows users to add data sources to the existing star schema data schema.
   * It requires the input of a list of StarSchemaDataSourceTableOrViewInfo without data source id.
   * The endpoint will scan the StarSchemaDataSourceTableOrViewInfo and perform cold start for the
   * new data sources and do enrich_ssds with related dimensions and metrics.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns StarSchemaDataSource Successful Response
   * @throws ApiError
   */
  public addDataSources(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: Array<StarSchemaDataSourceTableOrViewInfo>,
    sessionId?: string | null,
  ): CancelablePromise<StarSchemaDataSource> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/add_data_sources',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Data Source
   * Delete a data source and set the states of all related dimensions and metrics invalid.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param dataSourceId
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public deleteDataSource(
    accountName: string,
    starSchemaDataSourceId: string,
    dataSourceId: string,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/data_sources/{data_source_id}',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
        data_source_id: dataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Data Source
   * Update existing data source in the Star Schema Data Source.
   *
   * This endpoint allows users to update existing data source in the existing star schema data schema.
   * It requires the input of StarSchemaDataSourceTableOrViewInfo without data source id.
   * The endpoint will scan the StarSchemaDataSourceTableOrViewInfo and perform cold start for the
   * updated data source and do enrich_ssds with related dimensions and metrics.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param dataSourceId
   * @param requestBody
   * @param sessionId
   * @returns StarSchemaDataSource Successful Response
   * @throws ApiError
   */
  public updateDataSource(
    accountName: string,
    starSchemaDataSourceId: string,
    dataSourceId: string,
    requestBody: StarSchemaDataSourceTableOrViewInfo,
    sessionId?: string | null,
  ): CancelablePromise<StarSchemaDataSource> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/data_sources/{data_source_id}',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
        data_source_id: dataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Trim Text Columns Sql For Data Source
   * Generates a SQL that trims all the text columns from an existing data source.
   *
   * All the text columns will be applied with a trim.
   *
   * Returns:
   * the modified sql
   * @param accountName
   * @param starSchemaDataSourceId
   * @param dataSourceId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public trimTextColumnsSqlForDataSource(
    accountName: string,
    starSchemaDataSourceId: string,
    dataSourceId: string,
    sessionId?: string | null,
  ): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/data_sources/{data_source_id}/trim_text_columns_sql',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
        data_source_id: dataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Ssds Table And View Info
   * Get table and view info for the given ssds.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns StarSchemaDataSourceTableOrViewInfo Successful Response
   * @throws ApiError
   */
  public getSsdsTableAndViewInfo(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<StarSchemaDataSourceTableOrViewInfo>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/table_and_view_info',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Batch Update Dm Sensitive
   * Batch update DMs for sensitive given the assignment.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public batchUpdateDmSensitive(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: DMSensitiveAssignment,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/batch_update_dm_sensitive',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Cold Start From Tables
   * Cold start from table.
   * @param accountName
   * @param connectionId
   * @param requestBody
   * @param ssdsId
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public coldStartFromTables(
    accountName: string,
    connectionId: string,
    requestBody: StarSchemaDataSourceColdStart,
    ssdsId?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/cold_start_from_tables',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        ssds_id: ssdsId,
        connection_id: connectionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Bulk Create Cells
   * Upload cells to an account.
   *
   * Attempts to create with the same user, but will default to the admin
   * if the user doesn't belong to the account.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns BumblebeeCellBasic Successful Response
   * @throws ApiError
   */
  public bulkCreateCells(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: Array<BumblebeeCellBasic>,
    sessionId?: string | null,
  ): CancelablePromise<Array<BumblebeeCellBasic>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/upload_cells',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Bulk Create Feedback
   * Upload feedback to an account.
   *
   * Attempts to create with the same user, but will default to the admin
   * if the user doesn't belong to the account.
   *
   * NOTE: If a user is overwritten by an admin, the admin may be re-used to give feedback,
   * which means only the last feedback issued by the admin will apply.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param allowConflictingFeedback
   * @param requestBody
   * @param sessionId
   * @returns Feedback Successful Response
   * @throws ApiError
   */
  public bulkCreateFeedback(
    accountName: string,
    starSchemaDataSourceId: string,
    allowConflictingFeedback: boolean,
    requestBody: Array<Feedback>,
    sessionId?: string | null,
  ): CancelablePromise<Array<Feedback>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/upload_feedback',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        allow_conflicting_feedback: allowConflictingFeedback,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Named Filters
   * Delete all Named Filters matching SSDS id.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public deleteNamedFilters1(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/named_filters',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Bulk Create Named Filters
   * Add Named Filters with the appropriate SSDS id.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns NamedFilterBase Successful Response
   * @throws ApiError
   */
  public bulkCreateNamedFilters(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: Array<NamedFilterCreate>,
    sessionId?: string | null,
  ): CancelablePromise<Array<NamedFilterBase>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/upload_named_filters',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Bulk Create Semantic Queries
   * Upload Semantic Queries associated with ssds_id.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns SemanticQuery Successful Response
   * @throws ApiError
   */
  public bulkCreateSemanticQueries(
    accountName: string,
    requestBody: Array<SemanticQueryCreate>,
    sessionId?: string | null,
  ): CancelablePromise<Array<SemanticQuery>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/upload_semantic_queries',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Import From Twb
   * Import DMs from a twb/twbx file.
   *
   * This parses the twb and creates a star schema data source.
   * @param accountName
   * @param ssdsId
   * @param connectionId
   * @param formData
   * @param generateTemplatedDemonstrations
   * @param updateIdentifiers
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public importFromTwb(
    accountName: string,
    ssdsId: string,
    connectionId: string,
    formData: Body_import_from_twb_api_v1_accounts__account_name__star_schema_data_source_import_from_twb_post,
    generateTemplatedDemonstrations: boolean = true,
    updateIdentifiers: boolean = true,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/import_from_twb',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        ssds_id: ssdsId,
        generate_templated_demonstrations: generateTemplatedDemonstrations,
        update_identifiers: updateIdentifiers,
        connection_id: connectionId,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Upload Tableau Workbook
   * Upload a twb/twbx file.
   *
   * extract the connection from twb file.
   * if ssds_id is provided, then we check the connection in twb matches the connection in ssds.
   * if not, we discard the twb file.
   * if yes, we merge the dms extracted from twb to the existing ssds.
   *
   * if ssds_id is not provided, we return the connection info from twb file.
   * @param accountName
   * @param formData
   * @param ssdsId
   * @param updateIdentifiers
   * @param sessionId
   * @returns UploadTableauResponse Successful Response
   * @throws ApiError
   */
  public uploadTableauWorkbook(
    accountName: string,
    formData: Body_upload_tableau_workbook_api_v1_accounts__account_name__star_schema_data_source_upload_twb_post,
    ssdsId?: string | null,
    updateIdentifiers: boolean = true,
    sessionId?: string | null,
  ): CancelablePromise<UploadTableauResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/upload_twb',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        ssds_id: ssdsId,
        update_identifiers: updateIdentifiers,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Twb
   * Delete a twb file.
   * @param accountName
   * @param twbId
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public deleteTwb(
    accountName: string,
    twbId: string,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/upload_twb/{twb_id}',
      path: {
        account_name: accountName,
        twb_id: twbId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Ssds From Twb
   * Create a star schema data source from a twb file.
   * @param accountName
   * @param twbId
   * @param password
   * @param sessionId
   * @param formData
   * @returns StarSchemaDataSource Successful Response
   * @throws ApiError
   */
  public createSsdsFromTwb(
    accountName: string,
    twbId: string,
    password: string,
    sessionId?: string | null,
    formData?: Body_create_ssds_from_twb_api_v1_accounts__account_name__star_schema_data_source_upload_twb__twb_id__create_post,
  ): CancelablePromise<StarSchemaDataSource> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/upload_twb/{twb_id}/create',
      path: {
        account_name: accountName,
        twb_id: twbId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        password: password,
      },
      formData: formData,
      mediaType: 'application/x-www-form-urlencoded',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Run Eval On Reference Conversations
   * Run eval on reference conversations.
   *
   * Args:
   * max_eval_conversations: The upper limit of conversations to run.
   * eval_filter: Select which cells to include as ground-truth cells.
   * moonlight_run_params: Run parameters for cells.
   * run_in_reverse_order: Preserve upstream context by running cells in reverse order to independently evaluate each cell in a conversation.
   * We clone a conversation from reference and then rerun from the last (when this is set True) to reuse the reference context instead of the predicted context.
   * delete_conversations: Remove newly-created conversations after evaluation.
   * run_agentic: Whether to run the new agentic flow.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param maxEvalConversations
   * @param runInReverseOrder
   * @param deleteNewConversations
   * @param runAgentic
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public runEvalOnReferenceConversations(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: Body_run_eval_on_reference_conversations_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__eval_reference_conversations_post,
    maxEvalConversations?: number | null,
    runInReverseOrder: boolean = true,
    deleteNewConversations: boolean = true,
    runAgentic: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/eval_reference_conversations',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        max_eval_conversations: maxEvalConversations,
        run_in_reverse_order: runInReverseOrder,
        delete_new_conversations: deleteNewConversations,
        run_agentic: runAgentic,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Run Regression Test
   * Run regression test on verified cells.
   *
   * Args:
   * max_eval_cells: The upper limit of conversations to run.
   * moonlight_run_params: Run parameters for cells.
   * delete_conversations: Remove newly-created conversations after evaluation.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param maxEvalCells
   * @param deleteNewConversations
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public runRegressionTest(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: Body_run_regression_test_api_v1_accounts__account_name__star_schema_data_source__star_schema_data_source_id__regression_test_post,
    maxEvalCells?: number | null,
    deleteNewConversations: boolean = true,
    sessionId?: string | null,
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/regression_test',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        max_eval_cells: maxEvalCells,
        delete_new_conversations: deleteNewConversations,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Eval On Reference Conversations Results
   * Get eval results for an SSDS.
   *
   * This returns results metadata for the SSDS. Full eval results with conversations can be retrieved by ID.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param taskId
   * @param sessionId
   * @returns EvalResultBase Successful Response
   * @throws ApiError
   */
  public getEvalOnReferenceConversationsResults(
    accountName: string,
    starSchemaDataSourceId: string,
    taskId?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<Array<EvalResultBase>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/eval_reference_conversations_results',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        task_id: taskId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Eval On Reference Conversation Result By Id
   * Get eval result for an SSDS by ID.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param evalResultId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public getEvalOnReferenceConversationResultById(
    accountName: string,
    starSchemaDataSourceId: string,
    evalResultId: string,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/eval_reference_conversations_results/{eval_result_id}',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
        eval_result_id: evalResultId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Eval On Reference Conversations Result
   * Delete an eval result
   * @param accountName
   * @param starSchemaDataSourceId
   * @param evalResultId
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public deleteEvalOnReferenceConversationsResult(
    accountName: string,
    starSchemaDataSourceId: string,
    evalResultId: string,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/eval_reference_conversations_results/{eval_result_id}',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
        eval_result_id: evalResultId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Dm Usage
   * Get histogram of DM usage in semantic queries.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public getDmUsage(
    accountName: string,
    starSchemaDataSourceId: string,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/dm_usage',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Remap Ssds Dms
   * Remap all semantic queries to use new dimension/metric names.
   *
   * This does not alter any dimension/metric definitions, but only usage in semantic queries.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param requestBody
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public remapSsdsDms(
    accountName: string,
    starSchemaDataSourceId: string,
    requestBody: Record<string, string>,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/remap_dms',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Coalesce Dms
   * Coalesce all semantic queries in selected cells.
   *
   * We accept bumblebee cells as input, because we don't relate semantic queries directly to SSDSs.
   * If none are specified, this will coalesce all existing bumblebee cells.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param coalescingStrategy
   * @param commit
   * @param sessionId
   * @param requestBody
   * @returns any[] Successful Response
   * @throws ApiError
   */
  public coalesceDms(
    accountName: string,
    starSchemaDataSourceId: string,
    coalescingStrategy: SemanticQueryCoalescingStrategy,
    commit: boolean = true,
    sessionId?: string | null,
    requestBody?: Array<string> | null,
  ): CancelablePromise<any[]> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/star_schema_data_source/{star_schema_data_source_id}/coalesce_dms',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        coalescing_strategy: coalescingStrategy,
        commit: commit,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Dataset
   * Read Dataset.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param fullDataset
   * @param dmFilter
   * @param conversationCellFilter
   * @param sessionId
   * @returns BumblebeeDataset Successful Response
   * @throws ApiError
   */
  public readDataset(
    accountName: string,
    starSchemaDataSourceId: string,
    fullDataset: boolean = true,
    dmFilter?: DMFilter,
    conversationCellFilter?: ConversationCellFilter,
    sessionId?: string | null,
  ): CancelablePromise<BumblebeeDataset> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/datasets/{star_schema_data_source_id}',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        full_dataset: fullDataset,
        dm_filter: dmFilter,
        conversation_cell_filter: conversationCellFilter,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Add Demo Dataset
   * Add a demo dataset to the account
   * @param accountName
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public addDemoDataset(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/datasets/add_demo_dataset',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Upload Dataset
   * Add a dataset from a JSON file.
   *
   * The user must both be an account admin and eval maintainer to upload the dataset.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public uploadDataset(
    accountName: string,
    requestBody: Body_upload_dataset_api_v1_accounts__account_name__datasets_upload_post,
    sessionId?: string | null,
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/datasets/upload',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Copy Dataset
   * Copy dataset.
   *
   * This endpoint lets users create a copy of a dataset in another account. You can specify the
   * destination account name (dest_account_name). The users need to be the admin for both accounts.
   * You can copy to a different account you manage, or even the same account. When copying, you have
   * the option to update the details, including the SSDS name, connection, and data source
   * definition (data source id in the data source definition must be retained).
   *
   * Example:
   *
   * Given the original SSDS definition:
   * ```
   * tables_and_views = [
   * StarSchemaDataSourceTableOrViewInfo(
   * data_source_id="592a2851-f849-4dab-a4ca-614e8709dc8d",
   * name="MAKANA_WAITTIME_1",
   * db="CI_FOR_PYTEST",
   * db_schema="PYTEST",
   * table="MAKANA_WAITTIME_1",
   * )
   * ]
   * new_account_name = "new_account"
   * new_connection_id = uuid.uuid4()
   * new_ssds_name = "MAKANA_WAITTIMES (pytest) - copy from another account"
   * r = client.post(
   * f"{settings.API_V1_STR}/accounts/{account.name}/datasets/{ssds_id}/copy",
   * headers=user_header,
   * params={
   * "dest_account_name": new_account_name,
   * "dest_connection_id": new_connection_id,
   * "dest_ssds_name": new_ssds_name,
   * },
   * json=jsonable_encoder(tables_and_views),
   * )
   * @param accountName
   * @param starSchemaDataSourceId
   * @param destAccountName
   * @param destConnectionId
   * @param destSsdsName
   * @param conversationCellFilter
   * @param overwriteUserQuestionForSensitiveCell
   * @param sessionId
   * @param requestBody
   * @returns Task Successful Response
   * @throws ApiError
   */
  public copyDataset(
    accountName: string,
    starSchemaDataSourceId: string,
    destAccountName?: string | null,
    destConnectionId?: string | null,
    destSsdsName?: string | null,
    conversationCellFilter?: ConversationCellFilter,
    overwriteUserQuestionForSensitiveCell: boolean = false,
    sessionId?: string | null,
    requestBody?: Array<StarSchemaDataSourceTableOrViewInfo> | null,
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/datasets/{star_schema_data_source_id}/copy',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        dest_account_name: destAccountName,
        dest_connection_id: destConnectionId,
        dest_ssds_name: destSsdsName,
        conversation_cell_filter: conversationCellFilter,
        overwrite_user_question_for_sensitive_cell:
          overwriteUserQuestionForSensitiveCell,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Dataset
   * Update dataset.
   *
   * This endpoint lets users push *new* knowledge from baseline dataset to derived dataset, including
   * SSDS (dimensions, metrics, and named filters), conversations, cells, semantic queries, and feedback.
   *
   * For SSDS, we do incremental update including adding new dimensions, metrics, and named filters (DMFs),
   * align unaligned DMFs that are identical. We will let users to review any conflict in aligned DMFs.
   * For conversations, cells, semantic queries, and feedback, we only copy the new created conversations
   * and associated cells, semantic queries, and feedback. We will let users to review existing conversations
   * if there is any conflict.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param destSsdsId
   * @param conversationCellFilter
   * @param overwriteUserQuestionForSensitiveCell
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public updateDataset(
    accountName: string,
    starSchemaDataSourceId: string,
    destSsdsId: string,
    conversationCellFilter?: ConversationCellFilter,
    overwriteUserQuestionForSensitiveCell: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/datasets/{star_schema_data_source_id}/update',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        dest_ssds_id: destSsdsId,
        conversation_cell_filter: conversationCellFilter,
        overwrite_user_question_for_sensitive_cell:
          overwriteUserQuestionForSensitiveCell,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Log
   * Receive logs from the browser and display them on the server.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public log(
    accountName: string,
    requestBody: BrowserLog,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/logging/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
