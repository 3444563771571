// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/MetricView.ts"
);
import.meta.hot.lastModified = "1728941031126.639";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Dialect } from './Dialect'
import type { DisplayType } from './DisplayType'
import type { FilterCategory } from './FilterCategory'
import type { OriginType } from './OriginType'
import type { PublishState } from './PublishState'
import type { WindowAggregationSpecification } from './WindowAggregationSpecification'

/**
 * Simplified Metric View.
 *
 * Metric view unifies Measures and Metrics.
 *
 * Used in SemanticLayerView.
 */
export type MetricView = {
  id?: string | null
  sync_id?: string | null
  group_id?: string | null
  name: string
  description?: string | null
  dialect: Dialect
  expr?: string | null
  zen_sql?: string | null
  is_derived?: boolean | null
  expr_type?: FilterCategory
  state?: PublishState
  display_name?: string | null
  display_type?: DisplayType
  data_source_id?: string | null
  origin?: Array<OriginType>
  window_specification?: WindowAggregationSpecification | null
  popularity?: number
  is_sensitive?: boolean
  is_literal_sensitive?: boolean
  is_display_sensitive?: boolean
  field_type?: MetricView.field_type
}

export namespace MetricView {
  export enum field_type {
    METRIC = 'metric',
  }
}
