// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/KnowledgeOriginFilter.ts"
);
import.meta.hot.lastModified = "1728941028034.6565";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Knowledge origin filter.
 */
export enum KnowledgeOriginFilter {
  ALL = 'all',
  AI = 'ai',
  ADMIN = 'admin',
  LOG = 'log',
  SCHEMA = 'schema',
  DASHBOARD = 'dashboard',
  DICTIONARY = 'dictionary',
}
