// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/V3Service.ts"
);
import.meta.hot.lastModified = "1728941033646.6245";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgentAPI } from '../models/AgentAPI'
import type { ArimaForecast } from '../models/ArimaForecast'
import type { ArimaPredictiveAPI } from '../models/ArimaPredictiveAPI'
import type { Body_read_query_message_table_data_api_v3_orgs__account_name__messages__message_id__data_post } from '../models/Body_read_query_message_table_data_api_v3_orgs__account_name__messages__message_id__data_post'
import type { ChatAPICreate } from '../models/ChatAPICreate'
import type { ChatAPIResponse } from '../models/ChatAPIResponse'
import type { ChatAPIResponsePage } from '../models/ChatAPIResponsePage'
import type { ChatAPIUpdate } from '../models/ChatAPIUpdate'
import type { ChatMessagePromptlogInDB } from '../models/ChatMessagePromptlogInDB'
import type { ChatsFilter } from '../models/ChatsFilter'
import type { ChatsSort } from '../models/ChatsSort'
import type { DataPage } from '../models/DataPage'
import type { MessageAPIResponse } from '../models/MessageAPIResponse'
import type { MessageAPIResponsePage } from '../models/MessageAPIResponsePage'
import type { MessageAPISendV3 } from '../models/MessageAPISendV3'
import type { StarSchemaDataSourceAPI } from '../models/StarSchemaDataSourceAPI'
import type { TableFilter } from '../models/TableFilter'
import type { Task } from '../models/Task'
import type { UnionStatistic } from '../models/UnionStatistic'
import type { WidgetAPICreateResponse } from '../models/WidgetAPICreateResponse'
import type { WidgetAPIGetResponse } from '../models/WidgetAPIGetResponse'
import type { WidgetAPIUpdateRequest } from '../models/WidgetAPIUpdateRequest'
import type { WidgetAPIUpdateResponse } from '../models/WidgetAPIUpdateResponse'

import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'

export class V3Service {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create Chat
   * Create a chat. This will not send a message to the chat.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public createChat(
    accountName: string,
    requestBody: ChatAPICreate,
    sessionId?: string | null,
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Chats
   * Read all chats.
   * @param accountName
   * @param sortAscending
   * @param sortBy
   * @param filterBy
   * @param limit
   * @param offset
   * @param showEval
   * @param sessionId
   * @returns ChatAPIResponsePage Successful Response
   * @throws ApiError
   */
  public getChats(
    accountName: string,
    sortAscending: boolean = true,
    sortBy?: ChatsSort,
    filterBy?: ChatsFilter | null,
    limit?: number | null,
    offset?: number | null,
    showEval: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<ChatAPIResponsePage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        sort_ascending: sortAscending,
        sort_by: sortBy,
        filter_by: filterBy,
        limit: limit,
        offset: offset,
        show_eval: showEval,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Start Chat
   * Start a new chat.
   *
   * This endpoint sends will create a new chat and send a message.
   *
   * Returns: EventSourceResponse
   *
   * Raises:
   * NSCannotSendMessageError if another request is concurrently sending a message
   * @param accountName
   * @param chatId
   * @param requestBody
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public startChat(
    accountName: string,
    chatId: string,
    requestBody: MessageAPISendV3,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/start',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        chat_id: chatId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Chat
   * Delete a chat.
   * @param accountName
   * @param chatId
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public deleteChat(
    accountName: string,
    chatId: string,
    sessionId?: string | null,
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}',
      path: {
        account_name: accountName,
        chat_id: chatId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Chat
   * Update a chat.
   * @param accountName
   * @param chatId
   * @param requestBody
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public updateChat(
    accountName: string,
    chatId: string,
    requestBody: ChatAPIUpdate,
    sessionId?: string | null,
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}',
      path: {
        account_name: accountName,
        chat_id: chatId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Chat
   * Read a single chat. Note that this does NOT include the messages of the chat.
   * @param accountName
   * @param chatId
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public getChat(
    accountName: string,
    chatId: string,
    sessionId?: string | null,
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}',
      path: {
        account_name: accountName,
        chat_id: chatId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Chat Messages
   * Get messages in a chat.
   *
   * This paginated endpoint returns a chat with up to the requested number of
   * messages. Note that the messages are ordered from newest to oldest.
   *
   * Params:
   * limit: The maximum number of messages to include in the response.
   * offset: The messages to skip. The response will not include indexes greater
   * than the offset. Equivalent of 'skip' in database queries.
   *
   *
   * Returns:
   * Messages from the chat in reverse-temporal order (newest first). Agent end and
   * user end messages are not included.
   * @param accountName
   * @param chatId
   * @param limit
   * @param offset
   * @param sessionId
   * @returns MessageAPIResponsePage Successful Response
   * @throws ApiError
   */
  public getChatMessages(
    accountName: string,
    chatId: string,
    limit: number = 20,
    offset?: number,
    sessionId?: string | null,
  ): CancelablePromise<MessageAPIResponsePage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}/messages',
      path: {
        account_name: accountName,
        chat_id: chatId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
        offset: offset,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Send Message
   * Send a message in an existing chat.
   *
   * This endpoint sends a message in a chat. The message will be sent to the
   * chat and the AI will respond if necessary.
   *
   * Raises:
   * NSCannotSendMessageError if another request is concurrently sending a message
   * @param accountName
   * @param chatId
   * @param requestBody
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public sendMessage(
    accountName: string,
    chatId: string,
    requestBody: MessageAPISendV3,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}/send',
      path: {
        account_name: accountName,
        chat_id: chatId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Send Interrupt
   * Send a interrupt signal in a chat.
   *
   * Note that this sends the signal regardless of whether the AI is currently
   * generating
   * @param accountName
   * @param chatId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public sendInterrupt(
    accountName: string,
    chatId: string,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}/interrupt',
      path: {
        account_name: accountName,
        chat_id: chatId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Clone Chat
   * Clone a chat with the new user as the creator.
   * @param accountName
   * @param chatId
   * @param isEvalChat
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public cloneChat(
    accountName: string,
    chatId: string,
    isEvalChat: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}/clone',
      path: {
        account_name: accountName,
        chat_id: chatId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        is_eval_chat: isEvalChat,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Conversation To Chat
   * Convert a conversation into a Chat.
   * @param accountName
   * @param conversationId
   * @param ssdsId
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public conversationToChat(
    accountName: string,
    conversationId: string,
    ssdsId: string,
    sessionId?: string | null,
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/{conversation_id}/convert',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        ssds_id: ssdsId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Chat Agents
   * Get list of available agents by name.
   * @param accountName
   * @param sessionId
   * @returns AgentAPI Successful Response
   * @throws ApiError
   */
  public getChatAgents(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<AgentAPI>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/agents',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Arima Schema
   * Get schema for ARIMA predictive model.
   *
   * Args:
   * records_data: A pandas dataframe serialized as records.
   * @param accountName
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public getArimaSchema(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/models/forecasts/arima',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Arima Forecast
   * Get a list of available forecasting models for an account.
   *
   * Args:
   * records_data: A pandas dataframe serialized as records.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns ArimaForecast Successful Response
   * @throws ApiError
   */
  public getArimaForecast(
    accountName: string,
    requestBody: ArimaPredictiveAPI,
    sessionId?: string | null,
  ): CancelablePromise<ArimaForecast> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/models/forecasts/arima',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Forecasting Model Apis
   * Get a list of available forecasting models for an account.
   * @param accountName
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public getForecastingModelApis(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/models/forecasts',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Run Eval On Chat
   * Run eval on all of the messages sent in the reference chat.
   * @param accountName
   * @param chatId
   * @param deleteNewChat
   * @param runGroupChat
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public runEvalOnChat(
    accountName: string,
    chatId: string,
    deleteNewChat: boolean = true,
    runGroupChat: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}/eval',
      path: {
        account_name: accountName,
        chat_id: chatId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        delete_new_chat: deleteNewChat,
        run_group_chat: runGroupChat,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Run Eval Chats
   * Run eval on reference chats.
   * @param accountName
   * @param starSchemaDataSourceId
   * @param maxEvalChats
   * @param deleteNewChat
   * @param runGroupChat
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public runEvalChats(
    accountName: string,
    starSchemaDataSourceId: string,
    maxEvalChats?: number | null,
    deleteNewChat: boolean = true,
    runGroupChat: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/{star_schema_data_source_id}/eval_chats',
      path: {
        account_name: accountName,
        star_schema_data_source_id: starSchemaDataSourceId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        max_eval_chats: maxEvalChats,
        delete_new_chat: deleteNewChat,
        run_group_chat: runGroupChat,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Message Promptlog
   * Get chat message promptlogs.
   * @param accountName
   * @param messageId
   * @param sessionId
   * @returns ChatMessagePromptlogInDB Successful Response
   * @throws ApiError
   */
  public getMessagePromptlog(
    accountName: string,
    messageId: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<ChatMessagePromptlogInDB>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/promptlogs',
      path: {
        account_name: accountName,
        message_id: messageId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Refresh Query Message
   * Refresh the SQL table in a query chat message.
   * @param accountName
   * @param messageId
   * @param sessionId
   * @returns MessageAPIResponse Successful Response
   * @throws ApiError
   */
  public refreshQueryMessage(
    accountName: string,
    messageId: string,
    sessionId?: string | null,
  ): CancelablePromise<MessageAPIResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/refresh',
      path: {
        account_name: accountName,
        message_id: messageId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Profile Query Message Table Data
   * Profile the query message table columns.
   *
   * Raises:
   * NSCannotReadNodeDataError: If the message does not have a table ID.
   * @param accountName
   * @param messageId
   * @param limit
   * @param sessionId
   * @param requestBody
   * @returns UnionStatistic Successful Response
   * @throws ApiError
   */
  public profileQueryMessageTableData(
    accountName: string,
    messageId: string,
    limit: number = 100000,
    sessionId?: string | null,
    requestBody?: Array<TableFilter> | null,
  ): CancelablePromise<Record<string, UnionStatistic>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/profile',
      path: {
        account_name: accountName,
        message_id: messageId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Query Message Table Data
   * Get Message Data.
   * @param accountName
   * @param messageId
   * @param limit
   * @param skip
   * @param sessionId
   * @param requestBody
   * @returns DataPage Successful Response
   * @throws ApiError
   */
  public readQueryMessageTableData(
    accountName: string,
    messageId: string,
    limit: number = 100,
    skip?: number,
    sessionId?: string | null,
    requestBody?: Body_read_query_message_table_data_api_v3_orgs__account_name__messages__message_id__data_post,
  ): CancelablePromise<DataPage> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/data',
      path: {
        account_name: accountName,
        message_id: messageId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
        skip: skip,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Query Message Table Data As Csv
   * Read Query Message table data as a CSV.
   * @param accountName
   * @param messageId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public readQueryMessageTableDataAsCsv(
    accountName: string,
    messageId: string,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/data/csv',
      path: {
        account_name: accountName,
        message_id: messageId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Create Widget
   * Create a widget.
   *
   * For now, we only allow one widget per account.
   * @param accountName
   * @param key
   * @param sessionId
   * @returns WidgetAPICreateResponse Successful Response
   * @throws ApiError
   */
  public createWidget(
    accountName: string,
    key?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<WidgetAPICreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/widgets/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        key: key,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Widget For Account
   * Get widget by account ID.
   * @param accountName
   * @param sessionId
   * @returns WidgetAPIGetResponse Successful Response
   * @throws ApiError
   */
  public getWidgetForAccount(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<WidgetAPIGetResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/widgets/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Widget
   * Get widget by ID.
   * @param accountName
   * @param widgetId
   * @param sessionId
   * @returns WidgetAPIGetResponse Successful Response
   * @throws ApiError
   */
  public getWidget(
    accountName: string,
    widgetId: string,
    sessionId?: string | null,
  ): CancelablePromise<WidgetAPIGetResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/widgets/{widget_id}',
      path: {
        account_name: accountName,
        widget_id: widgetId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Widget
   * Update widget by ID.
   *
   * Currently this endpoint supports updating the `options` and the `key` fields.
   * @param accountName
   * @param widgetId
   * @param requestBody
   * @param sessionId
   * @returns WidgetAPIUpdateResponse Successful Response
   * @throws ApiError
   */
  public updateWidget(
    accountName: string,
    widgetId: string,
    requestBody: WidgetAPIUpdateRequest,
    sessionId?: string | null,
  ): CancelablePromise<WidgetAPIUpdateResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v3/orgs/{account_name}/widgets/{widget_id}',
      path: {
        account_name: accountName,
        widget_id: widgetId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Widget
   * Delete widget by ID.
   * @param accountName
   * @param widgetId
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public deleteWidget(
    accountName: string,
    widgetId: string,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v3/orgs/{account_name}/widgets/{widget_id}',
      path: {
        account_name: accountName,
        widget_id: widgetId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Datasets For Account
   * Get datasets for an account.
   * @param accountName
   * @param limit
   * @param offset
   * @param sessionId
   * @returns StarSchemaDataSourceAPI Successful Response
   * @throws ApiError
   */
  public getDatasetsForAccount(
    accountName: string,
    limit?: number | null,
    offset?: number | null,
    sessionId?: string | null,
  ): CancelablePromise<Array<StarSchemaDataSourceAPI>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/datasets/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
        offset: offset,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Suggestions For Dataset
   * Get suggested prompts for a star schema data source.
   * @param accountName
   * @param datasetId
   * @param limit
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public getSuggestionsForDataset(
    accountName: string,
    datasetId: string,
    limit?: number | null,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/datasets/{dataset_id}/suggestions',
      path: {
        account_name: accountName,
        dataset_id: datasetId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
