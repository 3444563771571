// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/DimensionFilter.ts"
);
import.meta.hot.lastModified = "1728941030630.6418";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Combination } from './Combination'
import type { DimensionFilterCondition } from './DimensionFilterCondition'
import type { FilterOrigin } from './FilterOrigin'

/**
 * Filter operating over dimensions.
 */
export type DimensionFilter = {
  id?: string | null
  query_id?: string | null
  name?: string | null
  combination?: Combination
  description?: string | null
  filter_origin?: FilterOrigin
  is_enabled?: boolean
  field_type?: DimensionFilter.field_type
  is_result_filter?: boolean
  conditions: Array<DimensionFilterCondition>
}

export namespace DimensionFilter {
  export enum field_type {
    DIMENSION = 'dimension',
  }
}
