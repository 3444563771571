// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/MessagesService.ts"
);
import.meta.hot.lastModified = "1728941032650.6304";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Body_read_query_message_table_data_api_v3_orgs__account_name__messages__message_id__data_post } from '../models/Body_read_query_message_table_data_api_v3_orgs__account_name__messages__message_id__data_post'
import type { ChatMessagePromptlogInDB } from '../models/ChatMessagePromptlogInDB'
import type { DataPage } from '../models/DataPage'
import type { MessageAPIResponse } from '../models/MessageAPIResponse'
import type { TableFilter } from '../models/TableFilter'
import type { UnionStatistic } from '../models/UnionStatistic'

import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'

export class MessagesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Message Promptlog
   * Get chat message promptlogs.
   * @param accountName
   * @param messageId
   * @param sessionId
   * @returns ChatMessagePromptlogInDB Successful Response
   * @throws ApiError
   */
  public getMessagePromptlog(
    accountName: string,
    messageId: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<ChatMessagePromptlogInDB>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/promptlogs',
      path: {
        account_name: accountName,
        message_id: messageId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Refresh Query Message
   * Refresh the SQL table in a query chat message.
   * @param accountName
   * @param messageId
   * @param sessionId
   * @returns MessageAPIResponse Successful Response
   * @throws ApiError
   */
  public refreshQueryMessage(
    accountName: string,
    messageId: string,
    sessionId?: string | null,
  ): CancelablePromise<MessageAPIResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/refresh',
      path: {
        account_name: accountName,
        message_id: messageId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Profile Query Message Table Data
   * Profile the query message table columns.
   *
   * Raises:
   * NSCannotReadNodeDataError: If the message does not have a table ID.
   * @param accountName
   * @param messageId
   * @param limit
   * @param sessionId
   * @param requestBody
   * @returns UnionStatistic Successful Response
   * @throws ApiError
   */
  public profileQueryMessageTableData(
    accountName: string,
    messageId: string,
    limit: number = 100000,
    sessionId?: string | null,
    requestBody?: Array<TableFilter> | null,
  ): CancelablePromise<Record<string, UnionStatistic>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/profile',
      path: {
        account_name: accountName,
        message_id: messageId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Query Message Table Data
   * Get Message Data.
   * @param accountName
   * @param messageId
   * @param limit
   * @param skip
   * @param sessionId
   * @param requestBody
   * @returns DataPage Successful Response
   * @throws ApiError
   */
  public readQueryMessageTableData(
    accountName: string,
    messageId: string,
    limit: number = 100,
    skip?: number,
    sessionId?: string | null,
    requestBody?: Body_read_query_message_table_data_api_v3_orgs__account_name__messages__message_id__data_post,
  ): CancelablePromise<DataPage> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/data',
      path: {
        account_name: accountName,
        message_id: messageId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
        skip: skip,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Query Message Table Data As Csv
   * Read Query Message table data as a CSV.
   * @param accountName
   * @param messageId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public readQueryMessageTableDataAsCsv(
    accountName: string,
    messageId: string,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/messages/{message_id}/data/csv',
      path: {
        account_name: accountName,
        message_id: messageId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
