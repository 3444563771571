// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/ZenIdentifierRelationship.ts"
);
import.meta.hot.lastModified = "1728941028158.6558";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Possible ZenIdentifierRelationship types.
 *
 * https://docs.zenlytic.com/docs/data_modeling/join
 */
export enum ZenIdentifierRelationship {
  ONE_TO_ONE = 'one_to_one',
  MANY_TO_ONE = 'many_to_one',
  ONE_TO_MANY = 'one_to_many',
  MANY_TO_MANY = 'many_to_many',
}
