// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/ZenView.ts"
);
import.meta.hot.lastModified = "1728941032262.6326";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ZenDimension } from './ZenDimension'
import type { ZenIdentifier } from './ZenIdentifier'
import type { ZenMeasure } from './ZenMeasure'

/**
 * Metrics layer view.
 *
 * Ref: https://docs.zenlytic.com/docs/data_modeling/view
 */
export type ZenView = {
  sync_id?: string | null
  type?: ZenView.type
  name: string
  index?: number
  model_name?: string | null
  description?: string | null
  sql_table?: string | null
  derived_table_sql?: string | null
  identifiers?: Array<ZenIdentifier>
  dimensions?: Array<ZenDimension>
  measures?: Array<ZenMeasure>
  default_date?: ZenDimension | null
}

export namespace ZenView {
  export enum type {
    VIEW = 'view',
  }
}
