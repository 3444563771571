// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/RedshiftService.ts"
);
import.meta.hot.lastModified = "1728941032706.63";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RedshiftConnection } from '../models/RedshiftConnection'
import type { RedshiftConnectionCreate } from '../models/RedshiftConnectionCreate'
import type { RedshiftConnectionUpdate } from '../models/RedshiftConnectionUpdate'
import type { TableColumn } from '../models/TableColumn'

import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'

export class RedshiftService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create Redshift Connection
   * Create new Redshift connection.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns RedshiftConnection Successful Response
   * @throws ApiError
   */
  public createRedshiftConnection(
    accountName: string,
    requestBody: RedshiftConnectionCreate,
    sessionId?: string | null,
  ): CancelablePromise<RedshiftConnection> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/accounts/{account_name}/connections/redshift/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Redshift Connection
   * Read a Redshift connection.
   * @param accountName
   * @param connectionId
   * @param sessionId
   * @returns RedshiftConnection Successful Response
   * @throws ApiError
   */
  public readRedshiftConnection(
    accountName: string,
    connectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<RedshiftConnection> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/redshift/{connection_id}',
      path: {
        account_name: accountName,
        connection_id: connectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Redshift Connection
   * Update Resfhit connection.
   *
   * Note that all fields for a valid Redshift connection are required.
   * @param accountName
   * @param connectionId
   * @param requestBody
   * @param sessionId
   * @returns RedshiftConnection Successful Response
   * @throws ApiError
   */
  public updateRedshiftConnection(
    accountName: string,
    connectionId: string,
    requestBody: RedshiftConnectionUpdate,
    sessionId?: string | null,
  ): CancelablePromise<RedshiftConnection> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/accounts/{account_name}/connections/redshift/{connection_id}',
      path: {
        account_name: accountName,
        connection_id: connectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Redshift Connection
   * Delete a Redshift connection.
   * @param accountName
   * @param connectionId
   * @param sessionId
   * @returns RedshiftConnection Successful Response
   * @throws ApiError
   */
  public deleteRedshiftConnection(
    accountName: string,
    connectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<RedshiftConnection> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/accounts/{account_name}/connections/redshift/{connection_id}',
      path: {
        account_name: accountName,
        connection_id: connectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * List Redshift Schemas
   * List Redshift schemas.
   * @param accountName
   * @param connectionId
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public listRedshiftSchemas(
    accountName: string,
    connectionId: string,
    sessionId?: string | null,
  ): CancelablePromise<Record<string, Array<string>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/redshift/{connection_id}/schemas',
      path: {
        account_name: accountName,
        connection_id: connectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * List Redshift Tables
   * Read Redshift tables.
   * @param accountName
   * @param connectionId
   * @param database
   * @param schema
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public listRedshiftTables(
    accountName: string,
    connectionId: string,
    database?: string | null,
    schema?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<Record<string, Record<string, Array<string>>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/redshift/{connection_id}/tables',
      path: {
        account_name: accountName,
        connection_id: connectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        database: database,
        schema: schema,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * List Redshift Columns
   * List Redshift columns.
   * @param accountName
   * @param connectionId
   * @param schema
   * @param table
   * @param database
   * @param sessionId
   * @returns TableColumn Successful Response
   * @throws ApiError
   */
  public listRedshiftColumns(
    accountName: string,
    connectionId: string,
    schema: string,
    table: string,
    database?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<Array<TableColumn>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/accounts/{account_name}/connections/redshift/{connection_id}/columns',
      path: {
        account_name: accountName,
        connection_id: connectionId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        database: database,
        schema: schema,
        table: table,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
