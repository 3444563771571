// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/WidgetService.ts"
);
import.meta.hot.lastModified = "1728941033658.6245";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WidgetAPICreateResponse } from '../models/WidgetAPICreateResponse'
import type { WidgetAPIGetResponse } from '../models/WidgetAPIGetResponse'
import type { WidgetAPIUpdateRequest } from '../models/WidgetAPIUpdateRequest'
import type { WidgetAPIUpdateResponse } from '../models/WidgetAPIUpdateResponse'

import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'

export class WidgetService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create Widget
   * Create a widget.
   *
   * For now, we only allow one widget per account.
   * @param accountName
   * @param key
   * @param sessionId
   * @returns WidgetAPICreateResponse Successful Response
   * @throws ApiError
   */
  public createWidget(
    accountName: string,
    key?: string | null,
    sessionId?: string | null,
  ): CancelablePromise<WidgetAPICreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/widgets/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        key: key,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Widget For Account
   * Get widget by account ID.
   * @param accountName
   * @param sessionId
   * @returns WidgetAPIGetResponse Successful Response
   * @throws ApiError
   */
  public getWidgetForAccount(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<WidgetAPIGetResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/widgets/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Widget
   * Get widget by ID.
   * @param accountName
   * @param widgetId
   * @param sessionId
   * @returns WidgetAPIGetResponse Successful Response
   * @throws ApiError
   */
  public getWidget(
    accountName: string,
    widgetId: string,
    sessionId?: string | null,
  ): CancelablePromise<WidgetAPIGetResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/widgets/{widget_id}',
      path: {
        account_name: accountName,
        widget_id: widgetId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Widget
   * Update widget by ID.
   *
   * Currently this endpoint supports updating the `options` and the `key` fields.
   * @param accountName
   * @param widgetId
   * @param requestBody
   * @param sessionId
   * @returns WidgetAPIUpdateResponse Successful Response
   * @throws ApiError
   */
  public updateWidget(
    accountName: string,
    widgetId: string,
    requestBody: WidgetAPIUpdateRequest,
    sessionId?: string | null,
  ): CancelablePromise<WidgetAPIUpdateResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v3/orgs/{account_name}/widgets/{widget_id}',
      path: {
        account_name: accountName,
        widget_id: widgetId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Widget
   * Delete widget by ID.
   * @param accountName
   * @param widgetId
   * @param sessionId
   * @returns void
   * @throws ApiError
   */
  public deleteWidget(
    accountName: string,
    widgetId: string,
    sessionId?: string | null,
  ): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v3/orgs/{account_name}/widgets/{widget_id}',
      path: {
        account_name: accountName,
        widget_id: widgetId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
