// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/NamedFilterOptionBase.ts"
);
import.meta.hot.lastModified = "1728941031218.6384";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Combination } from './Combination'
import type { FilterOrigin } from './FilterOrigin'
import type { NamedFilterOptionConditionBase } from './NamedFilterOptionConditionBase'

/**
 * NamedFilterOption Base Schema.
 */
export type NamedFilterOptionBase = {
  id?: string | null
  query_id?: string | null
  name: string
  combination?: Combination
  description?: string | null
  filter_origin?: FilterOrigin
  is_enabled?: boolean
  field_type?: NamedFilterOptionBase.field_type
  is_result_filter?: boolean
  conditions: Array<NamedFilterOptionConditionBase>
  named_filter_id?: string | null
}

export namespace NamedFilterOptionBase {
  export enum field_type {
    DIMENSION = 'dimension',
  }
}
