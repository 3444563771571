// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/ConversationsV2Service.ts"
);
import.meta.hot.lastModified = "1728941032514.631";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConversationAPICreateResponse } from '../models/ConversationAPICreateResponse'
import type { ConversationAPIReadResponse } from '../models/ConversationAPIReadResponse'
import type { ConversationAPIStart } from '../models/ConversationAPIStart'
import type { MessageAPISend } from '../models/MessageAPISend'
import type { MessageAPISendResponse } from '../models/MessageAPISendResponse'
import type { MessagePayloadType } from '../models/MessagePayloadType'

import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'

export class ConversationsV2Service {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Start Conversation
   * Start a conversation.
   *
   * This endpoint will start a new conversation with the NS application. If
   * accepted_payload_types is null then all types are accepted. The response will
   * include the details of the message and the conversation.
   * @param accountName
   * @param requestBody
   * @returns ConversationAPICreateResponse Successful Response
   * @throws ApiError
   */
  public startConversation(
    accountName: string,
    requestBody: ConversationAPIStart,
  ): CancelablePromise<ConversationAPICreateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/orgs/{account_name}/conversations/',
      path: {
        account_name: accountName,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Send Message
   * Send a message to a conversation.
   *
   * This endpoint sends a message to an existing application. The response includes the
   * metadata about the created message. Note that the only message payload type that
   * can be sent is TEXT.
   * @param accountName
   * @param conversationId
   * @param requestBody
   * @returns MessageAPISendResponse Successful Response
   * @throws ApiError
   */
  public sendMessage(
    accountName: string,
    conversationId: string,
    requestBody: MessageAPISend,
  ): CancelablePromise<MessageAPISendResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/orgs/{account_name}/conversations/{conversation_id}/messages',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Read Conversation Messages
   * Get messages in a conversation.
   *
   * This paginated endpoint returns a conversation with up to the requested number of
   * messages. Note that the messages are ordered from newest to oldest. The client can
   * specify that they want messages after a particular index by providing an offset in
   * order to only get new messages. Note that the server can choose to exclude the payload
   * (returning null) from some messages for performance reasons. The client must then make
   * individual requests for those messages to get the contents.
   *
   * Params:
   * accepted_payload_types: The payload types that can be used in the conversation.
   * If included in the params then it must include the TEXT type. Note that
   * excluding a type can cause messages of that type not to be sent. Messages are not
   * always converted to an accepted type (i.e. if you want table contents, you
   * have to have the “TABLE” type in the accepted types). If None then all types
   * are accepted.
   * limit: The maximum number of messages to return.
   * offset: The number of messages to skip.
   * bound: The index of the last message to return. The response will not include
   * indexes less than this value.
   * @param accountName
   * @param conversationId
   * @param acceptedPayloadTypes
   * @param limit
   * @param offset
   * @param bound
   * @returns ConversationAPIReadResponse Successful Response
   * @throws ApiError
   */
  public readConversationMessages(
    accountName: string,
    conversationId: string,
    acceptedPayloadTypes?: Array<MessagePayloadType> | null,
    limit: number = 20,
    offset?: number,
    bound?: number,
  ): CancelablePromise<ConversationAPIReadResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/orgs/{account_name}/conversations/{conversation_id}',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
      },
      query: {
        accepted_payload_types: acceptedPayloadTypes,
        limit: limit,
        offset: offset,
        bound: bound,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
