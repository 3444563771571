// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/ChatV3Service.ts"
);
import.meta.hot.lastModified = "1728941032454.6313";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AgentAPI } from '../models/AgentAPI'
import type { ChatAPICreate } from '../models/ChatAPICreate'
import type { ChatAPIResponse } from '../models/ChatAPIResponse'
import type { ChatAPIResponsePage } from '../models/ChatAPIResponsePage'
import type { ChatAPIUpdate } from '../models/ChatAPIUpdate'
import type { ChatsFilter } from '../models/ChatsFilter'
import type { ChatsSort } from '../models/ChatsSort'
import type { MessageAPIResponsePage } from '../models/MessageAPIResponsePage'
import type { MessageAPISendV3 } from '../models/MessageAPISendV3'
import type { Task } from '../models/Task'

import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'

export class ChatV3Service {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Create Chat
   * Create a chat. This will not send a message to the chat.
   * @param accountName
   * @param requestBody
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public createChat(
    accountName: string,
    requestBody: ChatAPICreate,
    sessionId?: string | null,
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Chats
   * Read all chats.
   * @param accountName
   * @param sortAscending
   * @param sortBy
   * @param filterBy
   * @param limit
   * @param offset
   * @param showEval
   * @param sessionId
   * @returns ChatAPIResponsePage Successful Response
   * @throws ApiError
   */
  public getChats(
    accountName: string,
    sortAscending: boolean = true,
    sortBy?: ChatsSort,
    filterBy?: ChatsFilter | null,
    limit?: number | null,
    offset?: number | null,
    showEval: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<ChatAPIResponsePage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        sort_ascending: sortAscending,
        sort_by: sortBy,
        filter_by: filterBy,
        limit: limit,
        offset: offset,
        show_eval: showEval,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Start Chat
   * Start a new chat.
   *
   * This endpoint sends will create a new chat and send a message.
   *
   * Returns: EventSourceResponse
   *
   * Raises:
   * NSCannotSendMessageError if another request is concurrently sending a message
   * @param accountName
   * @param chatId
   * @param requestBody
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public startChat(
    accountName: string,
    chatId: string,
    requestBody: MessageAPISendV3,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/start',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        chat_id: chatId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Delete Chat
   * Delete a chat.
   * @param accountName
   * @param chatId
   * @param sessionId
   * @returns Task Successful Response
   * @throws ApiError
   */
  public deleteChat(
    accountName: string,
    chatId: string,
    sessionId?: string | null,
  ): CancelablePromise<Task> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}',
      path: {
        account_name: accountName,
        chat_id: chatId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Update Chat
   * Update a chat.
   * @param accountName
   * @param chatId
   * @param requestBody
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public updateChat(
    accountName: string,
    chatId: string,
    requestBody: ChatAPIUpdate,
    sessionId?: string | null,
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}',
      path: {
        account_name: accountName,
        chat_id: chatId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Chat
   * Read a single chat. Note that this does NOT include the messages of the chat.
   * @param accountName
   * @param chatId
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public getChat(
    accountName: string,
    chatId: string,
    sessionId?: string | null,
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}',
      path: {
        account_name: accountName,
        chat_id: chatId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Chat Messages
   * Get messages in a chat.
   *
   * This paginated endpoint returns a chat with up to the requested number of
   * messages. Note that the messages are ordered from newest to oldest.
   *
   * Params:
   * limit: The maximum number of messages to include in the response.
   * offset: The messages to skip. The response will not include indexes greater
   * than the offset. Equivalent of 'skip' in database queries.
   *
   *
   * Returns:
   * Messages from the chat in reverse-temporal order (newest first). Agent end and
   * user end messages are not included.
   * @param accountName
   * @param chatId
   * @param limit
   * @param offset
   * @param sessionId
   * @returns MessageAPIResponsePage Successful Response
   * @throws ApiError
   */
  public getChatMessages(
    accountName: string,
    chatId: string,
    limit: number = 20,
    offset?: number,
    sessionId?: string | null,
  ): CancelablePromise<MessageAPIResponsePage> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}/messages',
      path: {
        account_name: accountName,
        chat_id: chatId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
        offset: offset,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Send Message
   * Send a message in an existing chat.
   *
   * This endpoint sends a message in a chat. The message will be sent to the
   * chat and the AI will respond if necessary.
   *
   * Raises:
   * NSCannotSendMessageError if another request is concurrently sending a message
   * @param accountName
   * @param chatId
   * @param requestBody
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public sendMessage(
    accountName: string,
    chatId: string,
    requestBody: MessageAPISendV3,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}/send',
      path: {
        account_name: accountName,
        chat_id: chatId,
      },
      cookies: {
        session_id: sessionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Send Interrupt
   * Send a interrupt signal in a chat.
   *
   * Note that this sends the signal regardless of whether the AI is currently
   * generating
   * @param accountName
   * @param chatId
   * @param sessionId
   * @returns any Successful Response
   * @throws ApiError
   */
  public sendInterrupt(
    accountName: string,
    chatId: string,
    sessionId?: string | null,
  ): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}/interrupt',
      path: {
        account_name: accountName,
        chat_id: chatId,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Clone Chat
   * Clone a chat with the new user as the creator.
   * @param accountName
   * @param chatId
   * @param isEvalChat
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public cloneChat(
    accountName: string,
    chatId: string,
    isEvalChat: boolean = false,
    sessionId?: string | null,
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/{chat_id}/clone',
      path: {
        account_name: accountName,
        chat_id: chatId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        is_eval_chat: isEvalChat,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Conversation To Chat
   * Convert a conversation into a Chat.
   * @param accountName
   * @param conversationId
   * @param ssdsId
   * @param sessionId
   * @returns ChatAPIResponse Successful Response
   * @throws ApiError
   */
  public conversationToChat(
    accountName: string,
    conversationId: string,
    ssdsId: string,
    sessionId?: string | null,
  ): CancelablePromise<ChatAPIResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v3/orgs/{account_name}/chat/{conversation_id}/convert',
      path: {
        account_name: accountName,
        conversation_id: conversationId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        ssds_id: ssdsId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Chat Agents
   * Get list of available agents by name.
   * @param accountName
   * @param sessionId
   * @returns AgentAPI Successful Response
   * @throws ApiError
   */
  public getChatAgents(
    accountName: string,
    sessionId?: string | null,
  ): CancelablePromise<Array<AgentAPI>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/chat/agents',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
