// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/FilterType.ts"
);
import.meta.hot.lastModified = "1728941028030.6565";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Filter type.
 */
export enum FilterType {
  DIMENSION = 'dimension',
  METRIC = 'metric',
}
