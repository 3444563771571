// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/ZenMeasure.ts"
);
import.meta.hot.lastModified = "1728941032238.6326";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Dialect } from './Dialect'
import type { DisplayType } from './DisplayType'
import type { FilterCategory } from './FilterCategory'
import type { OriginType } from './OriginType'
import type { PublishState } from './PublishState'
import type { TimeGranularity } from './TimeGranularity'
import type { ZenDimension } from './ZenDimension'
import type { ZenFieldFilter } from './ZenFieldFilter'
import type { ZenMeasureType } from './ZenMeasureType'

/**
 * Metrics layer measure.
 *
 * Ref: https://docs.zenlytic.com/docs/data_modeling/measure
 */
export type ZenMeasure = {
  id?: string | null
  sync_id?: string | null
  group_id?: string | null
  name: string
  field_type?: ZenMeasure.field_type
  type: ZenMeasureType
  description?: string | null
  sql: string
  ns_expr?: string | null
  sql_distinct_key?: string | null
  measure?: string | null
  filters?: Array<ZenFieldFilter> | null
  canon_date?: ZenDimension | null
  window_size?: number | null
  window_granularity?: TimeGranularity | null
  display_name?: string | null
  expr_type?: FilterCategory
  state?: PublishState
  display_type?: DisplayType
  data_source_id?: string | null
  is_sensitive?: boolean
  is_literal_sensitive?: boolean
  origin?: Array<OriginType>
  dialect?: Dialect
  popularity?: number
}

export namespace ZenMeasure {
  export enum field_type {
    MEASURE = 'measure',
  }
}
