// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/ui/atoms/Toast/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/ui/atoms/Toast/index.tsx");
  import.meta.hot.lastModified = "1702569433515.8843";
}
// REMIX HMR END

import * as ToastPrimitives from '@radix-ui/react-toast';
import { X } from 'lucide-react';
import * as React from 'react';
import { cn } from '~/utils/cn';
const ToastViewport = React.forwardRef(_c = ({
  className,
  ...props
}, ref) => <ToastPrimitives.Viewport ref={ref} className={cn('fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[420px]', className)} {...props} />);
_c2 = ToastViewport;
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;
const ToastRoot = React.forwardRef(_c3 = ({
  className,
  ...props
}, ref) => {
  return <ToastPrimitives.Root ref={ref} className={cn(`group pointer-events-auto relative flex w-full items-center justify-between
  space-x-4 overflow-hidden rounded-md border bg-background p-6 pr-8 text-primary shadow-lg
  transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)]
  data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none
  data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out
  data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full
  data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full`, className)} {...props} />;
});
_c4 = ToastRoot;
ToastRoot.displayName = ToastPrimitives.Root.displayName;
const ToastAction = React.forwardRef(_c5 = ({
  className,
  ...props
}, ref) => <ToastPrimitives.Action ref={ref} className={cn(`inline-flex h-8 shrink-0 items-center justify-center rounded-md border
      bg-transparent px-3 text-sm font-medium ring-offset-background transition-colors hover:bg-secondary
      focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none
      disabled:opacity-50`, className)} {...props} />);
_c6 = ToastAction;
ToastAction.displayName = ToastPrimitives.Action.displayName;
const ToastClose = React.forwardRef(_c7 = ({
  className,
  ...props
}, ref) => <ToastPrimitives.Close ref={ref} className={cn(`absolute right-2 top-2 rounded-md p-1 opacity-100 transition-opacity
      focus:outline-none focus:ring-2`, className)} toast-close='' {...props}>
    <X className='h-4 w-4' />
  </ToastPrimitives.Close>);
_c8 = ToastClose;
ToastClose.displayName = ToastPrimitives.Close.displayName;
const ToastTitle = React.forwardRef(_c9 = ({
  className,
  ...props
}, ref) => <ToastPrimitives.Title ref={ref} className={cn('text-sm font-semibold', className)} {...props} />);
_c10 = ToastTitle;
ToastTitle.displayName = ToastPrimitives.Title.displayName;
const ToastDescription = React.forwardRef(_c11 = ({
  className,
  ...props
}, ref) => <ToastPrimitives.Description ref={ref} className={cn('text-sm font-normal', className)} {...props} />);
_c12 = ToastDescription;
ToastDescription.displayName = ToastPrimitives.Description.displayName;
export const Toast = Object.assign(ToastRoot, {
  Title: ToastTitle,
  Close: ToastClose,
  Description: ToastDescription,
  Action: ToastAction,
  Viewport: ToastViewport,
  Provider: ToastPrimitives.Provider
});
_c13 = Toast;
export * from './useToast';
var _c, _c2, _c3, _c4, _c5, _c6, _c7, _c8, _c9, _c10, _c11, _c12, _c13;
$RefreshReg$(_c, "ToastViewport$React.forwardRef");
$RefreshReg$(_c2, "ToastViewport");
$RefreshReg$(_c3, "ToastRoot$React.forwardRef");
$RefreshReg$(_c4, "ToastRoot");
$RefreshReg$(_c5, "ToastAction$React.forwardRef");
$RefreshReg$(_c6, "ToastAction");
$RefreshReg$(_c7, "ToastClose$React.forwardRef");
$RefreshReg$(_c8, "ToastClose");
$RefreshReg$(_c9, "ToastTitle$React.forwardRef");
$RefreshReg$(_c10, "ToastTitle");
$RefreshReg$(_c11, "ToastDescription$React.forwardRef");
$RefreshReg$(_c12, "ToastDescription");
$RefreshReg$(_c13, "Toast");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;