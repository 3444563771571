// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/DimensionViewWithGroup.ts"
);
import.meta.hot.lastModified = "1728941030690.6414";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Dialect } from './Dialect'
import type { DimensionView } from './DimensionView'
import type { DisplayType } from './DisplayType'
import type { FilterCategory } from './FilterCategory'
import type { OriginType } from './OriginType'
import type { PublishState } from './PublishState'

/**
 * DimensionView with other DimensionViews with the same group_id.
 */
export type DimensionViewWithGroup = {
  id?: string | null
  sync_id?: string | null
  group_id?: string | null
  name: string
  description?: string | null
  dialect: Dialect
  expr?: string | null
  zen_sql?: string | null
  expr_type?: FilterCategory
  display_name?: string | null
  data_source_id?: string | null
  is_primary_time_dimension?: boolean
  origin?: Array<OriginType>
  top_values?: Array<string> | null
  display_type?: DisplayType
  popularity?: number
  is_sensitive?: boolean
  is_literal_sensitive?: boolean
  is_display_sensitive?: boolean
  field_type?: DimensionViewWithGroup.field_type
  state?: PublishState
  type?: 'categorical' | 'time' | null
  group: Array<DimensionView>
}

export namespace DimensionViewWithGroup {
  export enum field_type {
    DIMENSION = 'dimension',
  }
}
