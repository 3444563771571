// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/PublishStateFilterOption.ts"
);
import.meta.hot.lastModified = "1728941028078.6562";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PublishStateFilterOption {
  ONLY_VERIFIED = 'only_verified',
  NOT_DEPRECATED = 'not_deprecated',
  ONLY_VALIDATABLE = 'only_validatable',
  ALL = 'all',
}
