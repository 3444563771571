// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/SignalTypeAPI.ts"
);
import.meta.hot.lastModified = "1728941028094.656";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enum for the signal type of the message.
 *
 * These signals are used to inform the client about the state of the chat.
 *
 * Attributes:
 * REQUEST_INPUT: Request input from the user.
 * NOT_ACCEPTING_INPUT: System is no longer accepting input.
 * REJECTED_INPUT: Previously provided input was rejected.
 */
export enum SignalTypeAPI {
  REQUEST_INPUT = 'REQUEST_INPUT',
  NOT_ACCEPTING_INPUT = 'NOT_ACCEPTING_INPUT',
  REJECTED_INPUT = 'REJECTED_INPUT',
  REQUEST_CONFIRMATION = 'REQUEST_CONFIRMATION',
}
