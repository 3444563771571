// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/models/ChartType.ts"
);
import.meta.hot.lastModified = "1728941027938.657";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Enumerates the possible chart types.
 */
export enum ChartType {
  EMPTY = 'empty',
  BAR = 'bar',
  LINE = 'line',
  SCATTER = 'scatter',
  GEO = 'geo',
  BIG_NUMBER = 'big_number',
}
