// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/api/services/DatasetsV3Service.ts"
);
import.meta.hot.lastModified = "1728941032578.6306";
}
// REMIX HMR END

/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StarSchemaDataSourceAPI } from '../models/StarSchemaDataSourceAPI'

import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'

export class DatasetsV3Service {
  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get Datasets For Account
   * Get datasets for an account.
   * @param accountName
   * @param limit
   * @param offset
   * @param sessionId
   * @returns StarSchemaDataSourceAPI Successful Response
   * @throws ApiError
   */
  public getDatasetsForAccount(
    accountName: string,
    limit?: number | null,
    offset?: number | null,
    sessionId?: string | null,
  ): CancelablePromise<Array<StarSchemaDataSourceAPI>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/datasets/',
      path: {
        account_name: accountName,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
        offset: offset,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }

  /**
   * Get Suggestions For Dataset
   * Get suggested prompts for a star schema data source.
   * @param accountName
   * @param datasetId
   * @param limit
   * @param sessionId
   * @returns string Successful Response
   * @throws ApiError
   */
  public getSuggestionsForDataset(
    accountName: string,
    datasetId: string,
    limit?: number | null,
    sessionId?: string | null,
  ): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v3/orgs/{account_name}/datasets/{dataset_id}/suggestions',
      path: {
        account_name: accountName,
        dataset_id: datasetId,
      },
      cookies: {
        session_id: sessionId,
      },
      query: {
        limit: limit,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
